import { ContentfulAsset } from '@fractal/primitives/molecules/ContentfulAsset'
import useTranslation from 'app/i18n/client'
import { ContentfulLazyLoad } from 'components/shared/providers'
import dynamic from 'next/dynamic'
import { withPageContexts } from '../GlobalProviders'

const NotFoundMadlibHeader = dynamic(() => import('@fractal/features/NotFound/MadlibHeader/MadlibHeader'))

function ErrorPageComponent() {
  const { t } = useTranslation()
  const heading = t('client-error-heading')

  return (
    <div className='mt-sm-6'>
      <ContentfulLazyLoad slug='article-madlib-section'>
        {(componentData: any) => (
          <NotFoundMadlibHeader
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...componentData as any}
            heading={heading}
            red={t('oops')}
            byline={t('error-page-subtitle')}
          />
        )}
      </ContentfulLazyLoad>
      <div style={{ overflow: 'hidden' }}>
        <div
          className='mt-lg-n9'
          style={{
            display: 'grid',
            gridTemplate: '12fr',
            textAlign: 'center',
            marginBottom: -12
          }}
        >
          {/* eslint-disable-next-line max-len */}
          <ContentfulAsset url='https://assets.ctfassets.net/p0qf7j048i0q/1gV1tS4aibSz7VfUVhYb0P/ca009008e3eb1747d9f00b043479cebf/MISSION_PAGE_ANIMATION_04_LIGHT_090921.json' />
        </div>
      </div>
    </div>
  )
}

export default withPageContexts(ErrorPageComponent)
