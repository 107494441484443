import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
  from
} from '@apollo/client'
import { RetryLink } from '@apollo/client/link/retry'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import possibleTypes from './possibleTypes'

export const APOLLO_STATE_PROP_NAME: string = '__APOLLO_STATE__'
const isServer = (): boolean => typeof window === 'undefined'

let apolloClient: ApolloClient<NormalizedCacheObject>

// Apollo links
const NEXT_PUBLIC_CONTENTFUL_API = process.env.NEXT_PUBLIC_CONTENTFUL_API as string
const NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN as string
const NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN as string

const CONTENTFUL_CONTEXT_NAME: string = 'contentFul'

/** This will be the default link,
 all the request who not specifies a clientName in his context will be redirected here
 @example
 await client.query({ query: RAW_QUERY }) */
const graphqlLink: HttpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_DOMAIN, // (must be absolute)
  credentials: 'same-origin' // Additional fetch() options like `credentials` or `headers`
  // other link options...
})

/** In the other hand, the only way to send a request to the contentFul API
 is to pass the string "contentFul" in the query context as the clientName:

 @example
 await client.query({
   query: QUERY_CONTENTFUL,
   context: {clientName: "contentFul"}
}) */
const contentFulLink: HttpLink = new HttpLink({
  uri: NEXT_PUBLIC_CONTENTFUL_API
  // other link options...
})

const retryLink = new RetryLink({
  delay: {
    initial: 500, // 0.5 seconds
    max: 60000, // 1 minute
    jitter: true // Whether delays between attempts should be randomized
  },
  attempts: {
    max: 10,
    retryIf: (error) => error.statusCode === 429
  }
})

const apolloLink: ApolloLink = ApolloLink.split(
  (operation: Operation) => operation.getContext().clientName === CONTENTFUL_CONTEXT_NAME,
  // the string "contentFul" can be anything that we want,
  contentFulLink, // <= apollo will send to this if clientName is "contentFul"
  graphqlLink // <= otherwise will send to this
)

const authMiddleware: ApolloLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  // add the authorization to the headers
  const context: DefaultContext = operation.getContext()
  const isContentFul: boolean = context?.clientName === CONTENTFUL_CONTEXT_NAME
  const isPreview: boolean = !!operation.variables?.preview
  const authorization: string = `Bearer ${isPreview ? NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN : NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN}`
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(isContentFul && { authorization })
    }
  }))

  return forward(operation)
})

function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    connectToDevTools: process.env.NODE_ENV === 'development',
    ssrMode: isServer(),
    link: from([authMiddleware, retryLink, apolloLink]),
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      },
      watchQuery: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    },
    cache: new InMemoryCache({
      addTypename: true,
      possibleTypes,
      typePolicies: {
        GenericContent: {
          fields: {
            contents: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
              }
            }
          }
        }
      }
    })
  })
}

export function initializeApollo(initialState = {}) {
  const apolloClientInit = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClientInit.cache.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
      ]
    })

    // Restore the cache with the merged data
    apolloClientInit.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (isServer()) return apolloClientInit
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = apolloClientInit

  return apolloClientInit
}

export function addApolloState(client: ApolloClient<NormalizedCacheObject>, pageProps: any) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  return useMemo(() => initializeApollo(state), [state])
}
