import { IEntry } from 'components/dynamic-page/shared/types/Entry.type'
import React, { useCallback, useContext, useMemo } from 'react'
import { IPageComponentsContext } from './PageComponentsContext.type'

export const PageComponentsContext = React.createContext<IPageComponentsContext>({
  parent: undefined,
  entries: undefined,
  getPositionInParent: () => null
})

export const usePageComponentsContext = () => useContext(PageComponentsContext)

interface IProviderProps {
  parent: IEntry
  entries?: IEntry[]
  children: React.ReactNode
}

export function PageComponentsProvider({ parent, children, entries }: IProviderProps) {
  const getPositionInParent = useCallback((id: string) => {
    if (!entries) return null

    const index = entries.findIndex((entry) => entry?.sys?.id === id)

    return index === -1 ? null : index + 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries?.length])

  const contextData = useMemo(() => ({
    parent,
    entries,
    getPositionInParent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [parent?.sys?.id, entries?.length, getPositionInParent])

  return (
    <PageComponentsContext.Provider
      value={contextData}
    >
      {children}
    </PageComponentsContext.Provider>
  )
}
