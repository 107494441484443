import useDarkMode from 'use-dark-mode'
import { useEffect } from 'react'

export default function useCustomDarkMode () {
  const darkmode = useDarkMode()

  // In case the person changed the mode while page was closed, then come back
  // This makes sure that the right theme is provided, as it ^ was creating a bug
  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)')

    if (darkThemeMq.matches) {
      darkmode.enable()
    } else {
      darkmode.disable()
    }
  })

  return darkmode
}
