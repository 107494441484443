import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGdotGreen = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <circle cx='10' cy='10' r='9.5' fill='#2CA14D' stroke='#FFF4E5' />
    </svg>
  )
}

export default SVGdotGreen
