'use client'

import React, { useContext } from 'react'
import HeaderContext from '@fractal/features/Header/HeaderContext'
import SVGmenu from '@fractal/primitives/SVGs/icons/menu'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'

function MenuButton({
  sys,
  transparentBackground
}: any) {
  const { sidebarOpen, toggleSideBar } = useContext(HeaderContext)

  const handleMenuClick = () => {
    toggleSideBar!(!sidebarOpen)
  }

  return (
    <TrackClick
      as='button'
      data-testid='sidebarHamburger'
      className={`Btn-link flex-sm-align-center flex-sm-justify-center icon-hover no-print target
        ${transparentBackground ? 'text-foreground-darkblue' : 'text-static-white'}`}
      aria-label='Open menu'
      trackProps={{
        clickType: 'button',
        buttonName: 'sidebar_menu',
        unitName: 'main_menu',
        unitLocation: 'header',
        componentId: sys.id
      }}
      onClick={handleMenuClick}
    >
      <SVGmenu className='vicon' />
    </TrackClick>
  )
}

export default MenuButton
