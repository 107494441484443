import jwt from 'jsonwebtoken'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { v4 as uuidV4 } from 'uuid'

export const remapUser = res => {
  return {
    id: uuidV4(),
    attributes: {
      ...res
    }
  }
}

export const transformAndDecodeToken = token => {
  const data: any = jwtDecode(token)
  return {
    error: false,
    is_logged_in: true,
    email: data?.email,
    first_name: data['https://u.org/firstName'],
    id: data?.sub,
    picture: data?.picture,
    screen_name: data['https://u.org/screenName']
  }
}

export const initialUserState = {
  id: uuidV4(),
  error: false,
  is_logged_in: false,
  preferences: {}
}

export function getAuthCookie(name) {
  try {
    if (!name) return undefined
    const cookies = new Cookies()
    const cookie = cookies.get(name)
    return cookie
  } catch (err) {
    return undefined
  }
}

export function decodeToken(token, secret) {
  try {
    const userData = jwt.verify(token, secret, {
      algorithms: ['RS256']
    })
    return remapUser(userData)
  } catch (err) {
    return initialUserState
  }
}

export function extractLoggedinUser(configs) {
  if (!configs || !configs.app) return false
  const { authCookieName, authTokenSecret } = configs.app
  const authToken = getAuthCookie(authCookieName)
  if (!authToken) return false
  const userData = decodeToken(authToken, authTokenSecret)
  return (userData && remapUser(userData)) || initialUserState
}
