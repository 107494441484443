import React from 'react'
import { ICustomizationContext } from './types'

const DefaultCustomizationContext: ICustomizationContext = {
  reduceMotion: false,
  isReduceMotionManuallySet: false,
  updateCustomization: () => {}
}

const CustomizationContext = React.createContext(DefaultCustomizationContext)

const { Provider, Consumer } = CustomizationContext

export {
  Provider as CustomizationProvider,
  Consumer as CustomizationConsumer,
  DefaultCustomizationContext
}

export default CustomizationContext
