'use client'

import { ILinkProps, Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { AppContext } from '@context/AppContext'
import { LanguageContext, Languages } from '@context/LanguageContext'
import SVGglobe from '@fractal/primitives/SVGs/icons/globe'
import React, { useContext } from 'react'
import { LanguageProps } from './LanguageToggle.interface'

const LanguageLink = ({ children, ...props }: LanguageProps & ILinkProps) => {
  const { match = 'en-US' } = props
  const { language } = useContext(LanguageContext)

  return (
    <Link
      {...props}
      className={`${match === language ? 'text-bold no-underline' : ''
        } fd-sm-flex flex-sm-align-center flex-sm-justify-end f-sm-2 f-md-3 ${props.className
        }`}
    >
      {children}
    </Link>
  )
}

interface LanguageToggleProps {
  showIcon?: boolean
  withBackground?: boolean
  className?: string
  transparentBackground?: boolean
}

const LanguageToggle = ({
  showIcon = true,
  withBackground = false,
  className = '',
  transparentBackground = false
}: LanguageToggleProps) => {
  const {
    language, changeLanguage, slugEn, slugEs, enableLocaleSwitcher
  } = useContext(LanguageContext)
  const { basePath } = useContext(AppContext)
  const { trackNavigation } = useContext(AnalyticsContext)

  const handleLanguageChange = (lang: string, btnName: string) => (e: any) => {
    changeLanguage(lang)
    trackNavigation({
      eventType: 'click',
      buttonName: btnName,
      unitName: 'main_menu',
      unitLocation: 'header'
    })
  }

  if (!enableLocaleSwitcher) return null

  return (
    <>
      <section
        aria-label='languages'
        className={`d-sm-inline-flex flex-sm-align-center p-sm-1 lh-reset rounded-sm-5 ${withBackground || transparentBackground
          ? 'text-foreground-darkblue'
          : 'text-static-white'
          } py-sm-3 px-sm-4 ${className}`}
      >
        {showIcon && <SVGglobe className='d-md-inline-block vicon mr-sm-1' />}{' '}
        <ul className='list-reset d-sm-flex flex-sm-align-center p-sm-1 my-sm-0 lh-reset'>
          <li className='d-sm-flex flex-sm-align-center'>
            <LanguageLink
              match={Languages.EN}
              className={
                withBackground || transparentBackground
                  ? 'text-foreground-darkblue'
                  : 'text-static-white'
              }
              aria-label='english'
              aria-current={language === Languages.EN}
              href={`/${slugEn.replace(basePath, '')}`}
              locale={'en'}
              onClick={handleLanguageChange(Languages.EN, 'en')}
            >
              <span className='px-sm-2'>EN</span>
            </LanguageLink>
            <span className='d-sm-flex' aria-hidden='true'>
              |
            </span>
          </li>
          <li className='d-sm-flex flex-sm-align-center'>
            <LanguageLink
              match={Languages.ES}
              className={
                withBackground || transparentBackground
                  ? 'text-foreground-darkblue'
                  : 'text-static-white'
              }
              aria-label='español'
              aria-current={language === Languages.ES}
              href={`/${(slugEs).replace(basePath, '')}`}
              onClick={handleLanguageChange(Languages.ES, 'es')}
              locale={'es-mx'}
            >
              <span className='px-sm-2'>ES</span>
            </LanguageLink>
          </li>
        </ul>
      </section>
    </>
  )
}
export default LanguageToggle
