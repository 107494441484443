import { datadogRum } from '@datadog/browser-rum-slim'
import { GoogleTagManager } from '@next/third-parties/google'
import '@understood/emblem/dist/core/global_variables.css'
import '@understood/voyager/dist/voyager.css'
import ErrorBoundary from 'components/shared/components/ErrorBoundary/ErrorBoundary'
import ErrorPageComponent from 'components/shared/components/ErrorBoundary/ErrorPageComponent'
import { GlobalStyles } from 'components/shared/styles/GlobalStyles'
import 'components/shared/styles/print.scss'
import getCanonicalUrls from 'components/shared/utils/getCanonicalUrl'
import 'intersection-observer'
import localFont from 'next/font/local'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useEffect } from 'react'
import { setAppElement } from 'react-modal'

// Font files can be colocated inside of `pages`
const understoodSans = localFont({
  src: [
    {
      path: './UnderstoodSans-Regular.woff2',
      weight: '400',
      style: 'normal'
    },
    {
      path: './UnderstoodSans-SemiBold.woff2',
      weight: '600',
      style: 'normal'
    }
  ]
})

function MyApp({ Component, pageProps }: any) {
  const router = useRouter()

  useEffect(() => {
    setAppElement('#__next')
  }, [])

  useEffect(() => {
    const handleStart = () => {
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
      document.getElementById('page-body')?.focus()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
      site: 'us5.datadoghq.com',
      service: 'brand-pages',
      version: process.env.NEXT_PUBLIC_VERSION,
      env: process.env.NEXT_PUBLIC_DD_ENV,
      sessionSampleRate: 50,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true
    })
  }, [])

  const { asPath, basePath, query } = router
  const locale = query.lang as string

  const path = asPath?.split('?')?.[0] || ''
  const metaUrls = getCanonicalUrls({
    basePath,
    locale,
    path
  })

  const isLocalizable = typeof pageProps.enableLocaleSwitcher !== 'undefined'
    ? pageProps.enableLocaleSwitcher
    : pageProps?.initialState?.page?.isLocalizable

  // workaround to apply custom font to modals. That's because modals are rendered
  // outside the `main` tag below and `understoodSans` can't be moved to `_document`
  useEffect(() => {
    document.body.classList.add(understoodSans.className)
  }, [])

  return (
    <main className={understoodSans.className}>
      <GoogleTagManager gtmId='GTM-5FC77VH' />

      <Head>
        <link rel='canonical' href={metaUrls.canonical} key='canonical' />
        {isLocalizable && (
          <>
            <link
              rel='alternate'
              hrefLang='en'
              href={metaUrls.alternateEnglish}
            />
            <link
              rel='alternate'
              hrefLang='es-mx'
              href={metaUrls.alternateSpanish}
            />
            <link
              rel='alternate'
              hrefLang='x-default'
              href={metaUrls.alternateEnglish}
            />
          </>
        )}
      </Head>
      <ErrorBoundary
        // eslint-disable-next-line react/jsx-props-no-spreading
        fallback={<ErrorPageComponent {...pageProps} />}
      >
        <GlobalStyles />
        {/* Only the homepage has animation based on scroll */}
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...pageProps}
        />
      </ErrorBoundary>
    </main>
  )
}

export default MyApp
