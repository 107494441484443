import React, { useState } from 'react'
import {
  FormControlContext,
  IFormControlContextParams
} from './FormControl.context'
import { FormControlProps } from './FormControl.interface'

const margins = {
  default: 4,
  large: 5,
  small: 3,
  none: 0
}

const FormControl = (props: FormControlProps) => {
  const [context, setContext] = useState<IFormControlContextParams>({})

  const updateContext = (newContext: IFormControlContextParams) => {
    setContext(value => ({ ...value, ...newContext }))
  }

  return (
    <FormControlContext.Provider value={{ ...context, updateContext }}>
      <div className={`mb-sm-${margins[props.margin || 'none']} ${props.className}`}>
        {props.children}
      </div>
    </FormControlContext.Provider>
  )
}

FormControl.defaultProps = {
  margin: 'default'
}

export default FormControl
