// @ts-nocheck
import { AnalyticsContext } from '@context/AnalyticsContext'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import SocialLink from './SocialLink'

const FollowUs = ({
  links,
  titleText,
  tracking = { unitName: 'footer', unitLocation: 'footer' }
}) => {
  const { trackEngagement } = useContext(AnalyticsContext)
  if (!links) return null

  return (
    <>
      {titleText && (
        <p className='f-sm-4 lh-condensed text-center mb-sm-4 text-bold text-foreground-darkblue'>
          {titleText}
        </p>
      )}
      <ul className='list-reset text-center mb-sm-5'>
        {links &&
          links.map((link, i) => {
            return (
              <SocialLink
                key={`socialLinkA${i}`}
                url={link.url}
                label={link.label}
                handleClick={() => {
                  trackEngagement({
                    eventType: 'follow',
                    platform: link.label,
                    unitName: tracking.unitName,
                    unitLocation: tracking.unitLocation,
                    buttonName: link.label
                  })
                }}
              />
            )
          })}
      </ul>
    </>
  )
}

FollowUs.defaultProps = {
  titleText: 'Follow us',
  tracking: { unitName: 'footer', unitLocation: 'footer' }
}

FollowUs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  titleText: PropTypes.string
}

export default FollowUs
