import { AppProvider } from '@context/AppContext'
import React, { FunctionComponent } from 'react'

interface UseAppContextProps {
  pageTitle: string
  contentId: string
  deviceOS?: string
  deviceType?: string
  basePath?: string
  siteSection: string
  program?: string
  children?: React.ReactNode
}

const UseAppContext: FunctionComponent<UseAppContextProps> = ({
  children,
  pageTitle,
  contentId,
  deviceOS = '',
  deviceType = '',
  basePath,
  siteSection,
  program = ''
}) => {
  return (
    <AppProvider
      value={{
        pageTitle,
        contentId,
        deviceOS,
        deviceType,
        basePath,
        siteSection,
        program
      }}
    >
      {children}
    </AppProvider>
  )
}

export default UseAppContext
