export default {
  ArticleMadlib: 'feature-madlib',
  BlockContent: 'feature-block-content',
  ArticleSurvey: 'feature-survey-air',
  Journey: 'feature-journey',
  QuizCard: 'feature-quiz-card-gate',
  WunderPromo: 'feature-wunder-promo-gate',
  WunderSidePromo: 'feature-wunder-side-promo',
  WunderModal: 'feature-wunder-modal-gate',
  IosGate: 'feature-ios-gate',
  PeopleLink: 'feature-people-link',
  ArticleTopicSelector: 'feature-article-topics-selector',
  ArticleRelatedContent: 'feature-article-related-content',
  PodcastHeaderVideo: 'feature-podcast-header-video',
  DonatePageVideo: 'feature-donate-page-video',
  MadlibChallengerAndPivot: 'feature-madlib-challengers-and-pivots',
  ChatBot: 'feature-chat-bot',
  ChatBotBanner: 'feature-chat-bot-banner',
  ChatBotNavBar: 'feature-chat-bot-navbar'
} as const
