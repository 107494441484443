import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGthumbsNeutral = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 20L10 16L4.00009 12L-3.49691e-07 12L-4.37114e-07 10C0.000154238 6 2.00009 6 6.00001 6L6.00001 4C6.00001 2 8.08005 -3.5319e-07 10 -4.37115e-07L14.0001 -6.11965e-07C18.0001 -7.86811e-07 20 2 20 6L20 20L10 20ZM4.00009 10L2 10C2.00004 9.13671 2.11282 8.702 2.20268 8.49981C2.26165 8.36714 2.30356 8.33433 2.39452 8.28885C2.5576 8.20731 2.8848 8.11029 3.54116 8.05559C4.19081 8.00145 4.97413 8 6.00001 8C7.10458 8 8.00001 7.10457 8.00001 6L8.00001 4C8.00001 3.67468 8.19319 3.15873 8.68484 2.67355C9.18011 2.18481 9.70199 2 10 2L14.0001 2C15.6918 2 16.5932 2.42157 17.0858 2.91419C17.5784 3.40682 18 4.30825 18 6L18 14L10.6056 14L5.1095 10.3359C4.78096 10.1169 4.39494 10 4.00009 10ZM12 16L12 18L18 18L18 16L12 16Z'
      />
    </svg>
  )
}

export default SVGthumbsNeutral
