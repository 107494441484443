// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Quote component would map to contentful Quote */
function Quote ({ children }: IContentfulBlock) {
  return (
    <div data-testid='quote' className='pb-sm-1 pb-md-2'>
      <blockquote className='f-sm-1 f-md-2 mb-sm-1 mb-md-2 text-foreground-darkblue'>
        {children}
      </blockquote>
    </div>
  )
}

Quote.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Quote
