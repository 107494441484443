import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { LanguageContext } from '@context/LanguageContext'
import SVGmenu from '@fractal/primitives/SVGs/icons/menu'
import SVGsearch from '@fractal/primitives/SVGs/icons/search'
import SVGunderstood from '@fractal/primitives/SVGs/logos/understood'
import { LanguageToggle } from '@fractal/primitives/molecules/Toggles/LanguageToggle'
import React, { useContext } from 'react'
import HeaderContext from './HeaderContext'
import UserLogin from './UserLogin'
import {
  StyledDonateLink,
  StyledMenuButton,
  StyledSearchButton
} from './styled'
import { IIconHeader } from './types'

const IconHeader = ({
  CTAButtonUrl,
  CTAButtonText,
  showCTA,
  isTakeNote,
  transparentBackground,
  showAccount,
  trackingCTAButtonName,
  searchAlwaysOpened,
  dropdownTitle,
  dropdownUrl
}: IIconHeader) => {
  const { enableLocaleSwitcher } = useContext(LanguageContext)
  const { trackNavigation } = useContext(AnalyticsContext)

  const {
    searchDisabled,
    searchbarOpen,
    sidebarOpen,
    toggleSearchBar,
    toggleSideBar
  } = useContext(HeaderContext)

  const handleSearchClick = () => {
    toggleSearchBar!(searchbarOpen)
    trackNavigation({
      eventType: 'click',
      buttonName: 'search',
      unitName: 'main_menu',
      unitLocation: 'header'
    })
  }
  const handleMenuClick = () => {
    toggleSideBar!(!sidebarOpen)
    trackNavigation({
      eventType: 'click',
      buttonName: 'sidebar_menu',
      unitName: 'main_menu',
      unitLocation: 'header'
    })
  }

  const background = isTakeNote
    ? 'bg-background-high-darkblue'
    : transparentBackground
      ? ''
      : 'bg-static-darkblue'

  return (
    <header
      data-testid='iconHeader'
      aria-label='Header'
      className={`${background} px-sm-4 px-lg-5 py-sm-4`}
    >
      <nav className='d-sm-flex flex-sm-align-center' aria-label='Main'>
        <div className='d-sm-flex flex-sm-grow-1 flex-sm-align-center'>
          <Link
            data-testid='iconHeader-understood-home-link'
            className={`${transparentBackground
                ? 'text-foreground-darkblue'
                : 'text-static-white'
              }`}
            href={'/'}
            aria-label='Understood Home'
            onClick={() => {
              trackNavigation({
                eventType: 'click',
                buttonName: 'understood_home',
                unitName: 'main_menu',
                unitLocation: 'header'
              })
            }}
          >
            <SVGunderstood className='d-sm-block target vicon' />
          </Link>
        </div>

        {CTAButtonText && CTAButtonUrl && showCTA && (
          <div className='mr-sm-5 d-sm-none d-md-block no-print'>
            <a
              className='Btn Btn--primary Btn--small no-underline d-sm-inline-block bg-static-scarletTint'
              data-testid='iconHeader-donate-button'
              href={CTAButtonUrl}
              target='_blank'
              onClick={() => {
                trackNavigation({
                  eventType: 'click',
                  unitName: 'main_menu',
                  unitLocation: 'header',
                  isExternal: true,
                  linkedContentUrl: CTAButtonUrl,
                  buttonName: trackingCTAButtonName || ''
                })
              }}
            >
              {CTAButtonText}
              <span className='sr-only'>Opens new window</span>
            </a>
          </div>
        )}

        {!searchAlwaysOpened && (
          <StyledSearchButton
            searchbarOpen={searchbarOpen}
            searchDisabled={searchDisabled}
            onClick={handleSearchClick}
            transparentBackground={transparentBackground}
          >
            <SVGsearch className='vicon' />
          </StyledSearchButton>
        )}

        {enableLocaleSwitcher && (
          <LanguageToggle
            transparentBackground={transparentBackground}
            className='d-sm-none d-md-inline-flex mx-sm-n2 no-print'
          />
        )}

        {showAccount && (
          <UserLogin
            transparentBackground={transparentBackground}
            dropdownTitle={dropdownTitle}
            dropdownUrl={dropdownUrl}
          />
        )}

        <StyledMenuButton
          onClick={handleMenuClick}
          transparentBackground={transparentBackground}
        >
          <SVGmenu className='vicon' />
        </StyledMenuButton>
      </nav>
    </header>
  )
}

export default IconHeader
