import SVGnewChat from '@/journey/Svgs/icons/newChat'
import { useState } from 'react'
import StartOverModal from './StartOverModal'

export default function StartOverButton() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <>
      <button
        type='button'
        aria-label='Start new chat'
        className='text-foreground-darkblue Btn-link flex-sm-align-center flex-sm-justify-center icon-hover no-print target'
        onClick={openModal}
      >
        <SVGnewChat />
      </button>
      <StartOverModal isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}
