// @ts-nocheck
import * as PropTypes from 'prop-types'
import React from 'react'
import { IContentfulBlock } from './types'

/** ListItem component would map to contentful LIST_ITEM */
function ListItem ({ children }: IContentfulBlock) {
  return (
    <li data-testid='listItem' className='mb-sm-3 mb-md-4'>
      {children}
    </li>
  )
}

ListItem.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default ListItem
