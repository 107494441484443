'use client'

import { LanguageContext } from '@context/LanguageContext'
import i18next from 'i18next'
import { useContext } from 'react'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import { DEFAULT_NAMESPACE, getiI18nextOptions } from './settings'

i18next
  .use(initReactI18next)
  .init(getiI18nextOptions())

export default function useTranslation() {
  const { langAbbrev } = useContext(LanguageContext)
  return useTranslationOrg(DEFAULT_NAMESPACE, { lng: langAbbrev })
}
