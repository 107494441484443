import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGtakeNoteWave = ({ className = '' }: ISVGsProps) => {
  return (
    <>
      <svg
        className={`d-sm-none d-md-block w-sm-full ${className}`}
        viewBox='0 0 1280 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        focusable='false'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1280 52H0V31.2152C0 31.2152 102.4 0 327.68 0C552.96 0 628.053 31.2152 904.533 31.2152C1181.01 31.2152 1280 0 1280 0L1280 52Z'
        />
      </svg>
      <svg
        className={`d-sm-block d-md-none ${className}`}
        viewBox='0 0 375 33'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M375 33L0 33V14.6024C0 14.6024 30 0 96 0C162 0 184 16.5062 265 16.5062C346 16.5062 375 1.90385 375 1.90385L375 33Z'
        />
      </svg>
    </>
  )
}

export default SVGtakeNoteWave
