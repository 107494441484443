import { LanguageContext } from '@context/LanguageContext'
import { PreferencesProvider } from '@context/PreferencesContext'
import { UserContext } from '@context/UserContext'
import { useCustomUserData } from '@context/hooks/useCustomUserData'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import setUserPreferences from 'services/members/setUserPreferences'
import getTags from 'services/preferences/preferences-resolver'
import { PreferencesTypes } from 'services/preferences/types'

interface UsePreferencesContextProps {
  children: React.ReactNode
  topic?: any
  preselected?: string[]
  preselectedInterests?: string[]
  allInternalTags?: any[]
}

export default function UsePreferencesContext(
  {
    children,
    topic = null as any,
    preselected = [],
    preselectedInterests = [],
    allInternalTags = []
  }: UsePreferencesContextProps
) {
  const { user, isAuthenticated } = useContext(UserContext)
  const { language } = useContext(LanguageContext)
  const [data, setData] = useState<PreferencesTypes>()
  const [customData, setCustomData] = useCustomUserData({ monitorCookieChanges: false })
  const [internalTags] = useState(allInternalTags as any[])
  const [selectedTags, setSelectedTags] = useState<string[]>(
    customData?.selectedPreferences || []
  )
  const [selectedInternalTags, setSelectedInternalTags] = useState<string[]>(
    customData?.selectedInternalTags || []
  )
  const wasAuthenticated = useRef(isAuthenticated)

  const refetchPreferences = async (): Promise<PreferencesTypes> => {
    return getTags(queryVars)
      .then((userPreferences: PreferencesTypes) => (userPreferences))
  }

  const cookies = new Cookies()
  const userToken = cookies.get('user-jwt') || null

  let queryVars: any

  if (userToken && isAuthenticated) {
    queryVars = {
      id: user?.id,
      locale: language,
      authHeader: userToken
    }
  } else {
    queryVars = {
      locale: language,
      context: {}
    }
  }

  const preferences = topic
    ? [{ ...topic, id: topic.sys?.id }]
    : data?.items || []

  const setPreferences = async (pref: any[], updateServer: boolean = true) => {
    const externalTags = preferences.length
      ? pref?.filter(t => preferences.some(i => i.id === t))
      : pref
    if (isAuthenticated && updateServer && userToken) {
      await setUserPreferences(
        {
          userId: user.id,
          tags: externalTags,
          authHeader: userToken
        }
      )
    }
    setCustomData('selectedPreferences', externalTags)
    setSelectedTags(externalTags)
  }

  useEffect(() => {
    getTags(queryVars)
      .then((userTags: PreferencesTypes) => setData(userTags))
  }, [])

  const handleSetSelectedInternalTags = (tags: any[]) => {
    const internalTagList = tags?.filter(
      (t: any) =>
        internalTags.some(i => i.id === t) || preferences.every(i => i.id !== t)
    )
    setCustomData('selectedInternalTags', internalTagList)
    setSelectedInternalTags(internalTagList)
  }

  useEffect(() => {
    handleSetSelectedInternalTags(
      Array.from(new Set([...preselectedInterests, ...selectedInternalTags]))
    )
    setPreferences(Array.from(new Set([...preselected, ...selectedTags]))).then()
  }, [JSON.stringify(preselected), JSON.stringify(preselectedInterests)])

  useEffect(() => {
    if (wasAuthenticated.current && !isAuthenticated) {
      // clears the selected tags when the user signs off
      setPreferences([], false).then()
      handleSetSelectedInternalTags([])
      wasAuthenticated.current = false
      return
    }
    const getPreferences = async () => {
      const refetchData = await refetchPreferences()
      return refetchData?.items
        ?.filter(item => item.selected)
        .map(item => item?.sys?.id)
    }
    const mergePreferences = async () => {
      const currentItems = await getPreferences()
      const newPreferences = Array.from(
        new Set([...currentItems, ...selectedTags])
      )
      await setPreferences(newPreferences)
    }

    if (isAuthenticated) {
      mergePreferences().then()
      wasAuthenticated.current = true
    }
  }, [isAuthenticated, user])

  useEffect(() => {
    if (data && data?.items) {
      const serverSelected = data?.items
        ?.filter(item => {
          return item.selected === true
        })
        .map(item => item.id || item.sys.id)
      const newItems = Array.from(new Set([...serverSelected, ...selectedTags]))
      setPreferences(newItems).then()
    }
  }, [data])

  return (
    <PreferencesProvider
      value={{
        preferences,
        internalTags,
        setPreferences,
        refetchPreferences,
        selectedInternalTags,
        selectedPreferences: topic ? [topic?.sys?.id] : selectedTags,
        setSelectedInternalTags: handleSetSelectedInternalTags
      }}
    >
      {children}
    </PreferencesProvider>
  )
}
