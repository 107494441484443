'use client'

import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { AppContext } from '@context/AppContext'
import { LanguageContext } from '@context/LanguageContext'
import SVGcaretRight from '@fractal/primitives/SVGs/icons/caretRight'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import SVGunderstood from '@fractal/primitives/SVGs/logos/understood'
import { LanguageToggle } from '@fractal/primitives/molecules/Toggles/LanguageToggle'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { isExternalLink } from '../../utils'
import styles from './Header.module.scss'
import HeaderContext from './HeaderContext'
import Mask from './Mask'
import { ISideBar } from './types'

const appDomain = process.env.NEXT_PUBLIC_APP_DOMAIN

const ReactSidebar = ({
  links,
  CTAButtonText,
  CTAButtonUrl,
  CTATitle,
  CTABottomLinkText,
  CTABottomLinkUrl,
  trackingCTAButtonName,
  trackingCTALinkName,
  disableLanguageToggle
}: ISideBar) => {
  const { contentId } = useContext(AppContext)
  const { trackNavigation, trackEngagement } = useContext(AnalyticsContext)
  const { sidebarOpen, toggleSideBar, showOverlay } = useContext(HeaderContext)
  const closeButton = useRef<HTMLButtonElement>()
  const { enableLocaleSwitcher, slug } = useContext(LanguageContext)
  const [nestedOpens, setNestedOpens] = useState({})
  let firstFocusableElement: HTMLElement | null = null
  let lastFocusableElement: HTMLElement | null = null

  useEffect(() => {
    const focusableElements = 'button, [href]'
    const sidebar = document.querySelector('#react-sidebar')
    const focusableContent = sidebar?.querySelectorAll(focusableElements)

    if (focusableContent?.length && focusableContent?.length > 2) {
      firstFocusableElement = focusableContent[2] as HTMLElement
      lastFocusableElement = focusableContent[
        focusableContent.length - 1
      ] as HTMLElement
    }
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [])

  useEffect(() => {
    let currentFocused
    if (closeButton.current && sidebarOpen) {
      currentFocused = document.activeElement
      setTimeout(() => closeButton.current?.focus(), 200)
    }

    return () => {
      setTimeout(() => currentFocused?.focus(), 300)
    }
  }, [closeButton.current, sidebarOpen])

  const stripLocale = (slug: string): string => {
    if (!slug) {
      return ''
    }
    return slug.replace('/es-mx', '').replace('/en', '')
  }

  const handleclick = (open: boolean, label: string, link: any): void => {
    setNestedOpens(prev => ({ ...prev, [label]: !prev[label] }))
    trackNavigation({
      eventType: 'click',
      buttonName: `subpanel:${link?.id}`,
      unitName: 'sidebar_menu',
      unitLocation: 'modal'
    })
  }
  const handleNestedClick = (label: string, url: string) => {
    toggleSideBar!(false)
    trackNavigation({
      eventType: 'click',
      buttonName: label,
      isExternal: isExternalLink(url),
      linkedContentUrl: isExternalLink(url) ? url : `${appDomain}${url}`,
      unitName: 'sidebar_menu',
      unitLocation: 'modal'
    })
  }
  const handleClose = (): void => {
    toggleSideBar!(false)
    trackEngagement({
      eventType: 'click' as any,
      buttonName: 'dismiss',
      unitName: 'sidebar_menu',
      unitLocation: 'modal'
    })
  }
  const handleTabControl = event => {
    // Trap the focus in the sidebar
    if (document.activeElement === lastFocusableElement && !event.shiftKey) {
      if (closeButton.current) {
        closeButton.current.focus()
        event.preventDefault()
      }
      return
    }
    if (document.activeElement === closeButton.current && event.shiftKey) {
      if (lastFocusableElement) {
        lastFocusableElement.focus()
        event.preventDefault()
      }
      return
    }
  }

  const escFunction = useCallback(
    event => {
      if (event.keyCode === 27) {
        toggleSideBar(false)
        showOverlay(false)
      }

      if (event.keyCode === 9) {
        handleTabControl(event)
      }
    },
    [handleTabControl]
  )

  return (
    <>
      <div
        id='react-sidebar'
        data-testid='sidebar'
        className={`d-sm-block bg-background-page-white text-foreground-darkblue right-sm-0 p-sm-fixed top-sm-0 o-sm-y-scroll
          w-sm-full width-sm-fit d-sm-flex flex-sm-column flex-sm-justify-between ${styles.sidebar} ${sidebarOpen ? styles.open : ''}`}
      >
        <div>
          <div className='d-sm-flex m-sm-5'>
            <Link
              href='/'
              aria-label='Understood Home'
              className='flex-sm flex-sm-align-center d-sm-flex'
              onClick={() => {
                trackNavigation({
                  eventType: 'click',
                  buttonName: 'understood_home',
                  linkedContentId: '107m4TP8wIw0b1uuxYYQMe',
                  linkedContentUrl: appDomain,
                  unitName: 'sidebar_menu',
                  unitLocation: 'modal'
                })
              }}
            >
              <SVGunderstood />
            </Link>
            <button
              type='button'
              onClick={handleClose}
              aria-label='close menu'
              ref={closeButton as any}
              data-testid='sidebarCloseButton'
              className='Btn-link target flex-sm-align-center flex-sm-justify-center p-sm-absolute right-sm-0 top-sm-0 target'
            >
              <SVGclose className='vicon' />
            </button>
          </div>
          <nav className='w-sm-full'>
            <ul className='list-reset mt-sm-0 ml-sm-5 mb-sm-3'>
              {!disableLanguageToggle && enableLocaleSwitcher && (
                <li className='Btn-link text-left w-sm-full f-sm-5 pb-sm-0 d-sm-flex flex-sm-align-center'>
                  <LanguageToggle
                    className='bg-background-high-grey2'
                    withBackground={true}
                  />
                </li>
              )}
            </ul>
            <ul className='list-reset m-sm-0 px-sm-5 pb-sm-5'>
              <>
                {links &&
                  links
                    .filter(link => !link?.sub)
                    .map(link => {
                      const { text, id, children, url, label } = link
                      return (
                        <li
                          key={`sidebaritem-${id}`}
                          className={
                            children
                              ? 'border-sm-bottom border-static-grey3'
                              : ''
                          }
                        >
                          {children ? (
                            <>
                              <button
                                id={`sidebaritem-button-${label}`}
                                onClick={() => handleclick(true, label, link)}
                                aria-controls={`sidebaritem-submenu-${label}`}
                                aria-expanded={
                                  nestedOpens[label] ? 'true' : 'false'
                                }
                                aria-current={
                                  stripLocale(url) == slug ? 'page' : undefined
                                }
                                className='Btn-link text-left w-sm-full f-sm-5 py-sm-4 d-sm-flex flex-sm-align-center text-wrap'
                              >
                                <span className='flex-sm'>{text}</span>
                                <SVGcaretRight
                                  className={`${nestedOpens[label]
                                    ? 'rotate-270'
                                    : 'rotate-90'
                                    } w-sm-4 h-sm-4 text-static-scarlet vicon flex-shrink-0`}
                                />
                              </button>
                              <ul
                                id={`sidebaritem-submenu-${label}`}
                                className={`list-reset ${nestedOpens[label] ? '' : 'd-sm-none'
                                  }`}
                              >
                                {children.map(submenu => {
                                  const { text, id, url, label } = submenu
                                  return (
                                    <li key={`sidebarsubitem-${label}`}>
                                      <Link
                                        data-testid={label}
                                        aria-current={
                                          stripLocale(url) == slug
                                            ? 'page'
                                            : undefined
                                        }
                                        href={url}
                                        onClick={() =>
                                          handleNestedClick(label, url)
                                        }
                                        className='secondary-link text-left w-sm-full f-sm-3 mb-sm-4 d-sm-block'
                                      >
                                        {text}
                                      </Link>
                                    </li>
                                  )
                                })}
                              </ul>
                            </>
                          ) : (
                            <div className='py-sm-4 border-sm-bottom border-static-grey3 d-sm-flex flex-sm-align-center'>
                              <Link
                                data-testid={label}
                                key={id}
                                aria-current={
                                  stripLocale(url) == slug ? 'page' : undefined
                                }
                                href={url}
                                onClick={() => {
                                  toggleSideBar!(false)
                                  trackNavigation({
                                    eventType: 'click',
                                    buttonName: label,
                                    isExternal: isExternalLink(url),
                                    linkedContentId: id,
                                    linkedContentUrl: isExternalLink(url)
                                      ? url
                                      : `${appDomain}${url}`,
                                    unitName: 'sidebar_menu',
                                    unitLocation: 'modal'
                                  })
                                }}
                                className='secondary-link d-sm-block text-left w-sm-full f-sm-5'
                              >
                                {text}
                              </Link>
                            </div>
                          )}
                        </li>
                      )
                    })}
                {links &&
                  links.map(item => {
                    const { text, sub, url, id, label } = item
                    return (
                      sub && (
                        <li
                          key={`smaller-links${label}`}
                          className='my-sm-4 my-md-4 d-sm-flex'
                        >
                          <Link
                            className='secondary-link f-sm-3 lh-default no-underline'
                            data-testid={label}
                            href={url}
                            aria-current={
                              stripLocale(url) == slug ? 'page' : undefined
                            }
                            onClick={() => {
                              toggleSideBar!(false)
                              trackNavigation({
                                eventType: 'click',
                                buttonName: label,
                                isExternal: isExternalLink(url),
                                linkedContentId: id,
                                linkedContentUrl: isExternalLink(url)
                                  ? url
                                  : `${appDomain}${url}`,
                                unitName: 'sidebar_menu',
                                unitLocation: 'modal'
                              })
                            }}
                          >
                            {text}
                          </Link>
                        </li>
                      )
                    )
                  })}
              </>
            </ul>
          </nav>
        </div>
        {/* <OptimizelyFeature feature="side-menu-donate-button">
          {(enabled) => ( */}
        {CTAButtonText && CTAButtonUrl && (
          <div className='w-sm-full py-sm-4 px-sm-5 bg-background-high-darkblue'>
            <div className='d-sm-flex flex-sm-align-center flex-sm-column'>
              {CTATitle && (
                <p className='text-center text-static-white mb-sm-4 mx-md-5 f-sm-4'>
                  {CTATitle}
                </p>
              )}
              <a
                href={CTAButtonUrl}
                target='_blank'
                className='Btn Btn--primary no-underline'
                onClick={() => {
                  toggleSideBar!(false)
                  trackNavigation({
                    eventType: 'click',
                    unitName: 'sidebar_menu',
                    unitLocation: 'modal',
                    buttonName: trackingCTAButtonName || null,
                    linkedContentUrl: CTAButtonUrl
                  })
                }}
              >
                {CTAButtonText}
                <span className='sr-only'>Opens new window</span>
              </a>
              {CTABottomLinkText && CTABottomLinkUrl && (
                <Link
                  href={CTABottomLinkUrl}
                  className='text-center text-static-white my-sm-4 f-sm-3 text-bold'
                  onClick={() => {
                    toggleSideBar!(false)
                    trackNavigation({
                      eventType: 'click',
                      buttonName: trackingCTALinkName || null,
                      linkedContentId: contentId,
                      linkedContentUrl: isExternalLink(CTABottomLinkUrl)
                        ? CTABottomLinkUrl
                        : `${appDomain}${CTABottomLinkUrl}`,
                      unitName: 'sidebar_menu',
                      unitLocation: 'modal'
                    })
                  }}
                >
                  {CTABottomLinkText}
                </Link>
              )}
            </div>
          </div>
        )}
        {/* )}
        </OptimizelyFeature> */}
      </div>
      {sidebarOpen && <Mask transparent={false} handleClick={handleClose} />}
    </>
  )
}

export default ReactSidebar
