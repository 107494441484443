import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGwave = ({ className = '' }: ISVGsProps) => {
  return (
    <>
      <svg
        viewBox='0 0 375 20'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`d-md-none d-sm-block ${className}`}
        aria-hidden='true'
        focusable='false'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 0H375V7.99414C375 7.99414 345 20 279 20C213 20 191 7.99414 110 7.99414C29 7.99414 0 20 0 20L0 0Z'
        />
      </svg>
      <svg
        viewBox='0 0 1280 32'
        fill='none'
        preserveAspectRatio='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`d-sm-none d-md-block ${className}`}
        aria-hidden='true'
        focusable='false'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 0H1280V12.7906C1280 12.7906 1177.6 32 952.32 32C727.04 32 651.947 12.7906 375.467 12.7906C98.9867 12.7906 0 32 0 32L0 0Z'
        />
      </svg>
    </>
  )
}

export default SVGwave
