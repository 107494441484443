import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGprofile = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-label='Main menu'
      role='img'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35 15c0-5.5-4.5-10-10-10S15 9.5 15 15s4.5 10 10 10 10-4.5 10-10zm-25 0c0-8.3 6.7-15 15-15s15 6.7 15 15-6.7 15-15 15-15-6.7-15-15zm30 29c0-2.8-2.2-5-5-5H15c-2.8 0-5 2.2-5 5v1h30v-1zM15 34h20c5.5 0 10 4.5 10 10v6H5v-6c0-5.5 4.5-10 10-10z'
      />
    </svg>
  )
}

export default SVGprofile
