import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGprofileFilled = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        d='M29.853 17.5c0 6.822-5.53 12.353-12.353 12.353-6.822 0-12.353-5.53-12.353-12.353 0-6.822 5.53-12.353 12.353-12.353 6.822 0 12.353 5.53 12.353 12.353zm5.147 0C35 27.165 27.165 35 17.5 35S0 27.165 0 17.5 7.835 0 17.5 0 35 7.835 35 17.5zM31 35l15 15 4-4-15-15-4 4z'
      />
    </svg>
  )
}

export default SVGprofileFilled
