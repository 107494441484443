import * as React from 'react'
import { ISVGsProps } from '../types'

//Logo
const SVGunderstood = ({
  className = '',
  width = '134',
  height = '32',
  fill = '#002938'
}: ISVGsProps) => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1637.8 372'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M483.4,203.8v-91.6H510v91.6c0,16,13,29.4,29,29.4s29-13.4,29-29.4v-91.6h26.2v91.6 c0,29.8-24.8,54-55.2,54C508.4,257.8,483.4,233.6,483.4,203.8z' />
      <path d='M615.4,155.4h26.2v14c5.6-9.6,17-15.6,31.2-15.6c22.6,0,37.4,16.4,37.4,39.8v62.6H684v-57 c0-12.8-8.2-22-20.2-22c-13,0-22.2,10-22.2,23.8v55.2h-26.2L615.4,155.4L615.4,155.4z' />
      <path d='M837.4,234.4v21.8h-6.2c-15.2,0-23.6-5.6-26.6-15c-6.8,11.6-20.2,16.6-31,16.6 c-29.6,0-47.4-21-47.4-52s17.8-52,47.4-52c12.2,0,23.4,5.8,28.6,14.8V112h26.2v115.2c0,4.4,2.4,7.2,6.2,7.2H837.4z M804,205.8 c0-17.8-10.4-29.4-26-29.4s-26,11.6-26,29.4s10.4,29.4,26,29.4S804,223.6,804,205.8z' />
      <path d='M944.4,211.4h-73.2c1.8,15.2,11.4,24.8,26.2,24.8c12,0,19.8-4.8,22.2-14l24.4,5.2 c-5.8,18.6-23.6,30.4-46.6,30.4c-33.6,0-51.4-26.4-51.4-52.2c0-26.2,16-51.8,49.6-51.8c34.6,0,49.2,25.4,49.2,48.8 C944.8,206.4,944.6,209.4,944.4,211.4z M918.6,194.4c-0.8-11.8-9-21.2-23-21.2c-13.2,0-21.4,6.6-24,21.2H918.6z' />
      <path d='M1008.4,177.4c-14.8,0-21,12.4-21,30.4v48.4h-26.2V155.4h26.2v17.2c5.2-12.6,13-17.2,25.6-17.2h11.6 v22H1008.4z' />
      <path d='M1030.2,227.2l22.2-4.6c1.2,9.4,8.4,15.2,20,15.2c8.8,0,15-4.6,15-10.6c0-21.8-54.8-2-54.8-43.6 c0-17.4,15.2-29.8,36-29.8c22,0,37.2,10.8,40.6,28.2l-22.4,4.8c-1.4-9.6-9.8-14.4-17.6-14.4c-8.4,0-14.2,4-14.2,10.2 c0,20.8,55.8-1.8,55.8,43.8c0,19-15.4,31.4-39,31.4C1049.4,257.8,1033.6,246,1030.2,227.2z' />
      <path d='M1164.6,125.4v30h27.6v22h-27.6v41.8c0,10,5.4,15,14.4,15h13.2v22h-16.4c-22.6,0-37.4-13-37.4-36 v-42.8h-19.6v-22h19.6v-30L1164.6,125.4z' />
      <path d='M1200.4,205.8c0-30,22-52,52.8-52s53,22,53,52s-22.2,52-53,52S1200.4,235.8,1200.4,205.8z M1279.8,205.8c0-17.4-10.6-29.4-26.6-29.4c-15.8,0-26.4,12-26.4,29.4s10.6,29.4,26.4,29.4C1269.2,235.2,1279.8,223.2,1279.8,205.8z ' />
      <path d='M1317,205.8c0-30,22-52,52.8-52s53,22,53,52s-22.2,52-53,52S1317,235.8,1317,205.8z M1396.4,205.8 c0-17.4-10.6-29.4-26.6-29.4c-15.8,0-26.4,12-26.4,29.4s10.6,29.4,26.4,29.4C1385.8,235.2,1396.4,223.2,1396.4,205.8L1396.4,205.8z' />
      <path d='M1544.8,234.4v21.8h-6.2c-15.2,0-23.6-5.6-26.6-15c-6.8,11.6-20.2,16.6-31,16.6 c-29.6,0-47.4-21-47.4-52s17.8-52,47.4-52c12.2,0,23.4,5.8,28.6,14.8V112h26.2v115.2c0,4.4,2.4,7.2,6.2,7.2H1544.8z M1511.4,205.8 c0-17.8-10.4-29.4-26-29.4s-26,11.6-26,29.4s10.4,29.4,26,29.4S1511.4,223.6,1511.4,205.8L1511.4,205.8z' />
      <path d='M127.5,232.5l-0.5,0.2c11.9,18.1,31.3,29.7,52.9,31.8C158.5,262.3,139.3,250.5,127.5,232.5z' />
      <path d='M181.6,264.6l1.2,0.1c-1-0.1-1.9-0.1-2.9-0.2C180.5,264.5,181.1,264.6,181.6,264.6z' />
      <path d='M351.6,172L351.6,172c-21.6-29.5-21.6-29.6-18.2-63.1l0,0c0-0.1,0-0.2,0-0.3l0,0 c3.1-30.3-7.9-60.3-29.9-81.4c-12.5-12.3-28-21-44.9-25.2v191.5c0,39.4-31.9,71.4-71.4,71.4l0,0c-0.5,0-1,0-1.5,0h-1l-1.8-0.1 l-1.2-0.1c-0.6,0-1.1-0.1-1.7-0.2h-0.1c-21.6-2-41-13.7-52.9-31.8l0.5-0.2c-7.6-11.6-11.7-25.2-11.7-39.1V39.1l-4.5-0.5l0,0H111l0,0 C56.5,33.3,8,73.1,2.6,127.6c-2.7,27,5.9,53.9,23.6,74.5c18.3,25.1,18.1,26.9,14.9,58.6l0,0v0.3l0,0 c-5.6,54.3,33.8,102.8,88.1,108.4c25.4,2.6,50.8-4.7,70.9-20.3l0,0c29.5-21.7,29.6-21.6,63.1-18.2l0,0h0.3l0,0 c54.3,5.6,102.8-33.8,108.4-88.1C374.7,217.5,367.4,192.1,351.6,172L351.6,172z' />
      <path d='M184.6,264.8c-0.6,0-1.2,0-1.8-0.1L184.6,264.8z' />
      <g>
        <g>
          <path d='M1565.1,145.9c0-19.3,14.4-33.8,33.8-33.8c19.3,0,33.8,14.5,33.8,33.8c0,19.3-14.5,33.8-33.8,33.8 C1579.5,179.7,1565.1,165.2,1565.1,145.9z M1626.4,145.9c0-16-11.8-27.8-27.5-27.8c-15.8,0-27.7,11.9-27.6,27.8 c-0.1,16,11.8,27.8,27.6,27.8C1614.6,173.7,1626.4,161.9,1626.4,145.9z M1585.5,127.3h15.3c7.6,0,12.3,4.8,12.3,11.5 c0,3.6-1.8,6.1-4.6,7.5c2.3,1.7,3.7,4.2,4.1,7.3l1,10.1h-7.6l-1-9c-0.3-2.4-2.1-4.2-4.5-4.2h-7.2v13.3h-7.8V127.3z M1600.4,143.7 c3.6,0,5.2-2.5,5.2-4.9c0-2.7-1.6-5.1-5.2-5.1h-7.1v10.1H1600.4z' />
        </g>
      </g>
    </svg>
  )
}

export default SVGunderstood
