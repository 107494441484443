const MEMBERS_PATH: string = `${process.env.NEXT_PUBLIC_API_DOMAIN}/members/v1/users/:userId/amp-tags-replace`

interface SetTagsI {
  userId: string
  authHeader: string
  tags: string[]
}

export default async function setUserPreferences(
  {
    userId,
    authHeader,
    tags
  }: SetTagsI
): Promise<any> {
  if (!userId || !authHeader) return null
  const fullUrl: string = MEMBERS_PATH.replace(':userId', userId)

  const formData: FormData = new FormData()
  tags.forEach((tagId: string) => formData.append('tags', tagId))

  const requestOptions = {
    body: formData,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authHeader}`
    }
  }

  const response = await fetch(fullUrl, requestOptions)
  return response.json()
}
