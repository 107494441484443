// @ts-nocheck
import React from 'react'
import { ITable } from './types'
export const Table = ({ table }: ITable) => {
  return (
    <div className='table-responsive table-striped rounded-sm-3 my-sm-6 text-foreground-darkblue'>
      <div dangerouslySetInnerHTML={{ __html: table }} />
    </div>
  )
}

export default Table
