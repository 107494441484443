'use client'

import React, { useMemo, useState } from 'react'
import HeaderContext from '@fractal/features/Header/HeaderContext'

function HeaderProvider({
  searchAlwaysOpened = false,
  children
}: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [overlay, showOverlay] = useState(false)
  const [searchbarOpen, setSearchbarOpen] = useState(searchAlwaysOpened)
  const toggleSideBar = (sidebar: boolean) => setSidebarOpen(sidebar)
  const toggleSearchBar = (searchbar: boolean) => setSearchbarOpen(!searchbar)

  const contextValue = useMemo(() => ({
    sidebarOpen,
    searchbarOpen,
    overlay,
    showOverlay,
    toggleSideBar,
    toggleSearchBar
  }), [sidebarOpen, searchbarOpen, overlay])

  return (
    <HeaderContext.Provider
      value={contextValue as any}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderProvider
