import getGlobalContext from '../GlobalContext/getGlobalContext.client'
import {
  ISnowplowContentContext,
  ISnowplowPageContext,
  ISnowplowSiteContext
} from './Snowplow.interface'

const isSnakeCase = (val: string) => /^[a-z0-9]+(?:_[a-z0-9]+)*$/.test(val)

function isDarkMode() {
  return window.matchMedia('(prefers-color-scheme: dark)')?.matches
}

function getColorProfile(): string[] {
  return [
    isDarkMode() ? 'dark' : 'light',
    window.matchMedia('(inverted-colors: inverted)')?.matches && 'inverted',
    window.matchMedia('(forced-colors)')?.matches && 'forced',
    window.matchMedia('(prefers-contrast: more)')?.matches && 'contrast'
  ].filter((v) => v) as any
}

function getZoomLevel(): number {
  function isHighDensity() {
    return (
      (window.matchMedia
        // eslint-disable-next-line max-len
        && (window.matchMedia(
          'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)'
        ).matches
          // eslint-disable-next-line max-len
          || window.matchMedia(
            // eslint-disable-next-line max-len
            'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)'
          ).matches))
      || (window.devicePixelRatio && window.devicePixelRatio > 1.3)
    )
  }
  const zoomLevel = Math.round(window.devicePixelRatio * 100) || 100

  // Check hdpi support
  if (isHighDensity()) return zoomLevel / 2

  return zoomLevel
}

function getCustomizationStatus() {
  const customization = window.localStorage.getItem('customization')
  if (!customization) return false
  try {
    return JSON.parse(customization)
  } catch {
    return {
      reduceMotion: false,
      isReduceMotionManuallySet: false
    }
  }
}

export function trimPayload(data: Record<string, any>): Record<string, any> {
  return Object.entries(data).reduce((prev, [k, v]) => {
    if (v === undefined || v === null) {
      return prev
    }
    if (k === 'button_name' && !isSnakeCase(v)) {
      // eslint-disable-next-line no-console
      console.error(
        `Please revise your tracking values. ${k} has invalid pattern: ${v}. See AnalyticsContextProvider.`
      )
    }
    return {
      ...prev,
      [k]: v
    }
  }, {})
}

export default function getSharedContext(
  event: Partial<
  ISnowplowSiteContext & ISnowplowContentContext & ISnowplowPageContext
  >
): any[] {
  const globalContext = getGlobalContext()
  const customizationStatus = getCustomizationStatus()

  return [
    {
      schema: 'iglu:org.understood/site/jsonschema/1-0-8',
      data: trimPayload({
        unit_name: event.unitName,
        unit_location: event.unitLocation,
        unit_variation: event.unitVariation,
        event_horizontal_position: event.eventHorizontalPosition,
        event_vertical_position: event.eventVerticalPosition,
        position_in_unit: event.positionInUnit,
        num_results: event.numResults,
        linked_content_id: event.linkedContentId,
        linked_content_url: event.linkedContentUrl
      })
    },
    {
      schema: 'iglu:org.understood/content/jsonschema/1-0-5',
      data: trimPayload({
        content_id: globalContext.contentId,
        content_type_id: event.contentTypeId || globalContext.contentTypeId,
        component_id: event.componentId,
        parent_component_id: event.parentComponentId,
        position_in_parent_component: event.positionInParentComponent,
        child_component_id: event.childComponentId?.toString()
      })
    },
    {
      schema: 'iglu:org.understood/page/jsonschema/1-0-0',
      data: trimPayload({
        contentful_id: globalContext.contentfulId,
        header_id: globalContext.headerId,
        page_name: globalContext.pageName,
        is_spanish: globalContext.locale !== 'en',
        page_slug:
          globalContext.locale === 'en'
            ? globalContext.slugEn
            : globalContext.slugEs,
        page_title: globalContext.pageTitle,
        site_section: globalContext.siteSection
      })
    },
    {
      schema: 'iglu:org.understood/users/jsonschema/1-0-0',
      data: trimPayload({
        language: globalContext.locale,
        theme: isDarkMode() ? 'dark_mode' : 'light_mode',
        color: getColorProfile(),
        zoom: getZoomLevel(),
        reduced_motion: customizationStatus?.isReduceMotionManuallySet
          ? customizationStatus.reduceMotion
          : window.matchMedia('(prefers-reduced-motion: reduce)').matches
      })
    }
  ]
}
