type Args = {
  basePath?: string
  path?: string
  locale?: string
}

function cleanUrl(url) {
  // Remove any number of consecutive slashes except in 'http://' or 'https://'
  let cleanedUrl = url.replace(/([^:]\/)\/+/g, '$1')

  // Remove trailing slash unconditionally
  if (cleanedUrl.endsWith('/')) {
    cleanedUrl = cleanedUrl.slice(0, -1)
  }

  return cleanedUrl
}

export default function getCanonicalUrls({
  basePath = '', path = '', locale = 'en'
}: Args) {
  const appDomain = process.env.NEXT_PUBLIC_APP_DOMAIN

  let pathWithoutLocale = path.replace(/^\/en\/|^\/es-mx\//, '')
  if (path === '/en' || path === '/es-mx') {
    pathWithoutLocale = ''
  }

  let enUrl = cleanUrl(`${appDomain}/en/${basePath}/${pathWithoutLocale}`)
  let esUrl = cleanUrl(`${appDomain}/es-mx/${basePath}/${pathWithoutLocale}`)

  if (basePath === '' && (path === '/en' || path === '/es-mx' || path === '/' || path === '')) {
    enUrl = cleanUrl(appDomain)
    esUrl = cleanUrl(`${appDomain}/es-mx`)
  }

  return ({
    canonical: locale.includes('en') ? enUrl : esUrl,
    alternate: locale.includes('en') ? esUrl : enUrl,
    alternateEnglish: enUrl,
    alternateSpanish: esUrl
  })
}
