// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Heading6 component would map to contentful Heading_6 */
function Heading6 ({ children }: IContentfulBlock) {
  return (
    <h6
      className=' text-foreground-darkblue f-sm-1 f-md-2 pb-sm-1 pb-md-2'
      data-testid='heading6'
    >
      {children}
    </h6>
  )
}

Heading6.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading6
