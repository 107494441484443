// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Heading2 component would map to contentful Heading_2 */
function Heading2 ({ children }: IContentfulBlock) {
  return (
    <h2
      className=' text-foreground-darkblue f-sm-5 f-md-6 pb-sm-3 pb-md-4 pt-sm-4 pt-md-5'
      data-testid='heading2'
    >
      {children}
    </h2>
  )
}

Heading2.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading2
