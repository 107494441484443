import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

export interface UsePreferencesReturn {
  preferences: any
  setPreference(key, value)
}

export const parseCustomUserData = value => {
  try {
    return JSON.parse(atob(value)) || {}
  } catch {
    return {}
  }
}

export const CUSTOM_USER_DATA_COOKIE = 'u_custom_data'

export function useCustomUserData({
  req = undefined,
  res = undefined,
  monitorCookieChanges = true
} = {}) {
  const cookies = new Cookies(req)

  const [customData, setCustomDataState] = useState(
    parseCustomUserData(cookies.get(CUSTOM_USER_DATA_COOKIE))
  )

  const setCustomData = (key, value) => {
    const oldState = parseCustomUserData(cookies.get(CUSTOM_USER_DATA_COOKIE))
    const newState = { ...oldState, [key]: value }

    const expDate = new Date()
    expDate.setFullYear(expDate.getFullYear() + 2)
    cookies.set(CUSTOM_USER_DATA_COOKIE, btoa(JSON.stringify(newState || {})), {
      path: '/',
      expires: new Date(expDate)
    })

    if (!monitorCookieChanges) setCustomDataState(newState)
    window.dispatchEvent(new CustomEvent('customUserDataChange'))
  }

  useEffect(() => {
    if (!monitorCookieChanges) return

    const handleStorageChange = () => {
      setCustomDataState(
        parseCustomUserData(cookies.get(CUSTOM_USER_DATA_COOKIE))
      )
    }

    window.addEventListener('customUserDataChange', handleStorageChange)

    return () => {
      window.removeEventListener('customUserDataChange', handleStorageChange)
    }
  }, [])

  return [customData, setCustomData]
}
