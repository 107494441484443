import IFrame from '@fractal/primitives/Common/IFrame'
import * as PropTypes from 'prop-types'
import React from 'react'
import { IExternalContentProps } from './types'

function ExternalContent({
  url,
  width = '100%',
  height = '100%'
}: IExternalContentProps) {
  return (
    <div className='d-sm-flex flex-sm-justify-center'>
      <IFrame
        id={url}
        data-testid='externalcontent'
        width={width}
        frameBorder={0}
        scrolling='yes'
        allow='geolocation'
        url={url}
        height={height}
      />
    </div>
  )
}

ExternalContent.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.any.isRequired,
  height: PropTypes.any.isRequired
}

ExternalContent.displayName = 'ExternalContent'

export { ExternalContent }
export default ExternalContent
