// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulMark } from './types'

/** Underline component would map to contentful Underline */
function Underline ({ children }: IContentfulMark) {
  return (
    <u data-testid='underline' className=' text-foreground-darkblue'>
      {children}
    </u>
  )
}

Underline.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Underline
