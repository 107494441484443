import React from 'react'
import { Observable } from 'rxjs'
import { v4 as uuidV4 } from 'uuid'
import { ILoginParams, ISignupParams, IUser, IUserContext } from './types'

const DefaultUserContext: IUserContext = {
  user: {
    id: uuidV4(),
    is_logged_in: false,
    error: false
  },
  login: (arg: ILoginParams): Observable<any> => {
    return Observable.create()
  },
  socialLogin: (provider: string): Observable<any> => {
    return Observable.create()
  },
  signup: (arg: ISignupParams): Observable<any> => {
    return Observable.create()
  },
  requestPassword: (email: string): Observable<any> => {
    return Observable.create()
  },
  updateUser: (user: IUser): void => {},
  logoutUser: (): void => {},
  isAuthenticated: false,
  isAccountModalOpen: false,
  updateAccountModal: (bool: boolean): void => {},
  currentPage: 'signup',
  setCurrentPage: (page: IUserContext['currentPage']) => {}
}

const UserContext = React.createContext(DefaultUserContext)

const { Provider, Consumer } = UserContext

export {
  Provider as UserProvider,
  Consumer as UserConsumer,
  DefaultUserContext
}

export default UserContext
