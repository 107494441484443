import React from 'react'
import { IPreferencesContext } from './types'

const DefaultPreferencesContext: IPreferencesContext = {
  preferences: [],
  internalTags: [],
  selectedPreferences: [],
  selectedInternalTags: [],
  setPreferences: (tags: []): void => {},
  setSelectedInternalTags: (tags): void => {},
  refetchPreferences: () => {}
}

const PreferencesContext = React.createContext(DefaultPreferencesContext)

const { Provider, Consumer } = PreferencesContext

export {
  Provider as PreferencesProvider,
  Consumer as PreferencesConsumer,
  DefaultPreferencesContext
}

export default PreferencesContext
