import SVGdotGreen from '@fractal/primitives/SVGs/icons/dotGreen'
import SVGdotRed from '@fractal/primitives/SVGs/icons/dotRed'
import SVGdotYellow from '@fractal/primitives/SVGs/icons/dotYellow'
import SVGthumbsDown from '@fractal/primitives/SVGs/icons/thumbsDown'
import SVGthumbsNeutral from '@fractal/primitives/SVGs/icons/thumbsNeutral'
import SVGthumbsUp from '@fractal/primitives/SVGs/icons/thumbsUp'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useFormControlContext } from '../FormControl'
import { CheckboxProps } from './Checkbox.interface'
import styles from './Checkbox.module.scss'

const colorMap = {
  ochreLite: 'ochre-lite',
  midblueLite: 'midblue-lite',
  peach: 'peach',
  creamLite: 'cream-lite',
  white: 'white',
  grey2: 'grey2',
  ochreTint: 'ochreTint'
}

const Checkbox = (props: CheckboxProps): React.ReactPortal | null => {
  const { updateContext, labelPortal } = useFormControlContext()

  useEffect(() => {
    updateContext({ control: { ...props } })
  }, [props])

  if (!labelPortal) {
    return null
  }

  // I'm using Portal here to make it work with the current css we have
  // I'm going to change it once I have the tokens.
  // That's because, currently the styles expect the checkbox to be inside the label.

  // @ts-ignore
  return ReactDOM.createPortal(
    <React.Fragment>
      <input
        {...props}
        className={`form-choice form-checkbox ${styles.checkbox} ${props.className} ${
          props.checkboxType === 'emoji' || props.checkboxType === 'pill' ? 'sr-only' : ''
        } ${props.checkboxType === 'switcher' ? 'p-sm-absolute top-sm-0 left-sm-0 m-sm-0 p-sm-0 o-0' : ''}`}
      />
      {props.checkboxType === 'default' && <span className='form-choice-span' />}
      {props.checkboxType === 'emoji' && props.iconName && (
        <span
          className={`d-sm-flex flex-sm-align-center flex-sm-column rounded-sm-1 pt-sm-2 px-sm-1 pb-sm-3 ${styles.custom}`}
          style={{
            '--selected-background-color': `var(--color-background-high-${colorMap[props.selectedBackgroundColor || 'ochreLite']})`,
            '--unselected-background-color': `var(--background-low-${colorMap[props.unselectedBackgroundColor || 'grey2']})`
          } as any}
        >
          <img className='mb-sm-1 w-sm-6 h-sm-6' src={props.iconName} alt='' />
          {props.label}
        </span>
      )}
      {props.checkboxType === 'pill' && props.label && (
        <span
          className={`d-sm-block p-sm-3 f-sm-3 f-lg-4 text-center text-bold pill-sm  ${styles.custom}`}
          style={{
            '--selected-background-color': `var(--color-background-high-${colorMap[props.selectedBackgroundColor || 'ochreLite']})`,
            '--unselected-background-color': `var(--background-low-${colorMap[props.unselectedBackgroundColor || 'grey2']})`
          } as any}
        >
          <div className='row'>
            {props.iconName && (
              <div className='d-sm-flex flex-sm-justify-end flex-sm-align-center'>
                {props.iconName === 'thumbs_up' ? (
                  <SVGthumbsUp className='vicon' />
                ) : props.iconName === 'thumbs_down' ? (
                  <SVGthumbsDown className='vicon' />
                ) : props.iconName === 'thumbs_neutral' ? (
                  <SVGthumbsNeutral className='vicon' />
                ) : props.iconName === 'green_dot' ||
                  props.iconName === 'dotGreen' ? (
                  <SVGdotGreen className='vicon' />
                ) : props.iconName === 'red_dot' ||
                  props.iconName === 'dotRed' ? (
                  <SVGdotRed className='vicon' />
                ) : props.iconName === 'yellow_dot' ||
                  props.iconName === 'dotYellow' ? (
                  <SVGdotYellow className='vicon' />
                ) : null}
              </div>
            )}
            <div className={props.iconName ? 'col-sm-9 f-sm-2 text-left d-sm-flex flex-sm-align-center pl-sm-4' : 'col-sm-12'}>
              {props.label}
            </div>
          </div>
        </span>
      )}
      {props.checkboxType === 'switcher' && (
        <span className={`p-sm-relative d-sm-inline-block w-sm-6 pill-sm text-foreground-grey4 ${styles.switcher}`} />
      )}
    </React.Fragment>,
    labelPortal
  )
}

Checkbox.defaultProps = {
  type: 'checkbox',
  checkboxType: 'default',
  selectedBackgroundColor: 'ochreLite',
  unselectedBackgroundColor: 'grey2'
}

export default Checkbox
