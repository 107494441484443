import React from 'react'
import { IUseInViewContext } from './types'

const DefaultUseInViewContext: IUseInViewContext = {
  isTopNavVisible: true,
  isBottomCTAVisible: false,
  updateTopNav: (bool: boolean): void => {},
  updateBottomCTA: (bool: boolean): void => {}
}

const UseInViewContext = React.createContext(DefaultUseInViewContext)

const { Provider, Consumer } = UseInViewContext

export {
  Provider as UseInViewProvider,
  Consumer as UseInViewConsumer,
  DefaultUseInViewContext
}

export default UseInViewContext
