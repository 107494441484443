import {
  CustomStatsigProvider as CustomStatsigProviderComponent,
  GATE_LAYERS,
  ICustomStatsigProviderParams,
  RegisterFuncReturn
} from '@context/CustomStatsigContext'
import { LanguageContext } from '@context/LanguageContext'
import { UserContext } from '@context/UserContext'
import { useCustomUserData } from '@context/hooks/useCustomUserData'
import sample from 'lodash/sample'
import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import statsig from 'statsig-js'
import Cookies from 'universal-cookie'
import { v4 as uuidV4 } from 'uuid'
import { isFunction, setTimeoutWithCancel } from './helpers'

const STATSIG_COOKIE_NAME = 'statsig_user_v2'
const STATSIG_CONFIG_STORAGE_KEY = 'statsig_config'

interface ICustomStatsigProviderProps extends ICustomStatsigProviderParams {
  onInit?: () => void
}

function CustomStatsigProvider({
  children,
  statsigConfig = {},
  onInit
}: ICustomStatsigProviderProps) {
  const cookies = new Cookies()
  const [isInitialized, setIsInitialized] = useState(false)
  const [isUpdating, setIsUpdating] = useState(true)
  const { langAbbrev } = useContext(LanguageContext)
  const [customUserData] = useCustomUserData()
  const { user } = useContext(UserContext)
  const customDataFunctions = useRef<any>({})
  const experimentMap = useRef<any>({})
  const [gatesEnabled, setGatesEnabled] = useState<string[]>([])

  const getStatsigUserId = () => {
    let userId = cookies.get(STATSIG_COOKIE_NAME)
    if (!userId) {
      userId = uuidV4()
      // Expires is set to two years
      const expireDate = new Date()
      expireDate.setMonth(expireDate.getMonth() + 24)
      cookies.set(STATSIG_COOKIE_NAME, userId, {
        path: '/',
        expires: expireDate
      })
    }
    return userId
  }

  const getStatsigConfig = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const gateOverride = urlParams?.get('gate') as string
    const layerOverride = (urlParams?.get('layer') as string) || GATE_LAYERS.ARTICLE_BODY

    const evaluatedCustomFunctions = {}
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in customDataFunctions.current) {
      // eslint-disable-next-line no-await-in-loop
      const value = await customDataFunctions.current[key]()
      if (!value) return
      evaluatedCustomFunctions[key] = value
    }

    // Store enabled gates without layer
    const enabledGatesWithoutLayer: string[] = []

    // Group gates by their layers so we can select 1 random gate per layer later.
    const groupedByLayer = Object.keys(evaluatedCustomFunctions).reduce(
      (acc, key) => {
        const value = evaluatedCustomFunctions[key]
        if (!value.gate) return acc

        const layer = value.layer || GATE_LAYERS.NO_LAYER
        const enabledGates = acc[layer] || []

        // Map experiment to gate for easy experiment gate lookup
        if (value.experiment) {
          experimentMap.current[value.experiment] = value.gate
        }

        // Check if gate is enabled in statsig
        const isGateEnabled = statsig.checkGate(value.gate)

        const isEnabledInArticle = isFunction(value.isEnabledInArticle)
          ? value.isEnabledInArticle()
          : value.isEnabledInArticle
        if (!isEnabledInArticle || !isGateEnabled) return acc

        // Add gate to enabled gates without layer
        if (layer === GATE_LAYERS.NO_LAYER) {
          enabledGatesWithoutLayer.push(value.gate)
          return acc
        }

        // Add gate to enabled gates for that layer and avoid duplicates
        if (!enabledGates.includes(value.gate)) enabledGates.push(value.gate)

        return {
          ...acc,
          [layer]: enabledGates
        }
      },
      {}
    )

    // Get data from local storage
    const storageData = localStorage.getItem(STATSIG_CONFIG_STORAGE_KEY)
    let savedLayerData = {}
    if (storageData) {
      try {
        const decodedString = Buffer.from(storageData, 'base64').toString()
        savedLayerData = JSON.parse(decodeURIComponent(decodedString))
      } catch (e) {
        console.error(e)
      }
    }

    const layerData = Object.keys(groupedByLayer).reduce(
      (prev, layerKey) => {
        const value = groupedByLayer[layerKey]

        // Select 1 random gate for each layer
        let gateEnabled = sample(value)

        // Check if saved data exist for article and is still valid
        const localValue = savedLayerData[layerKey]
        if (localValue && value.includes(localValue)) gateEnabled = localValue

        // Allow gate override from url parameters
        if (gateOverride && layerKey === layerOverride) {
          gateEnabled = gateOverride
        }

        return {
          ...prev,
          [layerKey]: gateEnabled
        }
      },
      { ...savedLayerData }
    )

    // Save to local storage always
    const base64String = btoa(encodeURIComponent(JSON.stringify(layerData)))
    localStorage.setItem(STATSIG_CONFIG_STORAGE_KEY, base64String)

    // Save array of active gates for current session
    // This also includes all enabled gates without layers
    const newConfiguration = [
      ...(Object.values(layerData) as string[]),
      ...enabledGatesWithoutLayer
    ]

    setGatesEnabled(newConfiguration)
    setIsInitialized(true)
    setIsUpdating(false)
  }

  const getStatsigUser = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return {
      userID: getStatsigUserId(),
      privateAttributes: {
        userId: user.is_logged_in ? user?.id : undefined,
        email: user.is_logged_in ? user?.email : undefined
      },
      custom: {
        variant: urlParams?.get('variant') as string,
        locale: langAbbrev,
        pathname: window.location.pathname,
        ...customUserData
      }
    }
  }

  const getStatsigEnvironmentTier = () => {
    // eslint-disable-next-line no-nested-ternary
    const tier = window.location.hostname.includes('.dev.understood.org')
      || window.location.hostname.includes('localhost')
      ? 'development'
      : window.location.hostname.includes('qa.understood.org')
        ? 'staging'
        : 'production'

    return tier
  }

  const logEvent = (eventName, value, metadata) => {
    if (!isInitialized) {
      console.error(
        `STATSIG is not initialized yet.
          Please, check the "isInitialized" flag before calling the "logEvent" function`
      )
      return
    }
    statsig.logEvent(eventName, value, metadata)
  }

  const getExperiment = (experimentName): any | undefined => {
    if (!isInitialized) return
    const mappedGate = experimentMap.current[experimentName]
    // eslint-disable-next-line consistent-return
    if (mappedGate && gatesEnabled.includes(mappedGate)) {
      const exp = statsig.getExperiment(experimentName)
      // eslint-disable-next-line consistent-return
      return exp
    }
  }

  const getStatsigExperiment = (experimentName: string, isSSR?: boolean): any | undefined => {
    if (!isInitialized) return
    if (isSSR && !statsigConfig?.experimentsConfig?.[experimentName]) return
    // eslint-disable-next-line consistent-return
    return statsig.getExperiment(experimentName)
  }

  const checkGate = (configName): boolean | undefined => {
    if (!isInitialized) return
    // eslint-disable-next-line consistent-return
    if (gatesEnabled.includes(configName)) return statsig.checkGate(configName)
  }

  const checkStatsigGate = (configName: string, isSSR?: boolean): boolean | undefined => {
    if (!isInitialized) return
    if (isSSR && !statsigConfig?.gatesConfig?.[configName]) return
    // eslint-disable-next-line consistent-return
    return statsig.checkGate(configName)
  }

  const registerCustomData = (key, fnc: () => Promise<RegisterFuncReturn>) => {
    customDataFunctions.current = { ...customDataFunctions.current, [key]: fnc }
  }

  useEffect(() => {
    const tier = getStatsigEnvironmentTier()
    const statsigUser = getStatsigUser()
    const controller = new AbortController()
    const { signal } = controller

    statsig
      .initialize(
        'client-1cn7bY6gHWJxbjfIon1d0y0bT1MQApuwIHHZGRR9jrK',
        { ...statsigUser },
        { environment: { tier } }
      )
      .then(() => {
        setTimeoutWithCancel(15000, signal, () => {
          statsig.logEvent('hit_15_seconds_mark', 'true')
        })
        onInit?.()
        getStatsigConfig()
      })

    return () => {
      statsig.shutdown()
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (!isInitialized) return

    setIsUpdating(true)
    const statsigUser = getStatsigUser()
    statsig.updateUser(statsigUser)
      .then(() => setIsUpdating(false))
  }, [user])

  return (
    <CustomStatsigProviderComponent
      value={{
        isInitialized,
        isUpdating,
        getExperiment,
        getStatsigExperiment,
        checkGate,
        checkStatsigGate,
        logEvent,
        registerCustomData,
        experiments: statsigConfig?.experimentsConfig || {},
        gates: statsigConfig?.gatesConfig || {}
      }}
    >
      {children}
    </CustomStatsigProviderComponent>
  )
}

export default CustomStatsigProvider
