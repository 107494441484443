// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulMark } from './types'

/** Italics component would map to contentful italics */
function Italics ({ children }: IContentfulMark) {
  return (
    <i data-testid='italics' className=' text-foreground-darkblue'>
      {children}
    </i>
  )
}

Italics.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Italics
