import { Languages } from "@context/LanguageContext/types"


export const localeSubPathMap = new Map()

localeSubPathMap.set('en', Languages.EN)
localeSubPathMap.set('en-US', Languages.EN)
localeSubPathMap.set('es-mx', Languages.ES)
localeSubPathMap.set('es-MX', Languages.ES)

export const fallbackLanguage = 'en'
