import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGunderstood from '@fractal/primitives/SVGs/logos/understood'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useLinkedContentUrlBuilder } from '../../utils'
import FollowUs from './FollowUs'
import Legal from './Legal'
import Nav from './Nav'
import { IFooter } from './types'

/** Footer component description */
const Footer = ({
  socialLinks,
  footerLinks,
  titleText,
  legalCopy,
  address
}: IFooter) => {
  const { trackNavigation } = useContext(AnalyticsContext)
  const { buildLinkedContentUrl } = useLinkedContentUrlBuilder()

  return (
    <footer className='bg-background-page-white pt-sm-6 pt-lg-7 text-small'>
      <section className='container-md'>
        <div className='row flex-sm-justify-center'>
          <div className='col-lg-9'>
            <Link
              data-testid='footer-understood-home-link'
              className='text-foreground-darkblue mb-sm-5 d-sm-block mx-auto text-center'
              href='/'
              onClick={() => {
                trackNavigation({
                  eventType: 'click',
                  isContent: true,
                  buttonName: 'understood_home',
                  linkedContentId: '5MkNYlzCVsC7fPXTX1WL9T', // homepage
                  linkedContentUrl: buildLinkedContentUrl({}),
                  unitName: 'footer',
                  unitLocation: 'footer'
                })
              }}
              aria-label='Understood Home'
            >
              <SVGunderstood
                className='d-md-none vicon'
                width='218'
                height='54'
              />
              <SVGunderstood
                className='d-sm-none d-md-inline-block vicon'
                width='254'
                height='61'
              />
            </Link>
            <Nav links={footerLinks} />
            <FollowUs links={socialLinks} titleText={titleText} />
          </div>
        </div>
      </section>
      <Legal legalCopy={legalCopy} address={address} />
    </footer>
  )
}

Footer.propTypes = {
  /** Renders list of {label, url} objects */
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  /** Renders list of {label, text, url} objects */
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  titleText: PropTypes.string
}

Footer.displayName = 'Footer'

export default Footer
