import React from 'react'
import ContentfulContext from './ContentfulContext'

const ContentfulProvider: React.FC<any> = ({ contentfulData, children }) => {
  const getPropsByType = (type: string, index: number = 0) => {
    const component = contentfulData.filter(
      (component) => component.type === type
    )[index]

    return component?.contents || {}
  }

  return (
    <ContentfulContext.Provider value={{ getPropsByType }}>
      {children}
    </ContentfulContext.Provider>
  )
}

export default ContentfulProvider
