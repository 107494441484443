import { UseInViewContext } from '@context/UseInViewContext'
import SVGwave from '@fractal/primitives/SVGs/waves/wave'
import { RichText } from '@fractal/primitives/atoms/RichText'
import React, { useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const Legal = ({ legalCopy, address }) => {
  const [ref, inView] = useInView({ threshold: 0 })
  const { updateBottomCTA } = useContext(UseInViewContext)

  useEffect(() => {
    updateBottomCTA(inView)
  }, [inView])

  return (
    <div
      ref={ref}
      className='bg-background-high-cream text-foreground-darkblue pb-sm-7 mt-sm-7 f-sm-1'
    >
      <SVGwave className='fill-background-page-white mb-sm-7 mx-sm-0 w-sm-full' />
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 offset-lg-1 col-lg-10'>
            {legalCopy && <RichText richTextContent={legalCopy} />}
            <address>
              {address && <RichText richTextContent={address} />}
            </address>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Legal
