import { Link } from '@/journey/Link'
import * as PropTypes from 'prop-types'
import React from 'react'
import { IFooterLink } from './types'

const NavLink = ({
  href,
  url,
  label,
  text,
  index,
  handleClick
}: IFooterLink) => {
  return (
    <li
      value={index}
      className='mb-sm-4 d-sm-inline-block pr-sm-2 pr-md-1 pr-lg-4 pl-sm-4 pl-md-1 pl-lg-2'
    >
      <Link
        data-testid={`footer-${label}-link`}
        href={href}
        onClick={handleClick as any}
        className='secondary-link p-md-3 p-lg-0'
      >
        {text}
      </Link>
    </li>
  )
}

NavLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default NavLink
