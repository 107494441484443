import enCopies from './en.json'
import esCopies from './es.json'

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_NAMESPACE = 'translation'
export const SUPPORTED_LANGUAGES = [DEFAULT_LANGUAGE, 'es']

export function getiI18nextOptions(lng = DEFAULT_LANGUAGE) {
  return {
    debug: false,
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: DEFAULT_LANGUAGE,
    lng,
    ns: DEFAULT_NAMESPACE,
    resources: {
      en: {
        translation: enCopies
      },
      es: {
        translation: esCopies
      }
    }
  }
}
