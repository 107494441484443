import React from 'react'
import {
  IAccountEvent,
  IAnalyticsContext,
  IEngagementEvent,
  INavigationEvent,
  ISnowplowClickEvent,
  ISnowplowEngagementEvent,
  ISnowplowImpressionEvent
} from '.'
import { IABEvent } from './Analytics.old.interface'

const DefaultAnalyticsContext: IAnalyticsContext = {
  trackNavigation: (context: INavigationEvent) => { },
  trackEngagement: (context: IEngagementEvent) => { },
  trackAccount: (context: IAccountEvent) => { },
  trackABExperiment: (context: IABEvent) => { },
  triggerPageView: () => { },
  trackClick: (context: ISnowplowClickEvent) => { },
  trackImpression: (context: ISnowplowImpressionEvent) => { },
  trackSpEngagement: (context: ISnowplowEngagementEvent) => { }
}

const AnalyticsContext = React.createContext(DefaultAnalyticsContext)

const { Provider, Consumer } = AnalyticsContext

export {
  Provider as AnalyticsProvider,
  Consumer as AnalyticsConsumer,
  DefaultAnalyticsContext
}

export default AnalyticsContext
