// @ts-nocheck
import React from 'react'
import { IQa } from './types'
export const QAblock = ({ question, answer }: IQa) => {
  return (
    <div className='text-foreground-darkblue bg-background-page-white mt-sm-7'>
      <div className='row mb-sm-6'>
        <div
          className='col-sm-2 offset-md-1 col-md-2 offset-lg-2 col-lg-2'
          aria-hidden='true'
        >
          <span className='bg-background-high-darkblue text-static-white circle-sm w-sm-6 h-sm-6 d-sm-flex flex-sm-align-center flex-sm-justify-center f-sm-7 text-bold'>
            Q
          </span>
        </div>
        <div className='col-sm-10 col-md-9 col-lg-6 d-sm-flex flex-sm-align-center my-sm-n4 my-md-n5'>
          <h2>
            <span className='sr-only'>Question: </span>
            <span className='f-sm-5 f-md-6'>{question}</span>
          </h2>
        </div>
      </div>
      <div className='row'>
        <div
          className='col-sm-2 offset-md-1 col-md-2 offset-lg-2 col-lg-2'
          aria-hidden='true'
        >
          <span className='bg-background-high-darkblue text-static-white circle-sm w-sm-6 h-sm-6 d-sm-flex flex-sm-align-center flex-sm-justify-center f-sm-7 text-bold'>
            A
          </span>
        </div>
        <div className='col-sm-10 col-md-9 col-lg-6 qaBlock-answer'>
          <h3 className='sr-only'>Answer: </h3>
          {answer}
        </div>
      </div>
    </div>
  )
}

export default QAblock
