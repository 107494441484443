import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'
import AppContextProvider from '@common/components/UseAppContext'
import AuthContextProvider from '@common/components/UseAuthContext'
import CustomizationContextProvider from '@common/components/UseCustomizationContext'
import LanguageContextProvider from '@common/components/UseLanguageContext'
import PreferencesContextProvider from '@common/components/UsePreferencesContext'
import { transformPage } from '@common/utils/contentful-graphql'
import { AnalyticsContextProvider, ContentfulProvider, CustomStatsigProvider } from 'components/shared/providers'
import ThemeContextProvider from 'components/shared/providers/ThemeProvider/ThemeContextProvider'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import React from 'react'
import { useApollo } from 'utils/apolloClient'
import { Layout } from '../Layout'

const withPageContexts = (PageContentsComponent) => function PageContextProvider({
  ...props
}: any) {
  const lang = props.locale === 'en' ? 'en-US' : 'es-MX'
  const apolloClient = useApollo(props)

  const page = props.initialState?.page && transformPage(props.initialState?.page)
  let template = props.sharedPageData?.genericContent || []

  template = template
    .map((component) => (component.type === 'ChildPage' ? page?.template : component))
    .filter((component) => !!component)
    .flat()

  const enableLocaleSwitcher = typeof props.enableLocaleSwitcher !== 'undefined'
    ? props.enableLocaleSwitcher : props.initialState?.page?.isLocalizable

  const pageTitle = props.pageTitle || page?.title || page?.seo?.title || 'Understood'

  const siteSection = props.siteSection || props.initialState?.page?.siteSection
    || 'homepage'

  return (
    <ApolloProvider client={apolloClient}>
      <LanguageContextProvider
        lang={lang}
        enableLocaleSwitcher={enableLocaleSwitcher}
        slugEn={props.slugOverride || props.slug}
        slugEs={props.slugEsOverride || props.slugOverride || props.slug}
      >
        <AppContextProvider
          deviceOS=''
          deviceType=''
          program='agnostic'
          pageTitle={pageTitle}
          contentId={props.contentId || props.initialState?.page?.id || null}
          siteSection={siteSection}
          basePath=''
        >
          <ThemeContextProvider>
            <CustomizationContextProvider>
              <AnalyticsContextProvider
                snowplowUrl={process.env.NEXT_PUBLIC_SNOWPLOW_URL}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(props?.pageTrackingData || {})}
              >
                <AuthContextProvider>
                  <PreferencesContextProvider
                    preselected={props.preselectedTags}
                    preselectedInterests={props.preselectedInterests}
                  >
                    <ContentfulProvider contentfulData={template}>
                      <CustomStatsigProvider
                        statsigConfig={props.statsigConfig}
                        onInit={props.onStatsigInitialize}
                      >
                        <NextSeo
                          title={page?.title || page?.seo?.title || ''}
                          description={page?.seo?.description || ''}
                          openGraph={page?.seo?.openGraph || {}}
                        />
                        <Head>
                          <title>
                            {pageTitle}
                          </title>
                        </Head>
                        <div
                          data-testid={
                            page?.headerText || page?.title || page?.seo?.title
                          }
                        >
                          <Layout
                            isDynamic={props.pageProps?.isDynamic}
                            layoutConfig={props.layoutConfig}
                            layoutComponentProps={props.sharedPageData?.dynamicComponents || []}
                          >
                            <PageContentsComponent
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...props.pageProps}
                              components={template || []}
                            />
                          </Layout>
                        </div>
                      </CustomStatsigProvider>
                    </ContentfulProvider>
                  </PreferencesContextProvider>
                </AuthContextProvider>
              </AnalyticsContextProvider>
            </CustomizationContextProvider>
          </ThemeContextProvider>
        </AppContextProvider>
      </LanguageContextProvider>
    </ApolloProvider>
  )
}

export default withPageContexts
