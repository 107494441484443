import SVGarrowRight from '@fractal/primitives/SVGs/icons/arrowRight'
import React from 'react'
import { ITinyTout } from './types'

export const TinyTout = ({ internalTitle, toutImage, url }: ITinyTout) => {
  return (
    <div className='bg-background-high-grey2 p-sm-4 rounded-sm-3 d-md-flex'>
      <a
        href={url}
        className='flex-sm-align-center d-sm-flex tertiary-link'
        target='_blank'
      >
        <div className='m-sm-3 d-sm-flex'>
          <img
            src={toutImage}
            width='110px'
            height='120px'
            className='rounded-sm-4'
            alt=''
          />
        </div>
        <p className='text-bold f-sm-3 f-md-4 ml-sm-2'>
          {internalTitle}{' '}
          <SVGarrowRight className='vicon vertical-align-sm-middle ml-sm-2 text-static-scarlet' />
        </p>
        <span className='sr-only'>Opens in a new tab</span>
      </a>
    </div>
  )
}

export default TinyTout
