import SVGfacebook from '@fractal/primitives/SVGs/socials/facebook'
import SVGinstagram from '@fractal/primitives/SVGs/socials/instagram'
import SVGlinkedin from '@fractal/primitives/SVGs/socials/linkedin'
import SVGmedium from '@fractal/primitives/SVGs/socials/medium'
import SVGpinterest from '@fractal/primitives/SVGs/socials/pinterest'
import SVGshare from '@fractal/primitives/SVGs/socials/share'
import SVGtiktok from '@fractal/primitives/SVGs/socials/tiktok'
import SVGyoutube from '@fractal/primitives/SVGs/socials/youtube'
import * as PropTypes from 'prop-types'
import React from 'react'

interface ISocialLink {
  url: string
  label: string
  handleClick: () => void
}

const SocialLink = ({ url, label, handleClick }: ISocialLink) => {
  return (
    <li className='d-sm-inline-block mr-sm-2'>
      <a
        data-testid='social-link'
        href={url}
        onClick={handleClick}
        className={`d-sm-flex circle-sm bg-static-darkblue text-static-white w-sm-6 h-sm-6 flex-sm-justify-center flex-sm-align-center hover-bg-social-${label}`}
        target='_blank'
        rel='noopener noreferrer'
        aria-label={`Follow us via ${label}`}
      >
        {label === 'facebook' ? (
          <SVGfacebook className='vicon' />
        ) : label === 'instagram' ? (
          <SVGinstagram className='vicon' />
        ) : label === 'tiktok' ? (
          <SVGtiktok className='vicon' />
        ) : label === 'pinterest' ? (
          <SVGpinterest className='vicon' />
        ) : label === 'linkedin' ? (
          <SVGlinkedin className='vicon' />
        ) : label === 'medium' ? (
          <SVGmedium className='vicon' />
        ) : label === 'youtube' ? (
          <SVGyoutube className='vicon' />
        ) : (
          <SVGshare className='vicon' />
        )}
        <span className='sr-only'>Opens in a new tab</span>
      </a>
    </li>
  )
}

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default SocialLink
