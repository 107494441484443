import { head, last, tail, toUpper } from 'ramda'

export const pathifySlug = slug => {
  return (slug && Array.isArray(slug) && `/${slug.join('/') || '/'}`) || '/'
}

export const toCapitalCase = slug => {
  const string = (Array.isArray(slug) && last(slug)) || ''
  const upperFirst = toUpper(head(string))
  const theRest = tail(string)
  return `${upperFirst}${theRest}`
}

export const transformPage = page => {
  const {
    id,
    name,
    title,
    headerText,
    slug,
    template,
    seo,
    isLocalizable
  } = page
  return {
    id,
    name,
    title,
    headerText,
    slug,
    template: transformTemplate(template) || [],
    seo,
    isLocalizable,
    campaign: {
      seo: page?.campaign?.seo || {},
      layoutPage: {
        template: transformTemplate(page?.campaign?.layoutPage?.template) || []
      }
    }
  }
}

function transformTemplate(template) {
  return (
    (template &&
      template.components &&
      template.components.map(c => {
        return {
          name: c.name,
          type: c.type,
          contents: c.contents || null,
          richTextContent: c.richTextContent || null,
          mixins: c.mixins || [],
          children:
            c.children?.map(child => {
              return {
                name: child?.name,
                type: child?.type,
                contents: child?.contents || null,
                richTextContent: child?.richTextContent || null,
                mixins: child?.mixins || []
              }
            }) || []
        }
      })) ||
    null
  )
}
