import { LanguageContext } from '@context/LanguageContext'
import { useContext } from 'react'

const useLinkedContentUrlBuilder = () => {
  const { language } = useContext(LanguageContext)

  const buildLinkedContentUrl = ({
    url = '',
    type = ''
  }: {
    url?: string
    type?: string
  }): string => {
    const languageOnlyTwoChar = language?.split('-')[0] || 'en'
    const pathName = `/${url}/${languageOnlyTwoChar}/${type}/`
      .replace(/\/\//g, '/')
      .replace(/\/$/, '')
    // The first "replace" will convert two slashes into one (in the event url and/or type is empty)
    // The second "replace" will remove the trailing slash (in the event type is not empty)

    return process.env.NEXT_PUBLIC_APP_DOMAIN + pathName
  }

  return {
    buildLinkedContentUrl
  }
}

export { useLinkedContentUrlBuilder }
