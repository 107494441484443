import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGpinterest = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.906 34.533a8.668 8.668 0 0 0 7.373 3.597c9.532 0 16.546-8.812 16.546-19.783C46.825 7.736 38.193.003 27.042.003 13.194-.177 6 8.995 6 19.066c0 4.676 2.518 10.431 6.475 12.41.719.36.899.18 1.079-.36.18-.54.719-2.698.899-3.597a1.35 1.35 0 0 0-.18-.9 11.654 11.654 0 0 1-2.338-7.373A13.867 13.867 0 0 1 26.323 5.398c7.913 0 13.308 5.395 13.308 12.949 0 8.633-4.316 14.568-10.071 14.568a4.676 4.676 0 0 1-4.676-5.755c.9-3.777 2.698-7.914 2.698-10.611a4.064 4.064 0 0 0-4.137-4.497c-3.237 0-5.755 3.417-5.755 7.734a11.313 11.313 0 0 0 .9 4.676s-3.238 13.308-3.777 15.826a29.082 29.082 0 0 0-.18 8.813c.719.36 1.618.54 2.338.899a37.19 37.19 0 0 0 3.776-8.093c.54-1.439 2.159-7.374 2.159-7.374z'
      />
    </svg>
  )
}

export default SVGpinterest
