import { localeSubPathMap } from '@common/utils/language'
import { LanguageProvider, Languages } from '@context/LanguageContext'
import React, { useEffect, useState } from 'react'

const UseLanguageContext = ({ lang, enableLocaleSwitcher, slugEn, slugEs, children }) => {
  const [language, setLanguage] = useState(localeSubPathMap.get(lang))
  const [langAbbrev, setLangAbbrev] = useState(
    localeSubPathMap.get(lang).split('-')[0]
  )

  const changeLanguage = str => {
    setLanguage(str)
  }

  useEffect(() => {
    setLanguage(language)
    setLangAbbrev(language.split('-')[0])
  }, [language])

  return (
    <LanguageProvider
      value={{
        language,
        changeLanguage,
        enableLocaleSwitcher,
        slug: language === Languages.EN ? slugEn : slugEs,
        slugEn,
        slugEs,
        langAbbrev
      }}
    >
      {children}
    </LanguageProvider>
  )
}

export default UseLanguageContext
