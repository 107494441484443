import dynamic from 'next/dynamic'
import React from 'react'
import { IContentfulAssetProps } from './ContentfulAsset.interface'

const AnimatedSVG = dynamic({
  loader: () => import('./AnimatedSVG'),
  ssr: false
})


const ContentfulAsset = ({
  url,
  animationConfig,
  ...props
}: IContentfulAssetProps) => {
  if (!url) return null

  const format =
    typeof url === 'string'
      ? url
        .trim()
        .split('.')
        .pop()
      : 'json'

  if (format === 'json') {
    // Since we cannot access the svg, we add those to the div in case of an alt
    if (props['alt']) {
      props['role'] = 'img'
      props['aria-label'] = props['alt']
      delete props.alt
    }
    return (
      <AnimatedSVG url={url} animationConfig={animationConfig} {...props} />
    )
  }

  if (format?.match(/^(jpg|jpeg|png|gif|svg)$/)) {
    return <img {...(props as any)} src={url as string} />
  }

  return (
    <div
      {...(props as any)}
      type={undefined}
      as='object'
      data={url as string}
      className={`d-sm-inline-block ${props.className}`}
    />
  )
}

export default ContentfulAsset
