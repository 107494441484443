import { AnalyticsContext } from '@context/AnalyticsContext'
import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import { UserContext } from '@context/UserContext'
import SVGprofile from '@fractal/primitives/SVGs/icons/profile'
import SVGprofileFilled from '@fractal/primitives/SVGs/icons/profileFilled'
import React, { useContext, useEffect } from 'react'
import { Dropdown } from './Dropdown'
import HeaderContext from './HeaderContext'
import Mask from './Mask'
import { StyledLoginButton } from './styled'

const UserLogin = ({ transparentBackground, dropdownTitle, dropdownUrl }) => {
  const { user, updateAccountModal } = useContext(UserContext)
  const { overlay, showOverlay } = useContext(HeaderContext)
  const { trackNavigation } = useContext(AnalyticsContext)
  const { language } = useContext(LanguageContext)
  const localePath = LanguagePathMap.get(language) || 'en'

  useEffect(() => {
    if (!user?.is_logged_in && overlay) {
      showOverlay(false)
    }
  }, [user?.is_logged_in, overlay])

  return (
    <div className='p-sm-relative no-print' data-testid='user-signup-login'>
      {user?.is_logged_in ? (
        <button
          aria-label='User menu'
          className={
            transparentBackground
              ? 'Btn-link d-sm-flex target text-static-darkblue flex-sm-align-center flex-sm-justify-center icon-hover'
              : 'Btn-link d-sm-flex target text-static-white flex-sm-align-center flex-sm-justify-center icon-hover'
          }
          onClick={() => showOverlay(!overlay)}
        >
          {user?.picture ? (
            <img
              alt='User picture'
              src={user?.picture}
              width={32}
              height={32}
              className='circle-sm'
            />
          ) : (
            <SVGprofileFilled
              className={`${transparentBackground
                ? 'vicon text-foreground-darkblue'
                : 'vicon'
                }`}
            />
          )}
        </button>
      ) : (
        <StyledLoginButton
          role='button'
          aria-label='Account'
          onClick={() => {
            updateAccountModal?.(true)
            trackNavigation({
              eventType: 'click',
              buttonName: 'login',
              unitName: 'main_menu',
              unitLocation: 'header'
            })
          }}
        >
          <SVGprofile
            className={`${transparentBackground ? 'vicon text-foreground-darkblue' : 'vicon'
              }`}
          />
        </StyledLoginButton>
      )}
      {overlay && (
        <Dropdown
          dropdownUrl={dropdownUrl || `${process.env.NEXT_PUBLIC_APP_DOMAIN}/hub/${localePath}`}
          dropdownTitle={dropdownTitle || 'My Library'}
        />
      )}
      {overlay && (
        <Mask transparent handleClick={() => showOverlay(!overlay)} />
      )}
    </div>
  )
}

export { UserLogin }
export default UserLogin
