import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGyoutube = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.464 15a12.09 12.09 0 0 0-1.964-5 7.91 7.91 0 0 0-5-2.143C35.536 7.5 25 7.5 25 7.5s-10.536 0-17.5.536A6.232 6.232 0 0 0 2.5 10a11.286 11.286 0 0 0-1.964 5A71.767 71.767 0 0 0 0 23.036v3.75c.019 2.686.198 5.37.536 8.035a12.089 12.089 0 0 0 1.964 5 8.715 8.715 0 0 0 5.536 2.143C11.964 42.321 25 42.5 25 42.5s10.536 0 17.5-.536a7.91 7.91 0 0 0 5-2.143 11.285 11.285 0 0 0 1.964-5c.33-2.666.508-5.349.536-8.035v-3.75A71.771 71.771 0 0 0 49.464 15zM19.821 31.429V17.322l13.572 6.964-13.572 7.143z'
      />
    </svg>
  )
}

export default SVGyoutube
