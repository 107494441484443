'use client'

import HeaderContext from '@fractal/features/Header/HeaderContext'
import SVGsearch from '@fractal/primitives/SVGs/icons/search'
import useTranslation from 'app/i18n/client'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'
import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import styles from './SearchBar.module.scss'

function SearchBar({ sys, searchAlwaysOpened, transparentBackground }: any) {
  const { t } = useTranslation()
  const { searchbarOpen } = useContext(HeaderContext)
  const extraClass = transparentBackground ? '' : 'bg-static-darkblue'
  const searchInput = useRef<HTMLInputElement>(null)
  const [term, setTerm] = useState('')

  useEffect(() => {
    if (searchInput && searchInput.current && !searchAlwaysOpened) {
      searchInput.current.focus()
    }
  })

  return (
    <div
      className={`${searchbarOpen ? 'pt-md-6 pt-sm-5' : 'd-sm-none'} ${extraClass}`}
    >
      <div data-testid='searchbar'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3'>
              <form
                id='searchForm'
                role='search'
                method='GET'
                action='/search-results'
                target='_top'
              >
                <div className='p-sm-relative'>
                  <input
                    type='search'
                    name='query'
                    placeholder={t('search-placeholder')}
                    // eslint-disable-next-line max-len
                    className='f-sm-3 text-static-darkblue form-control form-control--tall form-control--noborder rounded-sm-5 border-shadow pr-sm-7 pl-sm-5'
                    aria-label='Type search terms'
                    required
                    ref={searchInput}
                    data-testid='search-input'
                    onChange={(e) => {
                      setTerm(e.target?.value)
                    }}
                  />
                  <TrackClick
                    as='button'
                    aria-label='Clear search'
                    className={`Btn-link p-sm-absolute py-sm-4 px-sm-5 ${styles.searchButton}`}
                    isEngagementEvent
                    trackProps={{
                      eventType: 'search' as any,
                      unitName: 'main_menu',
                      unitLocation: 'header',
                      submittedText: term,
                      componentId: sys.id
                    }}
                  >
                    <SVGsearch className='fill-static-darkblue mr-sm-0' />
                  </TrackClick>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBar
