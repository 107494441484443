import { AnalyticsContext } from '@context/AnalyticsContext'
import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import SVGsearch from '@fractal/primitives/SVGs/icons/search'
import React, { useContext, useEffect, useRef, useState } from 'react'
import HeaderContext from './HeaderContext'

const SearchBar = ({
  transparentBackground,
  searchAlwaysOpened,
  searchPlaceholder = 'Example: I have trouble focusing...'
}) => {
  const { searchbarOpen } = useContext(HeaderContext)
  const extraClass = transparentBackground ? '' : 'bg-static-darkblue'
  const searchInput = useRef<HTMLInputElement>(null)
  const [term, setTerm] = useState('')
  const { language } = useContext(LanguageContext)
  const localePath = LanguagePathMap.get(language) || 'en'
  const { trackEngagement } = useContext(AnalyticsContext)

  useEffect(() => {
    if (searchInput && searchInput.current && !searchAlwaysOpened) {
      searchInput.current.focus()
    }
  })

  const handleChange = e => {
    setTerm(e.target?.value)
  }

  return (
    <div
      className={`${searchbarOpen ? 'pt-md-6 pt-sm-5' : 'd-sm-none'
        } ${extraClass}`}
    >
      <div data-testid='searchbar'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3'>
              <form
                id='searchForm'
                role='search'
                method='GET'
                action={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/${localePath}/search-results`}
                target='_top'
                onClick={e => {
                  trackEngagement({
                    eventType: 'search' as any,
                    buttonName: 'search_submit',
                    unitName: 'main_menu',
                    unitLocation: 'header',
                    submittedText: term
                  })
                }}
              >
                <div className='p-sm-relative'>
                  <input
                    type='search'
                    name='query'
                    placeholder={searchPlaceholder}
                    className='f-sm-3 text-static-darkblue form-control form-control--tall form-control--noborder rounded-sm-5 border-shadow pr-sm-7 pl-sm-5'
                    aria-label='Type search terms'
                    required
                    ref={searchInput}
                    data-testid='search-input'
                    onChange={e => {
                      handleChange(e)
                    }}
                  />
                  <button
                    type='submit'
                    aria-label='Search'
                    className='Btn-link p-sm-absolute py-sm-4 px-sm-5'
                    style={{
                      top: '50%',
                      right: 0,
                      transform: 'translateY(-50%)'
                    }}
                  >
                    <SVGsearch className='fill-static-darkblue mr-sm-0' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SearchBar }
export default SearchBar
