import {
  AnalyticsProvider,
  IABEvent,
  IAccountEvent,
  IEngagementEvent,
  INavigationEvent,
  ISnowplowClickEvent,
  ISnowplowContentContext,
  ISnowplowEngagementEvent,
  ISnowplowImpressionEvent,
  ISnowplowPageContext,
  ISnowplowSiteContext
} from '@context/AnalyticsContext'
import { AppContext } from '@context/AppContext'
import { DefaultCustomizationContext, ICustomizationContext } from '@context/CustomizationContext'
import { LanguageContext } from '@context/LanguageContext'
import { useLocalStorage } from '@context/hooks/useStorage'
import { TimezonePlugin } from '@snowplow/browser-plugin-timezone'
import {
  enableActivityTracking,
  newTracker,
  trackPageView,
  trackSelfDescribingEvent
} from '@snowplow/browser-tracker'
import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import useDarkMode from 'use-dark-mode'
import lowerCaseAndUnderscore from 'utils/lowerCaseAndUnderscore'
import { IAnalyticsContextProvider } from './AnalyticsContextProvider.types'

const DEFAULT_TRESHOLDS = [0.25, 0.5, 0.75, 1]

const getColorProfile = (): string[] => (
  [
    window.matchMedia('(prefers-color-scheme: dark)')?.matches
      ? 'dark'
      : 'light',
    window.matchMedia('(inverted-colors: inverted)')?.matches && 'inverted',
    window.matchMedia('(forced-colors)')?.matches && 'forced',
    window.matchMedia('(prefers-contrast: more)')?.matches && 'contrast'
  ].filter((v) => v)
)

const getZoomLevel = (): number => {
  function isHighDensity() {
    return ((window.matchMedia
      // eslint-disable-next-line max-len
      && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches
        // eslint-disable-next-line max-len
        || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)
    ) || (window.devicePixelRatio && window.devicePixelRatio > 1.3))
  }
  const zoomLevel = Math.round(window.devicePixelRatio * 100) || 100

  // Check hdpi support
  if (isHighDensity()) return zoomLevel / 2

  return zoomLevel
}

function AnalyticsContextProvider({
  children,
  scrollDepthThresholds,
  disableScrollEvents,
  disablePageView,
  snowplowUrl,
  contentTypeId,
  ...pageEventData
}: IAnalyticsContextProvider) {
  const thresholds = useRef(scrollDepthThresholds || DEFAULT_TRESHOLDS)
  const { contentId } = useContext(AppContext)
  const { langAbbrev: language } = useContext(LanguageContext)
  const isBrowser = typeof window === 'object'
  const darkMode = useDarkMode()
  const [customization] = useLocalStorage<ICustomizationContext>('customization', DefaultCustomizationContext)
  const [isPageViewFired, setIsPageViewFired] = useState(false)

  const isSnakeCase = (val: string) => /^[a-z0-9]+(?:_[a-z0-9]+)*$/.test(val)

  const trimPayload = (data: Record<string, any>): Record<string, any> => Object
    .entries(data).reduce((prev, [k, v]) => {
      if (v === undefined || v === null) {
        return prev
      }
      if ((k === 'button_name') && !isSnakeCase(v)) {
        // eslint-disable-next-line no-console
        console.error(
          `Please revise your tracking values. ${k} has invalid pattern: ${v}. See AnalyticsContextProvider.`
        )
      }
      return {
        ...prev,
        [k]: v
      }
    }, {})

  const getSharedContext = (
    event: Partial<ISnowplowSiteContext
    & ISnowplowContentContext
    & ISnowplowPageContext>
  ): any[] => [
    {
      schema: 'iglu:org.understood/site/jsonschema/1-0-8',
      data: trimPayload({
        unit_name: event.unitName,
        unit_location: event.unitLocation,
        unit_variation: event.unitVariation,
        event_horizontal_position: event.eventHorizontalPosition,
        event_vertical_position: event.eventVerticalPosition,
        position_in_unit: event.positionInUnit,
        num_results: event.numResults,
        linked_content_id: event.linkedContentId,
        linked_content_url: event.linkedContentUrl
      })
    },
    {
      schema: 'iglu:org.understood/content/jsonschema/1-0-5',
      data: trimPayload({
        content_id: contentId,
        content_type_id: event.contentTypeId || contentTypeId,
        component_id: event.componentId,
        parent_component_id: event.parentComponentId,
        position_in_parent_component: event.positionInParentComponent,
        child_component_id: event.childComponentId?.toString()
      })
    },
    {
      schema: 'iglu:org.understood/page/jsonschema/1-0-0',
      data: trimPayload({
        contentful_id: pageEventData.contentfulId,
        header_id: pageEventData.headerId,
        page_name: pageEventData.pageName,
        is_spanish: language !== 'en',
        page_slug: pageEventData.pageSlug,
        page_title: pageEventData.pageTitle,
        site_section: pageEventData.siteSection
      })
    },
    {
      schema: 'iglu:org.understood/users/jsonschema/1-0-0',
      data: trimPayload({
        language,
        theme: darkMode.value ? 'dark_mode' : 'light_mode',
        color: getColorProfile(),
        zoom: getZoomLevel(),
        reduced_motion: customization?.isReduceMotionManuallySet
          ? customization.reduceMotion : window.matchMedia('(prefers-reduced-motion: reduce)').matches
      })
    }
  ]

  const trackImpression = (event: ISnowplowImpressionEvent) => {
    if (isBrowser) {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:org.understood/navigation/jsonschema/1-0-12',
          data: trimPayload({
            event_type: 'impression',
            button_name: event.buttonName,
            is_content: event.isContent || false,
            is_external: event.isExternal || false,
            button_text: event.buttonText
              ? lowerCaseAndUnderscore(event.buttonText) : event.buttonText,
            link_text: event.linkText
              ? lowerCaseAndUnderscore(event.linkText) : event.linkText,
            is_modal: event.isModal || false
          })
        },
        context: getSharedContext({
          ...event,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
    }
  }

  const trackClick = (event: ISnowplowClickEvent) => {
    if (isBrowser) {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:org.understood/navigation/jsonschema/1-0-12',
          data: trimPayload({
            event_type: 'click',
            button_name: event.buttonName,
            is_content: event.isContent || false,
            is_external: event.isExternal || false,
            click_type: event.clickType,
            button_text: event.buttonText
              ? lowerCaseAndUnderscore(event.buttonText) : event.buttonText,
            link_text: event.linkText
              ? lowerCaseAndUnderscore(event.linkText) : event.linkText,
            is_modal: event.isModal || false
          })
        },
        context: getSharedContext({
          ...event,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
    }
  }

  const trackSpEngagement = (event: ISnowplowEngagementEvent) => {
    if (isBrowser) {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:org.understood/engagement/jsonschema/1-0-15',
          data: trimPayload({
            event_type: event.eventType,
            platform: event.platform,
            submitted_text: event.submittedText,
            submitted_list: event.submittedList,
            submitted_object: event.submittedObject
              ? JSON.stringify(event.submittedObject)
              : undefined,
            submit_success: event.submitSuccess,
            percent_completed: event.percentCompleted,
            av_timestamp: event.avTimestamp,
            av_speed: event.avSpeed
          })
        },
        context: getSharedContext({
          ...event,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
    }
  }

  const trackNavigation = (event: INavigationEvent) => {
    if (!isBrowser) return
    if (event.eventType === 'click') {
      trackClick({
        ...event,
        componentId: null,
        clickType: event.linkedContentUrl ? 'text' : 'button',
        buttonText: event.linkedContentUrl ? null : event.buttonName,
        linkText: event.linkedContentUrl ? event.buttonName : null,
        buttonName: event.linkedContentUrl ? null : event.buttonName
      })
    }
    if (event.eventType === 'impression') {
      trackImpression({
        ...event,
        componentId: null,
        buttonText: event.linkedContentUrl ? null : event.buttonName,
        linkText: event.linkedContentUrl ? event.buttonName : null,
        buttonName: event.linkedContentUrl ? null : event.buttonName
      })
    }
  }

  const trackEngagement = (event: IEngagementEvent) => {
    if (!isBrowser) return
    trackSpEngagement({
      ...event,
      componentId: null
    })
  }

  const trackAccount = (event: IAccountEvent) => {
    if (isBrowser) {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:org.understood/account/jsonschema/1-0-4',
          data: trimPayload({
            event_type: event.eventType,
            signup_type: event.signupType,
            audience: event.audience,
            email: event.email,
            submit_success: event.submitSuccess,
            first_name: event.firstName,
            childs_age: event.childAge,
            class_grade_level: event.classGradeLevel,
            age: event.age,
            education_level: event.educationLevel,
            property: event.property,
            topic: event.topic,
            screen_name: event.screenName,
            zip_code: event.zipCode
          })
        },
        context: getSharedContext({
          ...event,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
    }
  }

  const trackABExperiment = (event: IABEvent) => {
    if (isBrowser) {
      trackSelfDescribingEvent({
        event: {
          schema: 'iglu:org.understood/ab_testing/jsonschema/1-0-0',
          data: trimPayload({
            event_type: event.eventType,
            experiment_name: event.experimentName,
            variant: event.variant
          })
        },
        context: getSharedContext({
          ...event,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
    }
  }

  const triggerPageView = () => {
    if (disablePageView) {
      return
    }
    if (isBrowser) {
      trackPageView({
        context: getSharedContext({
          unitName: null,
          unitLocation: null,
          eventHorizontalPosition: window?.scrollX,
          eventVerticalPosition: window?.scrollY
        })
      })
      setIsPageViewFired(true)
    }
  }

  useEffect(() => {
    newTracker('co', snowplowUrl, {
      appId: 'web-understood',
      plugins: [TimezonePlugin()]
    })
    enableActivityTracking({ heartbeatDelay: 15, minimumVisitLength: 15 })
    triggerPageView()

    if (disableScrollEvents) {
      return
    }

    const fireEventScroll = () => {
      if (!thresholds.current.length) {
        document.removeEventListener('scroll', fireEventScroll)
        return
      }
      const yScroll = window.scrollY || document.documentElement.scrollTop
      const pageHeight = Math.max(
        document.documentElement.offsetHeight,
        document.documentElement.scrollHeight,
        document.documentElement.clientHeight
      )
      const percentageScrolled = (yScroll + document.documentElement.clientHeight) / pageHeight

      // if any of the values are somehow invalid, we add a console log
      // it'll allow datadog to capture this error.
      if (yScroll > pageHeight || !Number.isFinite(yScroll) || !Number.isFinite(pageHeight)) {
        // eslint-disable-next-line no-console
        console.error(`Scroll of ${yScroll} on pageHeight of ${pageHeight}`)
      } else {
        const thresholdsReached = thresholds.current.filter(
          (threshold) => threshold <= percentageScrolled
        )

        if (thresholdsReached.length) {
          thresholds.current = thresholds.current.filter(
            (threshold) => threshold > percentageScrolled
          )

          thresholdsReached.forEach(() => {
            trackNavigation({
              eventType: 'impression',
              unitName: 'pixel',
              buttonName: null,
              unitLocation: null
            })
          })
        }
      }
    }

    document.addEventListener('scroll', fireEventScroll)

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', fireEventScroll)
      thresholds.current = scrollDepthThresholds || DEFAULT_TRESHOLDS
    }
  }, [contentId])

  return (
    <AnalyticsProvider
      // @ts-ignore
      value={{
        isPageViewFired,
        trackNavigation,
        trackEngagement,
        trackAccount,
        trackABExperiment,
        triggerPageView,
        trackImpression,
        trackClick,
        trackSpEngagement
      }}
    >
      {children}
    </AnalyticsProvider>
  )
}

export default AnalyticsContextProvider
