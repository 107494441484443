import { IGlobalContext, GLOBAL_CONTEXT_ID } from './types'

const emptyContext: IGlobalContext = {
  locale: null,
  slug: '',
  slugEn: null,
  slugEs: null,
  contentId: null,
  contentTypeId: null,
  supportAlternateLocale: null
}

export default function getGlobalContext(): IGlobalContext {
  if (typeof window === 'undefined') {
    return emptyContext
  }

  const globalContextElement = document.getElementById(GLOBAL_CONTEXT_ID)
  if (!globalContextElement) {
    return emptyContext
  }

  try {
    const globalContext = JSON.parse(globalContextElement.innerHTML)
    const slug = globalContext.locale === 'en'
      ? globalContext.slugEn
      : globalContext.slugEs

    return {
      ...globalContext,
      slug: slug || ''
    }
  } catch (error) {
    console.error('Error parsing shared context:', error)
    return emptyContext
  }
}
