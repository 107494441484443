import { CustomizationProvider, DefaultCustomizationContext, ICustomizationContext } from '@context/CustomizationContext'
import { useLocalStorage } from '@context/hooks/useStorage'
import React, { useEffect } from 'react'

const UseCustomizationContext = ({ children }) => {
  const [customization, setCustomization] = useLocalStorage<
    Partial<Omit<ICustomizationContext, 'updateCustomization'>>
  >('customization', DefaultCustomizationContext)

  const updateCustomization = (
    newValue: Partial<Omit<ICustomizationContext, 'updateCustomization'>>
  ) => {
    setCustomization(prevValue => ({ ...prevValue, ...newValue }))
  }

  useEffect(() => {
    if (typeof window !== 'object') return

    if (!customization?.isReduceMotionManuallySet) {
      updateCustomization({
        reduceMotion: window.matchMedia('(prefers-reduced-motion: reduce)')
          .matches
      })
    }
  }, [])

  return (
    <CustomizationProvider value={{ ...customization, updateCustomization }}>
      {children}
    </CustomizationProvider>
  )
}

export default UseCustomizationContext
