import { datadogRum } from '@datadog/browser-rum-slim'
import { NextRouter, withRouter } from 'next/router'
import { Component, ReactComponentElement, ReactNode } from 'react'

interface Props {
  children?: ReactNode
  fallback?: ReactComponentElement<any, any>
  router: NextRouter
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidMount() {
    const { router } = this.props
    router.events.on('routeChangeComplete', this.handleRouteChange)
  }

  componentDidCatch(error, errorInfo) {
    datadogRum?.addError(error, { type: 'UNHANDLED-EXCEPTION', errorInfo })
  }

  componentWillUnmount() {
    const { router } = this.props
    router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  handleRouteChange = () => {
    this.setState({ hasError: false })
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public render() {
    const { hasError } = this.state
    const { children, fallback } = this.props

    if (hasError) {
      return fallback
    }

    return children
  }
}

export default withRouter(ErrorBoundary)
