import { ISnowplowPageContext } from 'app/components/Snowplow/Snowplow.interface'

export const GLOBAL_CONTEXT_ID = 'global-context'

export interface GlobalContextProps extends ISnowplowPageContext {
  locale: string | null
  slugEn: string | null
  slugEs: string | null
  contentId: string | null
  contentTypeId: string | null
  supportAlternateLocale: boolean | null
}

export interface IGlobalContext extends ISnowplowPageContext {
  locale: string | null
  slug: string
  slugEn: string | null
  slugEs: string | null
  contentId: string | null
  contentTypeId: string | null
  supportAlternateLocale: boolean | null
}
