import { createContext, useContext } from 'react'
import { CheckboxProps } from '../Checkbox'
import { InputProps } from '../Input'
import { RadioProps } from '../Radio'
import { SelectProps } from '../Select'
import { TextareaProps } from '../Textarea'

export interface IFormControlContextParams {
  control?: CheckboxProps | InputProps | SelectProps | RadioProps | TextareaProps
  validationError?: boolean
  labelPortal?: HTMLElement
}

export interface IFormControlContext extends IFormControlContextParams {
  updateContext: (newContext: IFormControlContextParams) => void
}

export const FormControlContext = createContext<IFormControlContext>({
  updateContext: () => {}
})

export const useFormControlContext = () => {
  return useContext(FormControlContext)
}
