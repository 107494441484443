import SVGdocuments from '@fractal/primitives/SVGs/icons/documents'
import React from 'react'
import { IDownload } from './types'

export const Download = ({ title, link }: IDownload) => {
  return (
    <div className='bg-background-high-grey2 text-foreground-darkblue p-sm-5 rounded-sm-3 d-md-flex'>
      <div className='d-sm-flex flex-sm-align-center flex-md mb-sm-4 mb-md-0'>
        <div className='mr-sm-4'>
          <SVGdocuments width='50' height='50' className='vicon' />
        </div>
        <p>
          <strong className='f-sm-4'>{title}</strong>
          <span className='f-sm-2 d-sm-block pt-sm-2 pt-md-0'>PDF</span>
        </p>
      </div>
      <div className='text-center'>
        <a
          href={link}
          className='Btn Btn--outline no-underline'
          target='_blank'
          aria-label={`Download ${title} pdf`}
        >
          Download
          <span className='sr-only'>Opens in a new tab</span>
        </a>
      </div>
    </div>
  )
}

export default Download
