import { AnalyticsContext } from '@context/AnalyticsContext'
import { LanguageContext, Languages } from '@context/LanguageContext'
import SVGwave from '@fractal/primitives/SVGs/waves/wave'
import dynamic from 'next/dynamic'
import * as PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import BorderBottom from './BorderBottom'
import HeaderContext from './HeaderContext'
import IconHeader from './IconHeader'
import ReactSidebar from './ReactSidebar'
import SearchBar from './SearchBar'
import { IHeader } from './types'

const NotificationBar = dynamic(() => import('@fractal/features/NotificationBar'), {
  ssr: false
})

const Header = ({
  sidebarLinks,
  searchDisabled,
  wave,
  isTakeNote,
  transparentBackground,
  showAccount,
  showNotificationBar,
  showBorder,
  CTAButtonUrl,
  CTAButtonText,
  CTABottomLinkText,
  CTABottomLinkUrl,
  CTATitle,
  trackingCTAButtonName,
  showCTA = true,
  trackingCTALinkName,
  searchAlwaysOpened = false,
  searchPlaceholder,
  dropdownTitle,
  dropdownUrl
}: IHeader) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [overlay, showOverlay] = useState(false)
  const [searchbarOpen, setSearchbarOpen] = useState(searchAlwaysOpened)
  const toggleSideBar = (sidebar: boolean) => setSidebarOpen(sidebar)
  const toggleSearchBar = (searchbar: boolean) => setSearchbarOpen(!searchbar)

  const { language, enableLocaleSwitcher } = useContext(LanguageContext)
  const { trackNavigation } = useContext(AnalyticsContext)

  const onFocusSkip = e => {
    trackNavigation({
      eventType: 'impression',
      unitName: 'main_menu',
      buttonName: 'skip_to_content',
      unitLocation: 'header'
    })
  }

  const onClickSkip = e => {
    trackNavigation({
      eventType: 'click',
      unitName: 'main_menu',
      buttonName: 'skip_to_content',
      unitLocation: 'header'
    })
  }

  return (
    <HeaderContext.Provider
      value={{
        sidebarOpen,
        searchbarOpen,
        searchDisabled,
        transparentBackground,
        showAccount,
        overlay,
        showOverlay,
        toggleSideBar,
        toggleSearchBar
      }}
    >
      <>
        <a
          href='#target-skip-to-content'
          className='Btn Btn--small Btn--outline no-underline p-sm-absolute sr-only-focusable m-sm-2'
          onFocus={onFocusSkip}
          onClick={onClickSkip}
        >
          Skip to content
        </a>
        {showNotificationBar && <NotificationBar />}
        {enableLocaleSwitcher && (
          <span className='sr-only' aria-live='assertive' aria-atomic='true'>
            {language === Languages.ES
              ? 'This page is in Spanish'
              : 'This page is in English'}
          </span>
        )}
        <IconHeader
          CTAButtonUrl={CTAButtonUrl}
          CTAButtonText={CTAButtonText}
          showCTA={showCTA}
          isTakeNote={isTakeNote}
          transparentBackground={transparentBackground}
          showAccount={showAccount}
          trackingCTAButtonName={trackingCTAButtonName}
          searchAlwaysOpened={searchAlwaysOpened}
          dropdownTitle={dropdownTitle}
          dropdownUrl={dropdownUrl}
        />
        {wave && <SVGwave className='text-static-darkblue vicon' />}

        <SearchBar
          transparentBackground={transparentBackground}
          searchAlwaysOpened={searchAlwaysOpened}
          searchPlaceholder={searchPlaceholder}
        />
        <BorderBottom showBorder={showBorder} />
        <ReactSidebar
          links={sidebarLinks}
          CTAButtonUrl={CTAButtonUrl}
          CTAButtonText={CTAButtonText}
          CTATitle={CTATitle}
          CTABottomLinkText={CTABottomLinkText}
          CTABottomLinkUrl={CTABottomLinkUrl}
          trackingCTAButtonName={trackingCTAButtonName}
          trackingCTALinkName={trackingCTALinkName}
        />
      </>
    </HeaderContext.Provider>
  )
}

Header.defaultProps = {
  showBorder: false,
  showAccount: true
}

Header.propTypes = {
  sidebarLinks: PropTypes.array,
  searchDisabled: PropTypes.bool,
  wave: PropTypes.bool,
  transparentBackground: PropTypes.bool,
  showAccount: PropTypes.bool,
  language: PropTypes.bool,
  showBorder: PropTypes.bool
}

Header.displayName = 'Header'

export { Header }

export default Header
