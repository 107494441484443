// @ts-nocheck
import styled, { createGlobalStyle, css } from 'styled-components'

interface IStyledSidebarProps {
  sidebarOpen: boolean
}

interface IStyledSearchButtonProps {
  searchDisabled: boolean
  searchbarOpen: boolean
  transparentBackground?: boolean
}

interface IStyledMenuButtonProps {
  transparentBackground?: boolean
}

export const tapTarget = css`
  min-width: 44px;
  min-height: 44px;
`

export const StyledDropdown = styled.div.attrs({
  id: 'dropdown-account',
  'data-testid': 'dropdownAccount',
  className: 'arrow-up p-sm-absolute shadow rounded-sm-1 ml-sm-n6 text-nowrap'
})`
  z-index: 3;
`

const TappableButton = styled.button`
  ${tapTarget}
`

export const StyledSearchButton = styled(TappableButton).attrs<
  IStyledSearchButtonProps
>(({ searchDisabled, searchbarOpen, transparentBackground }) => ({
  disabled: searchDisabled ? true : false,
  className: transparentBackground
    ? 'Btn-link text-foreground-darkblue flex-sm-align-center flex-sm-justify-center icon-hover no-print'
    : 'Btn-link text-static-white flex-sm-align-center flex-sm-justify-center icon-hover no-print',
  id: 'search-toggle',
  'data-testid': searchDisabled ? 'disabledSearch' : 'enabledSearch',
  'aria-controls': 'searchForm',
  'aria-owns': 'searchForm',
  'aria-expanded': searchbarOpen ? 'true' : 'false',
  'aria-label': searchDisabled ? 'Search disabled' : 'Open search'
}))<IStyledSearchButtonProps>`
  opacity: ${({ searchDisabled }) => (searchDisabled ? '50%' : '100%')};
`

export const StyledMenuButton = styled(TappableButton).attrs<
  IStyledMenuButtonProps
>(({ transparentBackground }) => ({
  'data-testid': 'sidebarHamburger',
  className: transparentBackground
    ? 'Btn-link text-foreground-darkblue flex-sm-align-center flex-sm-justify-center icon-hover no-print'
    : 'Btn-link text-static-white flex-sm-align-center flex-sm-justify-center icon-hover no-print'
}))<IStyledMenuButtonProps>``

export const StyledLoginButton = styled(TappableButton).attrs({
  className:
    'Btn-link d-sm-inline-flex text-static-white flex-sm-align-center flex-sm-justify-center icon-hover'
})``
