// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IParagraphProps } from './types'

/** Paragraph component would map to contentful Paragraph */
function Paragraph ({
  children,
  alternate,
  sourceInfo,
  linkText,
  smallText,
  unstyled
}: IParagraphProps) {
  let style = 'f-sm-4 f-md-5 pb-sm-5 pb-md-4'
  if (alternate) {
    style = 'f-sm-3 f-md-4 pb-sm-6 pb-md-4'
  }
  if (sourceInfo) {
    style = 'f-sm-2 f-md-3 pb-sm-6 pb-md-4'
  }
  if (linkText) {
    style = 'f-sm-4 f-md-5 pb-sm-0 pb-md-0'
  }
  if (smallText) {
    style = 'f-sm-1 pb-sm-2'
  }
  if (unstyled) {
    style = ''
  }

  return (
    <p className={`${style}  text-foreground-darkblue`} data-testid='paragraph'>
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Paragraph
