export const isFunction = (value: any) => value
  && (Object.prototype.toString.call(value) === '[object Function]'
    || typeof value === 'function'
    || value instanceof Function)

export const setTimeoutWithCancel = (ms, _signal, cb: () => void) => {
  if (_signal.aborted) return null
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      cb()
      resolve('Timeout completed')
    }, ms)

    _signal.addEventListener('abort', () => {
      clearTimeout(timeoutId)
      reject()
    })
  })
}
