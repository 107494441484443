'use client'

import { UserContext } from '@context/UserContext'
import SVGprofile from '@fractal/primitives/SVGs/icons/profile'
import SVGprofileFilled from '@fractal/primitives/SVGs/icons/profileFilled'
import React, { useContext, useEffect } from 'react'
import HeaderContext from '@fractal/features/Header/HeaderContext'
import { Image } from '@/journey/Image'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'
import Dropdown from './Dropdown'
import Mask from './Mask'

function UserLogin({ sys, transparentBackground }: any) {
  const { user, updateAccountModal } = useContext(UserContext)
  const { overlay, showOverlay } = useContext(HeaderContext)

  useEffect(() => {
    if (!user?.is_logged_in && overlay) {
      showOverlay(false)
    }
  }, [user?.is_logged_in, overlay])

  return (
    <div className='p-sm-relative no-print' data-testid='user-signup-login'>
      {user?.is_logged_in ? (
        <button
          type='button'
          aria-label='User menu'
          className={`Btn-link d-sm-flex target flex-sm-align-center flex-sm-justify-center icon-hover
            ${transparentBackground ? 'text-static-darkblue' : 'text-static-white'}
          `}
          onClick={() => showOverlay(!overlay)}
        >
          {user?.picture ? (
            <Image
              alt='User picture'
              src={user?.picture}
              width={32}
              height={32}
              className='circle-sm'
            />
          ) : (
            <SVGprofileFilled
              className={`vicon ${transparentBackground ? 'text-foreground-darkblue' : ''}`}
            />
          )}
        </button>
      ) : (
        <TrackClick
          as='button'
          type='button'
          aria-label='Account'
          className='Btn-link d-sm-inline-flex text-static-white flex-sm-align-center flex-sm-justify-center icon-hover target'
          onClick={() => {
            updateAccountModal?.(true)
          }}
          trackProps={{
            clickType: 'button',
            buttonName: 'login',
            unitName: 'main_menu',
            unitLocation: 'header',
            componentId: sys.id
          }}
        >
          <SVGprofile
            className={`vicon ${transparentBackground ? 'text-foreground-darkblue' : ''}`}
          />
        </TrackClick>
      )}
      {overlay && (
        <Dropdown sys={sys} />
      )}
      {overlay && (
        <Mask transparent handleClick={() => showOverlay(!overlay)} />
      )}
    </div>
  )
}

export default UserLogin
