import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGthumbsUp = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 25H10L20 10.0002V0H25C35 0.000387126 35 5.00022 35 15H40C45 15 50 20.2001 50 25V35.0003C50 45.0003 45 50 35 50H0V25ZM25 10.0002V5C27.1582 5.0001 28.245 5.28204 28.7505 5.50671C29.0822 5.65414 29.1642 5.75891 29.2779 5.98631C29.4817 6.39401 29.7243 7.21201 29.861 8.85289C29.9964 10.477 30 12.4353 30 15C30 17.7614 32.2386 20 35 20H40C40.8133 20 42.1032 20.483 43.3161 21.7121C44.538 22.9503 45 24.255 45 25V35.0003C45 39.2296 43.9461 41.4831 42.7145 42.7146C41.483 43.9461 39.2294 45 35 45H15V26.5139L24.1602 12.7738C24.7078 11.9524 25 10.9874 25 10.0002ZM10 30H5V45H10V30Z'
      />
    </svg>
  )
}

export default SVGthumbsUp
