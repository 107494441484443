import TrackClick from 'app/components/TrackClick/TrackClick.client'
import SVGAssistantIcon from 'components/assistant/SVGassistant'

export default function AssistantIcon({ transparentBackground = false, sysId }: {
  transparentBackground: boolean | undefined
  sysId: string
}) {
  return (
    <TrackClick
      as='a'
      className={`Btn-link flex-sm-align-center flex-sm-justify-center icon-hover no-print target
        ${transparentBackground ? 'text-foreground-darkblue' : 'text-static-white'}`}
      data-testid='iconHeader-understood-ai-assistant-link'
      aria-label='Understood AI Assistant'
      href='/assistant'
      trackProps={{
        clickType: 'button',
        buttonName: 'ai_assistant',
        unitName: 'main_menu',
        unitLocation: 'header',
        componentId: sysId
      }}
    >
      <SVGAssistantIcon className='vicon' />
    </TrackClick>
  )
}
