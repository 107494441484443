// @ts-nocheck
import React, { useContext } from 'react'
import HeaderContext from './HeaderContext'

const BorderBottom = ({ showBorder }: { showBorder: boolean }) => {
  const { searchbarOpen, transparentBackground } = useContext(HeaderContext)
  const extraClass = transparentBackground ? '' : 'bg-static-darkblue'

  return (
    <div className={extraClass}>
      <div className={`${searchbarOpen ? 'container' : 'd-sm-none'}`}>
        <div className='row'>
          <div className='col-md-10 offset-md-1'>
            <div
              className={`${
                showBorder ? 'border-sm-bottom border-static-grey3' : ''
              } pt-sm-5 pt-md-6`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { BorderBottom }
export default BorderBottom
