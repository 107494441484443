import { createContext } from 'react'

interface IContentfulContext {
  getPropsByType: (type: string, index?: number) => any
}

const DefaultContentfulContext: IContentfulContext = {
  getPropsByType: (type, index) => ({})
}

const ContentfulContext = createContext(DefaultContentfulContext)

export default ContentfulContext
