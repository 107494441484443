'use client'

import React, { useContext } from 'react'
import HeaderContext from '@fractal/features/Header/HeaderContext'
import SVGsearch from '@fractal/primitives/SVGs/icons/search'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'

function SearchButton({
  sys,
  disableSearch = false,
  searchAlwaysOpened = false,
  transparentBackground
}: any) {
  const { searchbarOpen, toggleSearchBar } = useContext(HeaderContext)

  const handleSearchClick = () => {
    toggleSearchBar!(searchbarOpen)
  }

  return !searchAlwaysOpened ? (
    <TrackClick
      as='button'
      type='button'
      className={`Btn-link flex-sm-align-center flex-sm-justify-center icon-hover no-print target
        ${transparentBackground ? 'text-foreground-darkblue' : 'text-static-white'}`}
      id='search-toggle'
      data-testid={disableSearch ? 'disabledSearch' : 'enabledSearch'}
      aria-controls='searchForm'
      aria-owns='searchForm'
      aria-expanded={searchbarOpen}
      aria-label={disableSearch ? 'Search disabled' : 'Open search'}
      disabled={disableSearch}
      onClick={handleSearchClick}
      trackProps={{
        clickType: 'button',
        buttonName: 'search',
        unitName: 'main_menu',
        unitLocation: 'header',
        componentId: sys.id
      }}
    >
      <SVGsearch className='vicon' />
    </TrackClick>
  ) : null
}

export default SearchButton
