import { AnalyticsContext } from '@context/AnalyticsContext'
import { useLocalStorage } from '@context/hooks/useStorage'
import { Message } from 'ai'
import { useContext } from 'react'
import ReactModal from 'react-modal'
import { downloadChatHistory } from './utils'

const customStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '310px',
    padding: '0px',
    transform: 'translate(-50%, -50%)',
    borderWidth: '0px',
    overflow: 'hidden'
  },
  overlay: { zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.5)' }
}

export default function StartOverModal({ isOpen, closeModal }: {
  isOpen: boolean
  closeModal: () => void
}) {
  // uses the same local storage keys as ChatWindow.tsx, lines 57, 59, 68
  const [, setStoredMessages] = useLocalStorage<Message[]>('message_history', [])
  const [, setStoredMessageResponseIds] = useLocalStorage<Record<string, string>>('message_response_ids', {})
  const [, setStoredFeedback] = useLocalStorage<Record<string, string>>('response_feedback', {})
  const [, setStoredStep] = useLocalStorage<number>('current_onboarding_step', 0)
  const [, setStoredResponses] = useLocalStorage<string[]>('onboarding_responses', [])
  const [, setStoredTopLevelConcern] = useLocalStorage<string>('top_level_concern', '')
  const { trackClick } = useContext(AnalyticsContext)

  const startOver = () => {
    setStoredMessages([])
    setStoredMessageResponseIds({})
    setStoredFeedback({})
    setStoredStep(0)
    setStoredResponses([])
    setStoredTopLevelConcern('')
    closeModal()
    window.location.reload()
  }

  const onClick = () => {
    trackClick({
      buttonName: 'assistant:download_chat_history',
      buttonText: 'assistant:download_chat_history',
      unitName: 'assistant_interface',
      unitLocation: 'assistant',
      componentId: '00000005',
      clickType: 'text',
      isModal: true
    })

    // note: using the value from useLocalStorage does not work here
    const messages = localStorage.getItem('message_history')
    const parsedMessages = messages ? JSON.parse(messages) : []
    const responses = localStorage.getItem('onboarding_responses')
    const parsedResponses = responses ? JSON.parse(responses) : []
    const responseMessages = parsedResponses.map((r) => ({ role: 'user' as 'user', content: r, id: Date.now().toString() }))
    const allMessages = [...responseMessages, ...parsedMessages]
    downloadChatHistory(allMessages)
  }

  return (
    <ReactModal
      style={customStyle}
      isOpen={isOpen}
      role='dialog'
      onRequestClose={closeModal}
    >
      <form onSubmit={startOver} className='p-sm-5 bg-background-low-white'>
        <p className='f-sm-3 mb-sm-4'>
          You&apos;re about to start a new conversation, where you will lose your current one. To save it, you can
          {' '}
          <button type='button' onClick={onClick} className='Btn-link primary-link text-foreground-midblue text-underline'>
            export
          </button>
          {' '}
          the conversation.
        </p>
        <div className='text-center'>
          <button
            type='submit'
            className='Btn Btn--outline Btn--responsive border-static-scarletTint f-md-4'
          >
            Start a new chat
          </button>
        </div>
      </form>
    </ReactModal>
  )
}
