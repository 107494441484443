import React from 'react'
import { ICustomStatsigContext } from './types'

const DefaultCustomStatsigContext: ICustomStatsigContext = {
  isInitialized: false,
  isUpdating: true,
  getExperiment: () => undefined,
  getStatsigExperiment: () => undefined,
  checkGate: () => false,
  checkStatsigGate: () => false,
  logEvent: () => { },
  registerCustomData: () => { },
  experiments: {},
  gates: {}
}

const CustomStatsigContext = React.createContext(DefaultCustomStatsigContext)

const { Provider, Consumer } = CustomStatsigContext

export {
  Consumer as CustomStatsigConsumer, Provider as CustomStatsigProvider, DefaultCustomStatsigContext
}

export default CustomStatsigContext
