'use client'

import { AnalyticsContext, ISnowplowImpressionEvent } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import React, { useContext, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

interface ITrackingData
  extends Omit<
  ISnowplowImpressionEvent,
  'eventHorizontalPosition' | 'eventVerticalPosition'
  > { }

export interface ITrackImpressionProps extends ITrackingData {
  children?: React.ReactNode
  className?: string
  as?: string
  onFireEvent?: () => void
}

export function TrackImpression({
  as = 'div',
  onFireEvent,
  children,
  className,
  ...props
}: ITrackImpressionProps) {
  const [ref, inView, entry] = useInView({ triggerOnce: true, threshold: 0 })
  const { trackImpression } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  useEffect(() => {
    if (inView) {
      const viewportOffset = entry?.target?.getBoundingClientRect()
      trackImpression({
        ...props,
        parentComponentId: parentComponent?.sys.id,
        positionInParentComponent: props.componentId ? getPositionInParent(props.componentId) : null,
        eventHorizontalPosition: viewportOffset?.left || window.scrollX,
        eventVerticalPosition: viewportOffset?.top || window.scrollY,
        positionInUnit: props?.positionInUnit
      })
      onFireEvent?.()
    }
  }, [inView])

  if (as === 'fragment') {
    return (
      <>
        {children}
        <span ref={ref} />
      </>
    )
  }

  return React.createElement(as, {
    className,
    ref
  }, children)
}

export default TrackImpression
