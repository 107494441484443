import React, { useEffect } from 'react'
import { useFormControlContext } from '../FormControl'
import { FormErrorProps } from './FormError.interface'
import styles from './FormError.module.scss'

const FormError = ({ children, className, ...props }: FormErrorProps) => {
  const { updateContext, control } = useFormControlContext()

  useEffect(() => {
    updateContext({ validationError: !!children })
  }, [children])

  if (!children) {
    return null
  }

  const id = (control?.id || control?.name) + '_error'
  const dataTestId = props['data-testid'] || control?.['data-testid'] + '-error'

  return (
    <div
      id={id}
      data-testid={dataTestId}
      role='alert'
      className={`f-sm-2 mt-sm-3 mb-sm-4 ${styles.FormError} ${className}`}
    >
      {children}
    </div>
  )
}

export default FormError
