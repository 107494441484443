import * as React from 'react'
import { ISVGsProps } from '../types'

function SVGnewChat({
  className = 'fill-background-high-ochreLite',
  width = '32',
  height = '32',
  fill = 'none'
}: ISVGsProps) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      fill={fill}
      data-testid='new-chat-svg'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16' cy='16' r='16' />
      <g clipPath='url(#clip0_2929_2929)'>
        <rect width='11.84' height='12' transform='translate(9.61572 9.78003)' />
        <path
          fill='var(--foreground-darkblue)'
          stroke='var(--foreground-darkblue)'
          d='M21.4557 15.18V16.38H16.1277V21.78H14.9437V16.38H9.61572V15.18H14.9437V9.78003H16.1277V15.18H21.4557Z'
        />
      </g>
      <defs>
        <clipPath id='clip0_2929_2929'>
          <rect width='11.84' height='12' fill='white' transform='translate(9.61572 9.78003)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SVGnewChat
