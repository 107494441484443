import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGmenu = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-label='Main menu'
      role='img'
      fill={fill}
    >
      <path d='M0 7.5h50v5H0v-5zm0 15h50v5H0v-5zm50 15H0v5h50v-5z' />
    </svg>
  )
}

export default SVGmenu
