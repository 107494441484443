'use client'

import { Link } from '@/journey/Link'
import {
  AnalyticsContext,
  ISnowplowClickEvent,
  ISnowplowEngagementEvent
} from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext/PageComponentsContext'
import React, { useContext } from 'react'

interface ClickProps {
  trackProps: ISnowplowClickEvent
}

interface EngagementProps {
  trackProps: ISnowplowEngagementEvent
  isEngagementEvent: true
}

type TrackProps = ClickProps | EngagementProps

type TrackClickProps = {
  as?: React.ElementType
  children?: React.ReactNode
  [key: string]: any
} & TrackProps

function TrackClick({
  as = Link,
  children,
  trackProps,
  isEngagementEvent = false,
  ...props
}: TrackClickProps) {
  const { trackClick, trackSpEngagement } = useContext(AnalyticsContext)
  const { parent, getPositionInParent } = usePageComponentsContext()
  const track = isEngagementEvent ? trackSpEngagement : trackClick

  const extraTrackProps = isEngagementEvent ? {} : {
    parentComponentId: parent?.sys.id,
    positionInParentComponent: getPositionInParent(
      trackProps.componentId || trackProps.unitName
    )
  }

  return React.createElement(as, {
    ...props,
    onClick: (e) => {
      props?.onClick?.(e)
      track({
        ...trackProps,
        ...extraTrackProps
      } as any)
    }
  }, children)
}

export default TrackClick
