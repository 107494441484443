import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGmedium = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        d='M5.92904 13.1599C5.99116 12.5414 5.7571 11.9301 5.29879 11.5138L0.630252 5.84656V5H15.126L26.3305 29.7619L36.1811 5H50V5.84656L46.0084 9.70312C45.6643 9.96744 45.4936 10.4019 45.5649 10.8319V39.1681C45.4936 39.5981 45.6643 40.0326 46.0084 40.2969L49.9066 44.1534V45H30.2988V44.1534L34.3371 40.2028C34.7339 39.8031 34.7339 39.6855 34.7339 39.0741V16.1699L23.5061 44.9059H21.9888L8.9169 16.1699V35.4292C8.80791 36.2389 9.07478 37.0541 9.64052 37.6396L14.8926 44.0594V44.9059H0V44.0594L5.2521 37.6396C5.81373 37.0531 6.06503 36.2325 5.92904 35.4292V13.1599Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default SVGmedium
