// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Heading1 component would map to contentful Heading_1 */
function Heading1 ({ children }: IContentfulBlock) {
  return (
    <h1
      className=' text-foreground-darkblue f-sm-6 f-md-7 pb-sm-2 pb-md-4'
      data-testid='heading1'
    >
      {children}
    </h1>
  )
}

Heading1.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading1
