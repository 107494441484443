import { AnalyticsContext } from '@context/AnalyticsContext'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useLinkedContentUrlBuilder } from '../../utils'
import NavLink from './NavLink'
import { IFooterNavProps } from './types'

const Nav = ({ links }: IFooterNavProps) => {
  const { trackNavigation } = useContext(AnalyticsContext)
  const { buildLinkedContentUrl } = useLinkedContentUrlBuilder()

  return (
    <nav
      className='text-center mt-sm-6 f-sm-2 my-sm-6 text-static-darkblue'
      aria-label='Footer'
    >
      <ul className='list-reset'>
        {links &&
          links.map((link, index) => {
            return (
              <NavLink
                url={link.url}
                href={link.url}
                key={link.label}
                index={index}
                label={link.label}
                text={link.text}
                handleClick={() => {
                  trackNavigation({
                    eventType: 'click',
                    isContent: true,
                    buttonName: link.label,
                    linkedContentUrl: buildLinkedContentUrl({
                      type: link.url
                    }),
                    linkedContentId: link.trackingLinkedContentId,
                    unitName: 'footer',
                    unitLocation: 'footer',
                    positionInUnit: index + 1
                  })
                }}
              />
            )
          })}
      </ul>
    </nav>
  )
}

Nav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
}

export default Nav
