import lowerCaseAndUnderscore from 'utils/lowerCaseAndUnderscore'
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker'
import { ISnowplowClickEvent } from './Snowplow.interface'
import getSharedContext, { trimPayload } from './getSharedContext'

export default function trackClick(event: ISnowplowClickEvent) {
  if (typeof window === 'undefined') {
    return
  }

  trackSelfDescribingEvent({
    event: {
      schema: 'iglu:org.understood/navigation/jsonschema/1-0-12',
      data: trimPayload({
        event_type: 'click',
        button_name: event.buttonName,
        is_content: event.isContent || false,
        is_external: event.isExternal || false,
        click_type: event.clickType,
        button_text: event.buttonText
          ? lowerCaseAndUnderscore(event.buttonText) : event.buttonText,
        link_text: event.linkText
          ? lowerCaseAndUnderscore(event.linkText) : event.linkText,
        is_modal: event.isModal || false
      })
    },
    context: getSharedContext({
      ...event,
      eventHorizontalPosition: window?.scrollX,
      eventVerticalPosition: window?.scrollY
    })
  })
}
