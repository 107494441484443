import { LanguageContext, LanguagePathMap } from '@context/LanguageContext'
import { useContext, useEffect, useState } from 'react'

const useWunderSmartLinkBuilder = () => {
  const { language } = useContext(LanguageContext)
  const localePath = LanguagePathMap.get(language) || 'en'

  const documentReferrer =
    (typeof window === 'object' && window?.document?.referrer) || null

  const locationHref =
    (typeof window === 'object' && window?.location?.href) || null
  
    let createAppleSmartLink = referrer => {
      return (
        'https://apps.apple.com/app/apple-store/id1552485748?pt=122591629&ct=' +
        referrer +
        '&mt=8'
      )
    }
    let createAndroidSmartLink = (referrer) => {
      return (
        'https://play.google.com/store/apps/details?id=org.understood.community&referrer=utm_source%3D' +
        referrer
      )
    }



  const localStorageVariable =
    (typeof window === 'object' &&
      window?.localStorage &&
      window?.localStorage?.getItem('wunder_smart_cta')) ||
    null
  const [appleUrl, setAppleUrl] = useState('')
  const [androidUrl, setAndroidUrl] = useState('')
  const [textVariable, setTextVariable] = useState('')

  useEffect(() => {
    let text = ''
    if (documentReferrer?.includes('facebook.com')) {
      text = 'Android_GTM_FB'
    } else if (documentReferrer?.includes('instagram.com')) {
      text = 'Android_GTM_Instagram'
    } else if (documentReferrer?.includes('reddit.com')) {
      text = 'wunderlandingpagereddit'
    } else if (localStorageVariable) {
      text = localStorageVariable
    } else if (locationHref?.includes('sidemenu')) {
      text = 'understoodsitenav'
    } else if (documentReferrer?.includes('https://t.co/')) {
      text = 'wunderlandingpagetwitter'
    } else if (documentReferrer?.includes('google.com')) {
      text = 'wunderlandingpagegoogle'
    } else if (documentReferrer?.includes('linkedin.com')) {
      text = 'wunderlandingpagelinkedin'
    } else if (documentReferrer?.includes('medium.com')) {
      text = 'wunderlandingpagemedium'
    } else if (documentReferrer?.includes('pinterest.com')) {
      text = 'wunderlandingpagepinterest'
    } else if (documentReferrer?.includes('tiktok.com')) {
      text = 'wunderlandingpageptiktok'
    } else {
      text = 'wunderlandingpage'
    }
    const appleLink = createAppleSmartLink(text)
    const androidLink = createAndroidSmartLink(text)

    setAppleUrl(appleLink)
    setAndroidUrl(androidLink)
    setTextVariable(text)
  }, [localePath])

  return { appleUrl, androidUrl, textVariable }
}

export { useWunderSmartLinkBuilder }
