import React, { useEffect } from 'react'
import styles from './Header.module.scss'

const Mask = ({ handleClick, transparent }) => {
  useEffect(() => {
    document.body.classList.add(styles.noVerticalScroll)
    return () => document.body.classList.remove(styles.noVerticalScroll)
  }, [])

  return (
    <div
      className={`${styles.mask} ${transparent ? styles.transparent : ''}`}
      onClick={handleClick}
    />
  )
}

export default Mask
