import RichText from '@fractal/primitives/atoms/RichText'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import dynamic from 'next/dynamic'
import React from 'react'
import styles from './Footer.module.scss'
import { IComponentFooter } from './Footer.type'
import Newsletter from './components/Newsletter/Newsletter'

const Svglinkedin = dynamic(() => import('@/journey/Svgs/icons/linkedin'), { ssr: false })
const Svgfacebook = dynamic(() => import('@/journey/Svgs/icons/facebook'), { ssr: false })
const SvgPinterest = dynamic(() => import('@/journey/Svgs/icons/pinterest'), { ssr: false })
const Svginstagram = dynamic(() => import('@fractal/primitives/SVGs/socials/instagram'), { ssr: false })
const Svgtiktok = dynamic(() => import('@fractal/primitives/SVGs/socials/tiktok'), { ssr: false })

function Footer({
  sys,
  newsletterTitle,
  newsletterTags,
  socialTitle,
  socialLinksCollection,
  legalCopy,
  address,
  privacyPolicy,
  navLinkGroupsCollection
}: IComponentFooter) {
  const pillClassName = 'w-sm-5 w-md-6 h-sm-5 h-md-6 bg-static-midblueShade circle-sm d-sm-flex flex-sm-justify-center flex-sm-align-center'

  const getSvg = (label: string) => {
    switch (label.toLowerCase()) {
      case 'facebook':
        return <Svgfacebook className='fill-static-white' />
      case 'linkedin':
        return <Svglinkedin className='fill-static-white' />
      case 'pinterest':
        return <SvgPinterest className='fill-static-white' />
      case 'instagram':
        return <Svginstagram className='fill-static-white' />
      case 'tiktok':
        return <Svgtiktok className='fill-static-white' />
      default:
        return null
    }
  }

  return (
    <footer className='bg-background-low-darkblue text-static-cream py-sm-7 pb-md-8' data-testid='page-footer'>
      <TrackImpression
        componentId={sys.id}
        unitName={sys.id}
        unitLocation='inline'
      />
      <div className='grid container'>
        <div className='grid-col-sm-12 grid-start-md-3 grid-col-md-8 grid-col-lg-4 mb-sm-5 mb-md-6 mb-lg-6'>
          <Newsletter
            title={newsletterTitle}
            tags={newsletterTags}
            privacyPolicy={privacyPolicy}
          />
        </div>
        <div className='grid-col-sm-12 grid-start-md-3 grid-col-md-8 grid-start-lg-7 grid-col-lg-5'>
          <div className={`d-sm-flex flex-sm-justify-between mr-md-5 mr-lg-0 ${styles.linksGroupContainer}`}>
            {navLinkGroupsCollection.items.map((navLinks) => (
              <ul key={navLinks.sys.id} className='list-reset my-sm-0'>
                {navLinks.linksCollection.items.map((navLink, index) => {
                  let url
                  let id

                  if ('url' in navLink) {
                    url = navLink.url
                  } else {
                    url = getContentUrl(navLink.linkTo)
                    id = navLink.linkTo?.sys?.id
                  }

                  return (
                    <li key={navLink.sys.id}>
                      <TrackClick
                        href={url}
                        className='primary-link text-static-cream no-underline text-regular f-sm-2 f-md-3'
                        trackProps={{
                          clickType: 'text',
                          isContent: true,
                          linkText: navLink.label,
                          linkedContentId: id,
                          linkedContentUrl: url,
                          unitName: sys.id,
                          unitLocation: 'footer',
                          positionInUnit: index + 1,
                          componentId: sys.id
                        }}
                      >
                        {navLink.label}
                      </TrackClick>
                    </li>
                  )
                })}
              </ul>
            ))}
          </div>
          <div className='mt-sm-6 mb-sm-5 mb-md-6 mb-lg-7'>
            <h3 className='f-sm-3 f-md-5'>{socialTitle}</h3>
            <ul className={`list-reset m-sm-0 d-sm-flex mt-sm-4 ${styles.socialSection}`}>
              {socialLinksCollection.items.map((socialLink) => {
                const { url, label } = socialLink
                const SvgIcon = getSvg(label)
                return (
                  <li key={socialLink.sys.id} className='mr-sm-3'>
                    <TrackClick
                      as='a'
                      href={url}
                      className={pillClassName}
                      trackProps={{
                        clickType: 'button',
                        buttonName: 'connect',
                        buttonText: label.toLowerCase(),
                        linkedContentUrl: url,
                        isExternal: true,
                        unitName: sys.id,
                        componentId: sys.id,
                        unitLocation: 'inline'
                      }}
                    >
                      {SvgIcon}
                    </TrackClick>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        <div className={`grid-col-sm-12 grid-start-md-3 grid-col-md-8 grid-start-lg-2 grid-col-lg-10 ${styles.infoSection}`}>
          <div className={`text-static-cream f-sm-1 ${styles.legalSection}`}>
            <RichText richTextContent={legalCopy.json} unstyled />
          </div>
          <address className='text-static-cream f-sm-1 mt-sm-3'>
            <RichText richTextContent={address.json} unstyled />
          </address>
        </div>
      </div>
    </footer>
  )
}

export default Footer
