import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGlinkedin = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.49999 19.9169H0.333322V6.55595H4.49999V19.9169ZM2.41667 4.81327C1.08198 4.81327 0 3.73578 0 2.40664C0 1.07749 1.08198 0 2.41667 0C3.75135 0 4.83333 1.07749 4.83333 2.40664C4.83333 3.04491 4.57872 3.65705 4.12551 4.10838C3.6723 4.55972 3.05761 4.81327 2.41667 4.81327ZM20 19.917H15.8333V13.444C15.8333 11.8673 15.8333 9.95856 13.6667 9.95856C11.5 9.95856 11.1667 11.6183 11.1667 13.361V20H7.00001V6.55607H11V8.46478H11.0833C11.8572 7.049 13.3844 6.20766 15 6.30711C19.1667 6.30711 20 9.04569 20 12.6142V19.917Z'
      />
    </svg>
  )
}

export default SVGlinkedin
