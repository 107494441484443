// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IHeading3Props } from './types'

/** Heading3 component would map to contentful Heading_3 */
function Heading3 ({ children, linkText }: IHeading3Props) {
  let style = 'pb-sm-2 pb-md-4'

  if (linkText) {
    style = 'pb-sm-0 pb-md-0'
  }

  return (
    <h3
      className={` text-foreground-darkblue f-sm-4 f-md-5 ${style}`}
      data-testid='heading3'
    >
      {children}
    </h3>
  )
}

Heading3.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading3
