'use client'

import IFrame from '@fractal/primitives/Common/IFrame'
import React from 'react'
import styled from 'styled-components'
import { IArticleIFrameProps } from './types'

const ArticleWrapper = styled.div.attrs({
  className: 'd-sm-flex flex-sm-justify-center'
})`
  height: 100%;
`

/* TODO If someone press escape from inside the modal (including iframe... and this is the part that doesnt work), modal should close */

class ArticleIFrame extends React.Component<IArticleIFrameProps> {
  render() {
    return (
      <ArticleWrapper>
        <IFrame
          title={this.props.articleTitle}
          id={this.props.url}
          data-testid='article-iframe'
          frameBorder={0}
          scrolling='yes'
          width='100%'
          allow='geolocation'
          url={this.props.url}
          height='100%'
          tabIndex={this.props.tabIndex}
        />
      </ArticleWrapper>
    )
  }
}

export { ArticleIFrame }
export default ArticleIFrame
