import { Link } from '@/journey/Link'
import * as PropTypes from 'prop-types'
import React from 'react'
import { IHyperlinkProps } from './types'

/** Hyperlink component would map to contentful Hyperlink */
function Hyperlink({ url, children }: IHyperlinkProps) {
  return (
    <Link
      href={url}
      data-testid='hyperlink'
      className=' text-foreground-darkblue primary-link'
    >
      {children}
    </Link>
  )
}

Hyperlink.propTypes = {
  /** accepts url to link to */
  url: PropTypes.string.isRequired,
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}
export default Hyperlink
