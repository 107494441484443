/**
 * The possibleTypes field is used to configure how the
 * client determines the concrete type of an union or
 * interface during a GraphQL query.
 * More info:
 * - https://github.com/apollographql/apollo-client/issues/7648
 * - https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces
 */
export default {
  Entry:
    [
      'ComponentBioHeader',
      'PageGeneric',
      'InfoSeo',
      'Person',
      'ComponentCardList',
      'PageTopics',
      'ExternalTag',
      'ComponentResourceList',
      'PagePartnerships',
      'ComponentCampaignHeader',
      'ComponentAuthorCard',
      'PageAudiences',
      'ComponentHeroHeader',
      'ComponentBodyText',
      'ComponentCardCarousel',
      'LayoutComponentSection',
      'ComponentFilteredResources',
      'Article',
      'ComponentSpotlight',
      'LayoutComponentDuo',
      'ComponentPodcastEpisodeSpotlight',
      'PodcastEpisode',
      'Podcast',
      'ExtraNavLink',
      'InternalTag',
      'ComponentTeaserList',
      'DownloadableAsset',
      'Template',
      'Page',
      'Campaign',
      'ContentBlock',
      'GenericContent',
      'VideoBlock',
      'ComponentLargeHero',
      'ComponentEssentials',
      'ComponentContentFilter',
      'ComponentHelperContentFilterGroup',
      'Guide',
      'TakeNoteSection',
      'TakeNoteTag',
      'UserRoleInternalTagRelationship',
      'ExternalInternalTagRelationship',
      'ArticleOrDownloadableAsset',
      'TagCategory',
      'ContentList',
      'Carousel',
      'CarouselItem',
      'Taxonomy',
      'WunderTestimonial',
      'ComponentCtaButton',
      'ComponentNewsletter',
      'ComponentPromoBanner',
      'ComponentWunderBanner',
      'ComponentHelperPageLayout',
      'Answer',
      'ComponentGenericHeader',
      'ComponentHomepageImpact',
      'ComponentHomepageSectionBlock',
      'ComponentHomepageHeader',
      'ComponentMoreAboutUs',
      'ComponentHelperMoreAboutUs',
      'TinyTout',
      'ComponentAtAGlance',
      'GlossaryField',
      'Accordion',
      'AccordionItem',
      'LandingPage',
      'Checkbox',
      'AlloyEditor',
      'QaBlock',
      'ComponentAuthorCard'
      + 'OrComponentBodyText'
      + 'OrComponentCardCarousel'
      + 'OrComponentCardList'
      + 'OrComponentContentFilter'
      + 'OrComponentCtaButton'
      + 'OrComponentEssentials'
      + 'OrComponentFilteredResources'
      + 'OrComponentLargeHero'
      + 'OrComponentMoreAboutUs'
      + 'OrComponentNewsletter'
      + 'OrComponentPromoBanner'
      + 'OrComponentResourceList'
      + 'OrComponentWunderBanner'
      + 'OrGenericContent'
      + 'OrLayoutComponentDuo'
      + 'OrLayoutComponentSection',
      'ArticleOrCarouselItemOrExternalTagOrPersonOrPodcastEpisodeOrWunderTestimonial',
      'ExternalTagOrInternalTag',
      'ComponentAuthorCard'
      + 'OrComponentBodyText'
      + 'OrComponentCardCarousel'
      + 'OrComponentCardList'
      + 'OrComponentContentFilter'
      + 'OrComponentCtaButton'
      + 'OrComponentEssentials'
      + 'OrComponentFilteredResources'
      + 'OrComponentLargeHero'
      + 'OrComponentNewsletter'
      + 'OrComponentPromoBanner'
      + 'OrComponentResourceList'
      + 'OrComponentSymptomsQuizBanner'
      + 'OrComponentWunderBanner'
      + 'OrLayoutComponentDuo'
      + 'OrLayoutComponentSection',
      'TakeNoteTagOrTaxonomy',
      'ArticleOrCarouselItemOrExternalTagOrPodcastEpisodeOrWunderTestimonial',
      'ContentBlockOrDownloadableAssetOrExpertOrGenericContentOrVideoBlock'
    ],

  PagePartnershipsSectionsItem:
    [
      'ComponentAuthorCard',
      'ComponentBodyText',
      'ComponentCardCarousel',
      'ComponentCardList',
      'ComponentContentFilter',
      'ComponentCtaButton',
      'ComponentEssentials',
      'ComponentFilteredResources',
      'ComponentLargeHero',
      'ComponentNewsletter',
      'ComponentPromoBanner',
      'ComponentResourceList',
      'ComponentWunderBanner',
      'LayoutComponentDuo',
      'LayoutComponentSection'
    ],

  PageAudiencesSectionsItem:
    [
      'ComponentAuthorCard',
      'ComponentBodyText',
      'ComponentCardCarousel',
      'ComponentCardList',
      'ComponentContentFilter',
      'ComponentCtaButton',
      'ComponentEssentials',
      'ComponentFilteredResources',
      'ComponentLargeHero',
      'ComponentNewsletter',
      'ComponentPromoBanner',
      'ComponentResourceList',
      'ComponentWunderBanner',
      'LayoutComponentDuo',
      'LayoutComponentSection'
    ],

  ComponentCardCarouselContentListItem:
    [
      'Article',
      'CarouselItem',
      'ExternalTag',
      'PodcastEpisode',
      'WunderTestimonial'
    ],

  LayoutComponentDuoComponentOne:
    [
      'ComponentPodcastEpisodeSpotlight',
      'ComponentSpotlight',
      'ComponentTeaserList'
    ],

  ComponentTeaserListContentListItem:
    [
      'Article',
      'DownloadableAsset'
    ],

  TemplateComponentsItem:
    [
      'ContentBlock',
      'DownloadableAsset',
      'GenericContent',
      'VideoBlock'
    ],

  ComponentEssentialsSpotlight:
    [
      'Article',
      'VideoBlock'
    ],

  ComponentEssentialsFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  ComponentContentFilterDefaultFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  ComponentHelperContentFilterGroupTagsItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  GuideTryItem:
    [
      'Article',
      'DownloadableAsset'
    ],

  ComponentTeaserListFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  LayoutComponentDuoComponentTwo:
    [
      'ComponentPodcastEpisodeSpotlight',
      'ComponentSpotlight',
      'ComponentTeaserList'
    ],

  ComponentSpotlightResource:
    [
      'Article',
      'PodcastEpisode',
      'VideoBlock'
    ],

  ComponentSpotlightFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  ArticleTaxonomyItem:
    [
      'TakeNoteTag',
      'Taxonomy'
    ],

  ComponentCardCarouselFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  ComponentResourceListContentListItem:
    [
      'Article',
      'DownloadableAsset'
    ],

  ComponentResourceListFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  PageTopicsSectionsItem:
    [
      'ComponentAuthorCard',
      'ComponentBodyText',
      'ComponentCardCarousel',
      'ComponentCardList',
      'ComponentContentFilter',
      'ComponentCtaButton',
      'ComponentEssentials',
      'ComponentFilteredResources',
      'ComponentLargeHero',
      'ComponentNewsletter',
      'ComponentPromoBanner',
      'ComponentResourceList',
      'ComponentWunderBanner',
      'LayoutComponentDuo',
      'LayoutComponentSection'
    ],

  ComponentCardListContentListItem:
    [
      'Article',
      'CarouselItem',
      'ExternalTag',
      'Person',
      'PodcastEpisode',
      'WunderTestimonial'
    ],

  ComponentCardListFilterByItem:
    [
      'ExternalTag',
      'InternalTag'
    ],

  PageGenericHeader:
    [
      'ComponentBioHeader',
      'ComponentGenericHeader',
      'GenericContent'
    ],

  PageGenericSectionsItem:
    [
      'ComponentAuthorCard',
      'ComponentBodyText',
      'ComponentCardCarousel',
      'ComponentCardList',
      'ComponentContentFilter',
      'ComponentCtaButton',
      'ComponentEssentials',
      'ComponentFilteredResources',
      'ComponentLargeHero',
      'ComponentMoreAboutUs',
      'ComponentNewsletter',
      'ComponentPromoBanner',
      'ComponentResourceList',
      'ComponentWunderBanner',
      'GenericContent',
      'LayoutComponentDuo',
      'LayoutComponentSection'
    ]
}
