import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGprofileFilled = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 0c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15S33.3 0 25 0zm20 44c0-5.5-4.5-10-10-10H15C9.5 34 5 38.5 5 44v6h40v-6z'
      />
    </svg>
  )
}

export default SVGprofileFilled
