interface ISVGsProps {
  className?: string
  fill?: string
}

export default function SVGAssistantIcon({ fill = 'none', className = '' }: ISVGsProps) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {/* eslint-disable-next-line max-len */}
      <path fillRule='evenodd' clipRule='evenodd' d='M8.81763 2.0495H1.46348C0.674505 2.0495 0.0349121 2.68909 0.0349121 3.47807V14.8593C0.0349121 15.6482 0.674505 16.2878 1.46348 16.2878H3.67154V18.8085C3.67154 19.6181 4.58344 20.0921 5.2461 19.6269L10.0027 16.2878H16.6889C17.4779 16.2878 18.1175 15.6482 18.1175 14.8593V10.817H15.9746V14.145H9.32566L5.8144 16.6099V14.145H2.17777V4.19236H8.81763V2.0495Z' />
      {/* eslint-disable-next-line max-len */}
      <path fillRule='evenodd' clipRule='evenodd' d='M8.99981 6.97834C9.44858 7.06405 9.87367 7.21408 10.2653 7.4185C10.4395 7.50944 10.6071 7.61112 10.7671 7.72275C11.2131 8.0337 11.6009 8.42154 11.9119 8.86752C12.0235 9.02761 12.1252 9.19518 12.2161 9.36935C12.4206 9.76097 12.5706 10.1861 12.6563 10.6349C12.6598 10.6534 12.6633 10.672 12.6666 10.6906C12.6967 10.8592 12.9386 10.8592 12.9687 10.6906C12.972 10.672 12.9754 10.6534 12.9789 10.6349C13.0647 10.1861 13.2147 9.76097 13.4191 9.36935C13.51 9.19518 13.6117 9.02761 13.7233 8.86752C14.0343 8.42154 14.4221 8.0337 14.8681 7.72275C15.0282 7.61112 15.1958 7.50944 15.3699 7.4185C15.7616 7.21408 16.1867 7.06405 16.6355 6.97834C16.654 6.9748 16.6725 6.97138 16.6912 6.96804C16.8598 6.93799 16.8598 6.69607 16.6912 6.66602C16.6725 6.66268 16.654 6.65926 16.6355 6.65572C16.1867 6.57001 15.7616 6.41998 15.3699 6.21556C15.1958 6.12462 15.0282 6.02294 14.8681 5.91131C14.4221 5.60036 14.0343 5.21252 13.7233 4.76654C13.6117 4.60645 13.51 4.43888 13.4191 4.26471C13.2147 3.87309 13.0647 3.44796 12.9789 2.99919C12.9754 2.98066 12.972 2.96209 12.9687 2.94349C12.9386 2.77486 12.6967 2.77486 12.6666 2.94349C12.6633 2.96209 12.6598 2.98066 12.6563 2.99919C12.5706 3.44796 12.4206 3.87309 12.2161 4.26471C12.1252 4.43888 12.0235 4.60645 11.9119 4.76654C11.6009 5.21252 11.2131 5.60036 10.7671 5.91131C10.6071 6.02294 10.4395 6.12462 10.2653 6.21556C9.87367 6.41998 9.44858 6.57001 8.99981 6.65572C8.98128 6.65926 8.96271 6.66268 8.94407 6.66602C8.77548 6.69607 8.77548 6.93799 8.94407 6.96804C8.96271 6.97138 8.98128 6.9748 8.99981 6.97834Z' />
      {/* eslint-disable-next-line max-len */}
      <path fillRule='evenodd' clipRule='evenodd' d='M14.9977 2.60083C15.2781 2.6544 15.5438 2.74816 15.7886 2.87593C15.8975 2.93277 16.0022 2.99632 16.1022 3.06609C16.381 3.26042 16.6234 3.50283 16.8177 3.78156C16.8875 3.88162 16.951 3.98635 17.0079 4.09521C17.1357 4.33997 17.2294 4.60568 17.283 4.88616C17.2852 4.89774 17.2873 4.90934 17.2894 4.92097C17.3082 5.02637 17.4594 5.02637 17.4782 4.92097C17.4802 4.90934 17.4824 4.89774 17.4846 4.88616C17.5382 4.60568 17.6319 4.33997 17.7597 4.09521C17.8165 3.98635 17.8801 3.88162 17.9499 3.78156C18.1442 3.50283 18.3866 3.26042 18.6654 3.06609C18.7654 2.99632 18.8701 2.93277 18.979 2.87593C19.2238 2.74816 19.4895 2.6544 19.7699 2.60083C19.7815 2.59862 19.7931 2.59648 19.8047 2.59439C19.9101 2.57561 19.9101 2.42441 19.8047 2.40563C19.7931 2.40354 19.7815 2.4014 19.7699 2.39919C19.4895 2.34562 19.2238 2.25185 18.979 2.12409C18.8701 2.06725 18.7654 2.0037 18.6654 1.93393C18.3866 1.73959 18.1442 1.49719 17.9499 1.21845C17.8801 1.1184 17.8165 1.01367 17.7597 0.904806C17.6319 0.660045 17.5382 0.394338 17.4846 0.113856C17.4824 0.102276 17.4802 0.0906731 17.4782 0.0790447C17.4594 -0.0263482 17.3082 -0.0263482 17.2894 0.0790447C17.2873 0.0906731 17.2852 0.102276 17.283 0.113856C17.2294 0.394338 17.1357 0.660045 17.0079 0.904806C16.951 1.01367 16.8875 1.1184 16.8177 1.21845C16.6234 1.49719 16.381 1.73959 16.1022 1.93393C16.0022 2.0037 15.8975 2.06725 15.7886 2.12409C15.5438 2.25185 15.2781 2.34562 14.9977 2.39919C14.9861 2.4014 14.9745 2.40354 14.9628 2.40563C14.8574 2.42441 14.8574 2.57561 14.9628 2.59439C14.9745 2.59648 14.9861 2.59862 14.9977 2.60083Z' />
    </svg>
  )
}
