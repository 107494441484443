import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGtiktok = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M19.0342 8.21349C17.2546 8.21349 15.6069 7.64798 14.2614 6.68695V13.6727C14.2614 17.1672 11.427 20 7.93085 20C6.6264 20 5.41389 19.6058 4.40664 18.9298L4.40605 18.9292C2.71373 17.7935 1.6001 15.863 1.6001 13.6726C1.6001 10.1783 4.43441 7.3455 7.93097 7.3455C8.22151 7.34535 8.51172 7.36502 8.79958 7.40438L8.79946 10.904C8.52242 10.8161 8.22707 10.7684 7.92065 10.7684C6.32122 10.7684 5.02488 12.0643 5.02488 13.6627C5.02488 14.7929 5.67286 15.7715 6.6178 16.2482C7.00964 16.4458 7.45199 16.5569 7.92065 16.5569C9.51677 16.5569 10.8109 15.2663 10.8164 13.6726V0H14.2614V0.440266C14.2735 0.571948 14.291 0.70308 14.3138 0.833333C14.5529 2.19641 15.3684 3.36099 16.5002 4.06686C17.2602 4.54104 18.1384 4.79167 19.0342 4.79032L19.0342 8.21349Z' />
    </svg>
  )
}

export default SVGtiktok
