'use client'

import SVGtakeNoteWave from '@fractal/primitives/SVGs/waves/takeNoteWave'
import React from 'react'
import { StyledContainer } from './styled'
import { IYoutubeVideo } from './types'

function YoutubeVideo({
  url,
  backgroundColor = 'page-landing',
  showWave = false
}: IYoutubeVideo) {
  return (
    <div data-testid='youtube-video'>
      {showWave && (
        <div className='p-sm-absolute right-sm-0 left-sm-0 pt-sm-6 pt-md-8 bg-background-page-white'>
          <SVGtakeNoteWave className='text-foreground-white bg-background-page-white vicon mt-sm-8' />
        </div>
      )}
      <div
        className={`${backgroundColor === 'page-landing'
            ? 'bg-background-page-white'
            : backgroundColor === 'page-article'
              ? 'bg-background-page-white'
              : 'bg-background-high-grey2'
          }`}
      >
        <div className='container-md container-lg'>
          <div className='row'>
            <div className='col-sm-12 offset-lg-2 col-md-10 offset-md-1 col-lg-8 mb-sm-5'>
              <StyledContainer>
                <iframe
                  className='border-sm-0 rounded-md-4 p-sm-absolute top-sm-0 bottom-sm-0 left-sm-0 right-sm-0 w-sm-full h-sm-full'
                  width='100%'
                  height='100%'
                  src={url}
                  tabIndex={0}
                  title='Youtube Media'
                />
              </StyledContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YoutubeVideo
