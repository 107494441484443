export enum Languages {
  EN = 'en-US',
  ES = 'es-MX'
}

export interface ILanguageContext {
  language: Languages
  changeLanguage: (arg: string) => void
  enableLocaleSwitcher: boolean
  slug: string
  slugEn: string
  slugEs: string
  langAbbrev: string
}
