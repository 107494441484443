'use client'

import { LanguageContext, Languages } from '@context/LanguageContext'
import React, { useContext } from 'react'

function AriaLocale() {
  const { language, enableLocaleSwitcher } = useContext(LanguageContext)

  return enableLocaleSwitcher ? (
    <span className='sr-only' aria-live='assertive' aria-atomic='true'>
      {language === Languages.ES
        ? 'This page is in Spanish'
        : 'This page is in English'}
    </span>
  ) : null
}

export default AriaLocale
