// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Heading4 component would map to contentful Heading_4 */
function Heading4 ({ children }: IContentfulBlock) {
  return (
    <h4
      className=' text-foreground-darkblue f-sm-4 f-md-5 pb-sm-3 pb-md-4 lh-condensed'
      data-testid='heading4'
    >
      {children}
    </h4>
  )
}

Heading4.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading4
