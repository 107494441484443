import React from 'react'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type: 'checkbox'
  label?: string
  checkboxType?: 'default' | 'emoji' | 'pill' | 'switcher'
  selectedBackgroundColor?: 'ochreLite' | 'midblueLite' | 'peach' | 'creamLite'
  unselectedBackgroundColor?: 'white' | 'grey2' | 'ochreTint'
  iconName?:
  | 'arrow-left'
  | 'arrow-right'
  | 'documents'
  | 'bookmarked'
  | 'classroom'
  | 'schoology'
  | 'play'
  | 'un-bookmarked'
  | 'copy-link'
  | 'arrow-up'
  | 'caret-left'
  | 'caret-right'
  | 'chat'
  | 'channel'
  | 'close'
  | 'edit'
  | 'email'
  | 'facebook'
  | 'google'
  | 'apple'
  | 'link'
  | 'menu'
  | 'pinterest'
  | 'printer'
  | 'profile'
  | 'profile-filled'
  | 'search'
  | 'share'
  | 'thumbs_down'
  | 'thumbs_up'
  | 'thumbs_neutral'
  | 'green_dot'
  | 'yellow_dot'
  | 'topic'
  | 'bullet'
  | 'bullet_grey'
  | 'twitter'
  | 'linkedin'
  | 'youtube'
  | 'facebook_login'
  | 'medium'
  | 'educator_icon'
  | 'bookmark'
  | 'notes'
  | 'globe'
  | 'moon'
  | 'add'
  | 'check'
  | 'eye-blocked'
  | 'eye'
  | 'happy'
  | 'ok'
  | 'sad'
  | 'mad'
  | 'nervous'
  | 'embarrassed'
  | 'confused'
  | 'overwhelm'
  | 'dontKnow'
  | 'dotYellow'
  | 'dotRed'
  | 'dotGreen'
  | 'red_dot'
}
