/* eslint-disable no-underscore-dangle */
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import get from 'lodash/get'
import { IRichText } from '../types/RichText.type'

export const getContentUrl = (content, overrideType: string | null = null): string => {
  const type = overrideType || get(content, '__typename') || content?.typename

  switch (type) {
    case 'Generic':
      return content.url || `/${content.slug}`

    case 'Article':
      return content.url || `/articles/${content.slug}`

    case 'Topic':
      return content.url || `/topics/${content.slug}`

    case 'ExternalTag':
      return `/topics/${content.slug}`

    case 'PodcastEpisode': {
      const podcastSlug = content.linkedFrom?.podcastCollection.items[0]?.slug
        || content.podcast?.slug
      return `/podcasts/${podcastSlug}/${content.slug}`
    }
    case 'Podcast':
      return `/podcasts/${content.slug}`

    case 'CarouselItem':
      return `/articles/${content.linkedFrom.carouselCollection.items[0].linkedFrom.articleCollection.items[0].slug}`

    case 'DownloadableAsset':
      return content.asset?.url

    case 'Person':
      return content.slug ? `/people/${content.slug}` : `/people/${content.sys?.id}`

    case 'PageGeneric':
      return `/${content.slug}`

    case 'PageTopics':
      return `/topics/${content.externalTag?.slug}`

    case 'PageAudiences':
      return `/audiences/${content.slug}`

    case 'PageHome':
      return '/'

    case 'PagePartnerships':
      return `/partnerships/${content.slug}`

    case 'Page':
      return `${content.slug}`

    case 'Asset':
      return `${content.url}`

    case 'PressRelease':
      return `/press-releases/${content.slug}`

    case 'ResearchAndSurveys':
      return `/research-and-surveys/${content.reseachSlug}`

    case 'NewsAppearance':
      return `${content.newsAppearanceLinkToPublication}`

    default:
      return ''
  }
}

export const getObjectUrlFromDownloadUrl = async (url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

interface Article {
  __typename: 'Article'
  dek?: string
  articleContent: IRichText
}

interface Podcast {
  __typename: 'Podcast'
  description?: IRichText
}

interface PodcastEpisode {
  __typename: 'PodcastEpisode'
  dek?: string
  description?: IRichText
  podcastContent: IRichText
}

export const getDescription = (
  content: Article | Podcast | PodcastEpisode
) => {
  let description = ''

  switch (content.__typename) {
    case 'Article': {
      if (content.dek) {
        description = content.dek
        break
      }
      if (content.articleContent?.json) {
        const toPlainText = documentToPlainTextString(content.articleContent.json)
        description = toPlainText.substring(0, 240)
      }
      break
    }

    case 'PodcastEpisode': {
      if (content.dek) {
        description = content.dek
        break
      }
      if (content.description?.json || content.podcastContent?.json) {
        const toPlainText = documentToPlainTextString(
          content.description?.json || content.podcastContent?.json
        )
        description = toPlainText.substring(0, 240)
      }
      break
    }

    case 'Podcast': {
      if (content.description?.json) {
        const toPlainText = documentToPlainTextString(content.description.json)
        description = toPlainText.substring(0, 240)
      }
      break
    }

    default:
      return ''
  }

  return description
}
