import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGdocuments = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45 50H5V0H45V50ZM40 45H10V5H40V45ZM23.5002 25.7827H23.4792C22.3032 25.7777 21.2012 25.3147 20.3732 24.4787L14.9922 19.0977L18.5272 15.5627L23.5002 20.5347L33.5432 10.5017L37.0762 14.0387L26.5482 24.5557C25.7162 25.3517 24.6412 25.7827 23.5002 25.7827ZM15 39.99H35V34.99H15V39.99Z'
      />
    </svg>
  )
}

export default SVGdocuments
