const MEMBERS_PATH: string = `${process.env.NEXT_PUBLIC_API_DOMAIN}/members/v1/users/:userId`

export default async function userPreferencesHandler(
  { userId, authHeader }: { userId: string, authHeader: string }
): Promise<any> {
  if (!userId || !authHeader) return null

  const response = await fetch(
    MEMBERS_PATH.replace(':userId', userId),
    { headers: { Authorization: `Bearer ${authHeader}` } }
  )
  return response.json()
}
