import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGfacebook = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.025 8.303L38.9 8.3V.35C38.057.243 35.163 0 31.795 0c-7.03 0-11.843 4.143-11.843 11.75v6.555H12v8.887h7.953V50h9.51V27.192h7.93l1.187-8.887h-9.117V12.63c0-2.573.74-4.328 4.562-4.328z'
      />
    </svg>
  )
}

export default SVGfacebook
