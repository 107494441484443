// @ts-nocheck
import * as PropTypes from 'prop-types'
import React from 'react'
import { IContentfulBlock } from './types'

/** UnorderedList component would map to contentful UL_LIST */
function UnorderedList ({ children }: IContentfulBlock) {
  return (
    <ul data-testid='unorderedList' className='text-foreground-darkblue'>
      {children}
    </ul>
  )
}

UnorderedList.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default UnorderedList
