import SVGdownload from '@/journey/Svgs/icons/download'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { useContext } from 'react'
import { downloadChatHistory } from './utils'

export default function DownloadChatButton() {
  const { trackClick } = useContext(AnalyticsContext)

  const onClick = () => {
    trackClick({
      buttonName: 'assistant:download_chat_history',
      buttonText: 'assistant:download_chat_history',
      unitName: 'assistant_interface',
      unitLocation: 'assistant',
      componentId: '00000004',
      clickType: 'button'
    })

    // note: using the value from useLocalStorage does not work here
    const messages = localStorage.getItem('message_history')
    const parsedMessages = messages ? JSON.parse(messages) : []
    const responses = localStorage.getItem('onboarding_responses')
    const parsedResponses = responses ? JSON.parse(responses) : []
    const responseMessages = parsedResponses.map((r) => ({ role: 'user' as 'user', content: r, id: Date.now().toString() }))
    const allMessages = [...responseMessages, ...parsedMessages]
    downloadChatHistory(allMessages)
  }

  return (
    <button
      type='button'
      aria-label='Download chat history'
      className='text-foreground-darkblue Btn-link flex-sm-align-center flex-sm-justify-center icon-hover no-print target'
      onClick={onClick}
    >
      <SVGdownload className='vicon' />
    </button>
  )
}
