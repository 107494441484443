import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import communityThemeTokens from '@understood/emblem/dist/community/theme.json'
import communityThemeObject from '@understood/emblem/dist/community/themeobject.json'
import darkThemeObject from '@understood/emblem/dist/dark-theme/theme-object.json'
import darkThemeTokens from '@understood/emblem/dist/dark-theme/theme.json'
import useCustomDarkMode from '@common/components/useCustomDarkMode'
import { ThemeContextProviderProps } from './ThemeContextProvider.types'

const darkTheme = { ...darkThemeTokens, ...darkThemeObject }
const communityTheme = { ...communityThemeTokens, ...communityThemeObject }

function ThemeContextProvider({ children }: ThemeContextProviderProps) {
  const [currentTheme, setCurrentTheme] = useState(communityTheme)
  const darkmode = useCustomDarkMode()

  useEffect(() => {
    setCurrentTheme(darkmode.value ? darkTheme : communityTheme)
  }, [darkmode.value])

  return (
    <ThemeProvider theme={currentTheme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeContextProvider
