import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { UserContext } from '@context/UserContext'
import SVGbullet from '@fractal/primitives/SVGs/icons/bullet'
import React, { useContext } from 'react'
import { StyledDropdown } from './styled'
import { IDropDown } from './types'

const Dropdown = ({ dropdownUrl, dropdownTitle }: IDropDown) => {
  const { logoutUser } = useContext(UserContext)
  const { trackNavigation } = useContext(AnalyticsContext)

  const logOut = () => {
    logoutUser()
    trackNavigation({
      eventType: 'click',
      buttonName: 'log_out',
      unitName: 'main_menu',
      unitLocation: 'header'
    })
  }
  return (
    <StyledDropdown>
      <ul className='list-reset'>
        <li>
          <Link
            className='no-underline text-foreground-creamLite hover-text-static-scarletLite d-sm-block py-sm-2 pl-sm-3 pr-sm-5'
            href={dropdownUrl}
            onClick={() => {
              trackNavigation({
                eventType: 'click',
                buttonName: 'library',
                unitName: 'main_menu',
                unitLocation: 'header'
              })
            }}
          >
            <SVGbullet className='vicon' width='9' height='9' />
            <span className='pl-sm-1 text-foreground-darkblue'>
              {dropdownTitle}
            </span>
          </Link>
        </li>
        <li>
          <button
            onClick={logOut}
            className='Btn-link no-underline text-foreground-creamLite hover-text-static-scarletLite d-sm-block py-sm-2 pl-sm-3 pr-sm-6'
          >
            <SVGbullet className='vicon' width='9' height='9' />
            <span className='pl-sm-1 text-bold text-foreground-darkblue'>
              Log Out
            </span>
          </button>
        </li>
      </ul>
    </StyledDropdown>
  )
}

export { Dropdown }
export default Dropdown
