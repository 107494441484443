// @ts-nocheck
import * as React from 'react'
import { IHeaderState } from './types'

const defaultHeaderContext: IHeaderState = {
  sidebarOpen: false,
  searchbarOpen: false,
  searchDisabled: false,
  transparentBackground: false,
  showAccount: true,
  wave: true,
  overlay: true,
  showOverlay: (bool: boolean) => !bool,
  toggleSearchBar: (bool: boolean) => !bool,
  toggleSideBar: (bool: boolean) => !bool
}

const HeaderContext = React.createContext(defaultHeaderContext)

export default HeaderContext
