import { useEffect, useRef, useState } from 'react'

const parseValue = value => {
  if (typeof value !== 'string') return value

  try {
    return JSON.parse(value)
  } catch {
    return undefined
  }
}

const stringifyValue = value => {
  if (typeof value === 'string') return value

  try {
    return JSON.stringify(value)
  } catch {
    return ''
  }
}

const isInBrowser = typeof window === 'object'

type UpdateValueType<T = void> = ((value: T | undefined) => T) | T

export function useLocalStorage<D = void>(
  key: string,
  defaultValue: D,
  storageType: 'session' | 'local' = 'local'
): [D | undefined, (newValue: UpdateValueType<D>) => void, () => void] {
  const lastKey = useRef(key)

  const getStorage = () => {
    if (!isInBrowser) return undefined
    if (storageType === 'session') return sessionStorage
    return localStorage
  }

  const savedValue = isInBrowser && getStorage()?.getItem(key)
  const [value, setValue] = useState<D | undefined>(
    parseValue(savedValue) ?? defaultValue
  )

  const updateValue = (newValue: UpdateValueType<D>) => {
    setValue(prevValue => {
      let evaluatedValue = newValue
      if (typeof newValue === 'function') {
        evaluatedValue = (newValue as (value: D | undefined) => D)(prevValue)
      }

      const parsedValue = parseValue(evaluatedValue)

      // == null (double =) tests for either null or undefined
      if (newValue == null || parsedValue == null) {
        removeValue()
        return undefined
      }

      isInBrowser && getStorage()?.setItem(key, stringifyValue(parsedValue))
      return parsedValue
    })
  }

  const removeValue = () => {
    isInBrowser && getStorage()?.removeItem(key)
    setValue(undefined)
  }

  useEffect(() => {
    if (!isInBrowser || storageType !== 'local') return

    const handleStorageChange = event => {
      if (event.key === key) {
        updateValue(event.newValue)
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (key !== lastKey.current) {
      lastKey.current = key
      const savedValue = getStorage()?.getItem(key)
      setValue(parseValue(savedValue) ?? defaultValue)
    }
  }, [key])

  return [value, updateValue, removeValue]
}
