import React, { useEffect, useRef } from 'react'
import { useFormControlContext } from '../FormControl'

import { LabelProps } from './Label.interface'
import styles from './Label.module.scss'

const marginSizeMap = {
  none: 0,
  default: 3,
  medium: 5,
  large: 4
}

const weightMap = {
  default: 'text-bold',
  normal: 'text-regular'
}

const fontSizeMap = {
  default: 2,
  medium: 3,
  large: 4,
  xlarge: 5
}

const Label = ({ ...props }: LabelProps) => {
  const { control, updateContext } = useFormControlContext()

  const myref = useRef<HTMLElement>()

  useEffect(() => {
    if (myref?.current) {
      updateContext({ labelPortal: myref.current })
    }
  }, [myref])

  const showRequiredElement = props.required || control?.required
  let labelType = props.labelType

  if ((control as any)?.type === 'checkbox' || (control as any)?.type === 'radio') {
    labelType = (control as any)?.checkboxType || (control as any)?.radioType || labelType
  }

  const clickable = (control as any)?.type === 'checkbox'
    || (control as any)?.type === 'radio'
    || labelType === 'emoji'

  const marginSize = clickable || labelType === 'emoji' ? 'none' : props.marginSize
  const fontWeight = labelType === 'switcher' ? 'default' : clickable ? 'normal' : props.weight
  const fontSize = clickable ? 'medium' : props.size

  let dynamicLabelClass = `mb-sm-${marginSizeMap[marginSize]} ${weightMap[fontWeight]} f-sm-${fontSizeMap[fontSize]}`
  if (clickable) {
    dynamicLabelClass += ` ${styles.clickable}`
  }
  if (labelType !== 'emoji' && labelType !== 'switcher') {
    dynamicLabelClass += labelType === 'pill' ? ' d-sm-block' : ' d-sm-inline-block'
  }
  if (labelType === 'switcher') {
    dynamicLabelClass += ' d-sm-inline-flex flex-sm-row-reverse flex-sm-align-center lh-reset'
  }

  const spanClass = labelType === 'pill' ? 'd-sm-block' :
    labelType === 'switcher' ? 'ml-sm-3 p-sm-relative' : ''

  return (
    <label
      {...props}
      htmlFor={props.htmlFor || control?.id}
      className={`p-sm-relative text-foreground-darkblue ${dynamicLabelClass} ${props.className}`}
    >
      <span className={spanClass} ref={myref as any}></span>
      {props.children}
      {showRequiredElement && (
        <span aria-label='required' className='text-static-scarletTint'>*</span>
      )}
    </label>
  )
}

Label.defaultProps = {
  size: 'default',
  marginSize: 'default',
  weight: 'default',
  required: false,
  labelType: 'default'
}

export default Label
