import OldFooter from '@fractal/features/Footer/Footer'
import Header from '@fractal/features/Header'
import { ContentfulContext } from 'components/shared/providers'
import get from 'lodash/get'
import dynamic from 'next/dynamic'
import React, { ReactNode, useContext } from 'react'
import TopNav from '../TopNav/TopNav.client'
import Footer from '../Footer/Footer.client'

const Account = dynamic(() => import('@fractal/features/Accounts/Account'), {
  ssr: false
})

const NotificationBar = dynamic(
  () => import('@fractal/features/NotificationBar'),
  {
    ssr: false
  }
)

const WunderModal = dynamic(
  () => import('@fractal/features/WunderV2/WunderModal/WunderModal'),
  {
    ssr: false
  }
)

const GivingTuesdayModal = dynamic(
  () => import('components/articles/Experiments/GivingTuesday/GivingTuesdayModal'),
  {
    ssr: false
  }
)

const DonationModal = dynamic(
  () => import('components/articles/Experiments/DonateBanner/DonationModal'),
  {
    ssr: false
  }
)

const CallOutBanner = dynamic(
  () => import('@fractal/features/CallOutBanner/CallOutBanner'),
  {
    ssr: false
  }
)

interface ILayout {
  isDynamic: boolean
  layoutConfig?: {
    header?: {
      searchAlwaysOpened?: boolean
      darkBlueBackground?: boolean
      customClass?: string
      showDonateCta?: boolean
    }
    hideCallOutBanner?: boolean
    hideNotification?: boolean
    hideWunderModal?: boolean
    showTuesdayModals?: boolean
    hideFooter?: boolean
    showAiNav?: boolean
  }
  children: ReactNode
  layoutComponentProps?: any[]
}

function Layout({
  children, isDynamic, layoutConfig, layoutComponentProps = []
}: ILayout) {
  const { getPropsByType } = useContext(ContentfulContext)

  if (isDynamic) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  const footerProps = layoutComponentProps.find((c) => get(c, '__typename') === 'ComponentFooter')
  const topNavProps = layoutComponentProps.find((c) => get(c, '__typename') === 'ComponentTopNav')

  return (
    <>
      {!layoutConfig?.hideNotification && (
        <NotificationBar
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getPropsByType('NotificationBar')}
        />
      )}
      {!layoutConfig?.hideWunderModal && (
        <WunderModal
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getPropsByType('WunderModal')}
        />
      )}
      {layoutConfig?.showTuesdayModals && (
        <>
          <GivingTuesdayModal />
          <DonationModal />
        </>
      )}

      <div className={layoutConfig?.header?.customClass}>
        {topNavProps?.sys?.id && (
          <TopNav
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...topNavProps}
            showDonateButton={false}
            transparentBackground={!layoutConfig?.header?.darkBlueBackground}
            showAiNav={layoutConfig?.showAiNav}
          />
        )}
        {!topNavProps?.sys?.id && (
          <Header
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getPropsByType('Header')}
            transparentBackground={!layoutConfig?.header?.darkBlueBackground}
            searchAlwaysOpened={!!layoutConfig?.header?.searchAlwaysOpened}
            showCTA={layoutConfig?.header?.showDonateCta ?? true}
          />
        )}
      </div>
      <div className='post-header' />
      {children}
      <div className='no-print'>
        {!layoutConfig?.hideCallOutBanner && (
          <CallOutBanner
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getPropsByType('CallOutBanner')}
          />
        )}
        {!footerProps?.sys?.id && !layoutConfig?.hideFooter && (
          <OldFooter
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getPropsByType('Footer')}
          />
        )}
        {footerProps?.sys?.id && (
          <Footer
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...footerProps}
          />
        )}
        <Account
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getPropsByType('Account')}
        />
      </div>
      <p className='m-sm-4 d-sm-none d-block-print'>
        Copyright © 2014-
        {new Date().getFullYear()}
        {' '}
        Understood For All Inc.
        {' '}
      </p>
    </>
  )
}

export default Layout
