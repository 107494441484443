import { Message } from 'ai'
import POSSIBLE_QUESTIONS from './sample_questions.json'
import POSSIBLE_CONCERNS from './wunder_concerns.json'

export const createFileForExport = (messages: Message[]) => {
  // create or obtain the file's content
  const content = messages
    .filter((m) => m.role === 'user' || m.role === 'assistant')
    .map((m) => `${m.role === 'user' ? 'You' : 'Assistant'}\n${m.content}\n\n`)
    .join('')

  // create a file and put the content, name and type
  const file = new File([`\ufeff${content}`], 'chatHistory.txt', { type: 'text/plain:charset=UTF-8' })

  // create a ObjectURL in order to download the created file
  const url = typeof window === 'object' ? window.URL.createObjectURL(file) : ''

  return { url, file }
}

export const downloadChatHistory = (messages: Message[]) => {
  const { url, file } = createFileForExport(messages)

  // create a hidden link and set the href and click it
  const a = document.createElement('a')
  a.href = url
  a.download = file.name
  a.click()
  window.URL.revokeObjectURL(url)
}

const STEPS = {
  concerns: POSSIBLE_CONCERNS,
  questions: POSSIBLE_QUESTIONS
}

export const getAllOptions = (forStep: string) => {
  const obj: Record<string, string[]> = STEPS[forStep]
  const allOptions: string[] = []
  Object.keys(obj).forEach((key) => {
    obj[key].forEach((q) => {
      allOptions.push(q)
    })
  })
  return allOptions
}

export const prettifyUrlParamValue = (param?: string) => param?.replace(/-/g, ' ') || ''

export const getQuestionsFromParam = (param?: string) => {
  // e.g. Reading and writing --> reading-and-writing
  const parsedParam = param?.toLowerCase().replace(/ /g, '-') || ''
  // choose from the possible questions
  const obj: Record<string, string[]> = POSSIBLE_QUESTIONS
  if (parsedParam && obj[parsedParam].length > 0) {
    return obj[parsedParam]
  }
  return getAllOptions('questions')
}

export const getConcernsFromParam = (param?: string) => {
  const parsedParam = param?.toLowerCase().replace(/ /g, '-') || ''
  // choose from the possible concerns
  const obj: Record<string, string[]> = POSSIBLE_CONCERNS
  if (parsedParam && obj[parsedParam].length > 0) {
    // return the concerns for the parsed param...
    return obj[parsedParam]
  }
  // ...or, return all concerns
  return getAllOptions('concerns')
}

const getRandomOptions = (arr: string[], limit: number = 3) => {
  const used: Array<number> = []
  const options: string[] = []

  if (arr.length <= limit) {
    return arr
  }
  while (options.length < limit) {
    const newIdx = Math.floor(Math.random() * arr.length)
    if (!used.includes(newIdx)) {
      options.push(arr[newIdx])
      used.push(newIdx)
    }
  }
  return options
}

export const getRandomQuestions = (param: string, limit: number = 3) => {
  const arr = getQuestionsFromParam(param)
  const questions: string[] = getRandomOptions(arr, limit)

  return questions
}

export const getRandomConcerns = (param: string, limit: number = 3) => {
  const arr = getConcernsFromParam(param)
  const conerns: string[] = getRandomOptions(arr, limit)

  return conerns
}
