import React from 'react'
import { IAppContext } from './types'

const DefaultAppContext: IAppContext = {
  deviceType: '',
  deviceOS: '',
  pageTitle: 'Understood',
  contentId: '',
  basePath: '',
  siteSection: '',
  program: ''
}


const AppContext = React.createContext(DefaultAppContext)

const { Provider, Consumer } = AppContext

export { Consumer as AppConsumer, Provider as AppProvider, DefaultAppContext }

export default AppContext
