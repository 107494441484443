import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGglobe = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M10 0C4.48591 0 0 4.48591 0 10C0 15.5141 4.48591 20 10 20C15.5141 20 20 15.5141 20 10C20 4.48591 15.5141 0 10 0ZM16.7998 5.45455H14.0684C13.8164 4.3125 13.4661 3.26341 13.0214 2.39659C14.5682 3.01341 15.8839 4.08909 16.7998 5.45455ZM10 1.81818C10.7202 1.81818 11.6514 3.15545 12.2182 5.45455H7.78182C8.34864 3.15545 9.27977 1.81818 10 1.81818ZM2.28614 12.7273C1.98364 11.8739 1.81818 10.9559 1.81818 10C1.81818 9.04409 1.98364 8.12614 2.28614 7.27273H5.62159C5.51068 8.16841 5.45455 9.08955 5.45455 10C5.45455 10.9105 5.51068 11.8316 5.62159 12.7273H2.28614ZM3.20023 14.5455H5.93136C6.18341 15.6875 6.53364 16.7366 6.97841 17.6034C5.43182 16.9866 4.11614 15.9109 3.20023 14.5455ZM5.93159 5.45455H3.20023C4.11614 4.08909 5.43182 3.01341 6.97841 2.39659C6.53386 3.26341 6.18341 4.3125 5.93159 5.45455ZM10 18.1818C9.27977 18.1818 8.34864 16.8445 7.78182 14.5455H12.2182C11.6514 16.8445 10.7202 18.1818 10 18.1818ZM12.5557 12.7273H7.44432C7.33545 11.895 7.27273 10.9827 7.27273 10C7.27273 9.01727 7.33545 8.105 7.44432 7.27273H12.5555C12.6645 8.105 12.7273 9.01727 12.7273 10C12.7273 10.9827 12.6645 11.895 12.5557 12.7273ZM13.0214 17.6034C13.4661 16.7366 13.8164 15.6875 14.0684 14.5455H16.7995C15.8839 15.9109 14.5682 16.9866 13.0214 17.6034ZM18.1818 10C18.1818 10.9559 18.0164 11.8739 17.7139 12.7273H14.3784C14.4893 11.8316 14.5455 10.9105 14.5455 10C14.5455 9.08955 14.4893 8.16841 14.3784 7.27273H17.7139C18.0164 8.12614 18.1818 9.04409 18.1818 10Z' />
    </svg>
  )
}

export default SVGglobe
