// @ts-nocheck
import React from 'react'
import { IAnchorTag } from './types'

function AnchorTag ({ id }: IAnchorTag) {
  if (!id) throw new Error('Missing required id')
  if (typeof id !== 'string') throw new TypeError('Id can only be a string')
  return <a data-testid='anchortag' id={id} />
}

export default AnchorTag
