import Image from 'next/legacy/image'
import React from 'react'
import { NextImageProps } from './NextImage.interface'

const NextImage = ({ ...props }: NextImageProps) => {
  if (!props.src) return null

  const customLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`
  }

  return <Image data-testid='nextimageid' loader={customLoader} {...props} />
}

export default NextImage
