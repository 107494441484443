// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulBlock } from './types'

/** Heading5 component would map to contentful Heading_5 */
function Heading5 ({ children }: IContentfulBlock) {
  return (
    <h5
      className=' text-foreground-darkblue f-sm-2 f-md-3 pb-sm-1 pb-md-2'
      data-testid='heading5'
    >
      {children}
    </h5>
  )
}

Heading5.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Heading5
