// @ts-nocheck
import React from 'react'
import * as PropTypes from 'prop-types'
import { IContentfulMark } from './types'

/** Bold component would map to contentful Bold */
function Bold ({ children, type = 'bold' }: IContentfulMark) {
  return (
    <strong data-testid='bold' className=' text-foreground-darkblue'>
      {children}
    </strong>
  )
}

Bold.propTypes = {
  /** Renders children of any type that can be rendered */
  children: PropTypes.node.isRequired
}

export default Bold
