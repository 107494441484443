import { CustomStatsigContext } from '@context/CustomStatsigContext'
import { useContext, useEffect, useState } from 'react'
import Features from 'components/shared/constants/features'

interface IConfig {
  mode?: 'ssr' | 'client' | 'layer'
}

export default function useIsFeatureEnabled(
  feature: typeof Features[keyof typeof Features],
  config?: IConfig
): boolean {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false)
  const {
    checkGate,
    checkStatsigGate,
    isInitialized
  } = useContext(CustomStatsigContext)

  useEffect(() => {
    if (!isInitialized) return
    let featureEnabled = false
    if (config?.mode === 'layer') {
      featureEnabled = checkGate(feature)
    } else {
      featureEnabled = checkStatsigGate(feature, config?.mode === 'ssr')
    }
    setIsFeatureEnabled(featureEnabled)
  }, [isInitialized])

  return isFeatureEnabled
}
