/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect } from 'react'
import styles from './UserLogin.module.scss'

function Mask({ handleClick }: any) {
  useEffect(() => {
    document.body.classList.add(styles.noVerticalScroll)
    return () => document.body.classList.remove(styles.noVerticalScroll)
  }, [])

  return (
    <div
      className={styles.mask}
      onClick={handleClick}
    />
  )
}

export default Mask
