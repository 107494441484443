// @ts-nocheck
import jwtDecode from 'jwt-decode'
import cloneDeep from 'lodash/cloneDeep'
import { last } from 'ramda/src/last'
import { pathOr } from 'ramda/src/pathOr'
import { v4 as uuidV4 } from 'uuid'
export * from './customHooks'

export const isBrowser = typeof window === 'object'

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    console.log({ category, action })
  }
}

export const logException = (description = '', fatal = false) => {
  if (description) {
    console.error({ description, fatal })
  }
}

export const isExternalLink = (url: string) => {
  return url?.startsWith('http') && !url?.includes('localhost')
}
const extractImageData = image => {
  // const { fields: { file }} = image
  return {
    url: pathOr('', ['fields', 'file', 'url'], image),
    width: parseFloat(
      pathOr('', ['fields', 'file', 'details', 'image', 'width'], image)
    ),
    height: parseFloat(
      pathOr('', ['fields', 'file', 'details', 'image', 'height'], image)
    )
  }
}

export const transformArticle = (response: any) => {
  const article = last(response.items)
  return {
    id: article.sys.id,
    ...article.fields,
    heroImage: {
      imageFile: extractImageData(article.fields.heroImage.fields.imageFile),
      altText: article.fields.heroImage.fields.altText
    },
    thumbnail: extractImageData(article.fields.thumbnail),
    author: { ...article.fields.author.fields },
    reviewer: { ...article.fields.reviewer.fields },
    breadcrumb: { ...article.fields.breadcrumb.fields }
  }
}
const routeMap = new Map()

routeMap.set('landingPage', '7uOzGWxXscBO6V0rPGgO1C')
routeMap.set('notice', '4mvoWOzMmoNgUhlLWEgEVm')
routeMap.set('observe', '1Wc2imkUeelKyZhjYu9Lx')
routeMap.set('talk', 'TXnT4X6LzF952L1BasdCo')
routeMap.set('engage', '4EH72P9DOVfsMvUzihtD2i')

interface IClipboardProps {
  url: string
  onCopy: () => void
}

export const copyToClipboard = async ({ url, onCopy }: IClipboardProps) => {
  try {
    isBrowser && (await window.navigator.clipboard.writeText(url))
    onCopy && onCopy()
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

export const transformAndDecodeToken = token => {
  const data = jwtDecode(token)
  return {
    error: false,
    is_logged_in: true,
    email: data?.email,
    first_name: data['https://u.org/firstName'],
    id: data?.sub,
    picture: data?.picture,
    screen_name: data['https://u.org/screenName']
  }
}

export const initialUserState = {
  id: uuidV4(),
  error: false,
  is_logged_in: false
}

export const shuffleTags = tags => {
  // We use the Fisher-Yates algorithm to shuffle tags in the FilterButtonToggle.
  let tagsClone = cloneDeep(tags)
  for (let i = tagsClone.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
      ;[tagsClone[i], tagsClone[j]] = [tagsClone[j], tagsClone[i]]
  }
  return tagsClone
}

export const lowerCaseAndUnderscore = (str: any): any => {
  if (!str) {
    return str
  }
  return str
    .toLowerCase()
    .replaceAll('-', '_')
    .replaceAll(/[.,&;()]/g, '')
    .replaceAll(' ', '_')
    .replaceAll('__', '_')
}

export { routeMap }
