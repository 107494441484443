import { UserContext } from '@context/UserContext'
import SVGbullet from '@fractal/primitives/SVGs/icons/bullet'
import useTranslation from 'app/i18n/client'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'
import React, { useContext } from 'react'

function Dropdown({ sys }: any) {
  const { logoutUser } = useContext(UserContext)
  const { t } = useTranslation()

  const logOut = () => {
    logoutUser()
  }
  return (
    <div
      id='dropdown-account'
      data-testid='dropdownAccount'
      className='arrow-up p-sm-absolute shadow rounded-sm-1 ml-sm-n6 text-nowrap z-index-3'
    >
      <ul className='list-reset'>
        <li>
          <TrackClick
            className='no-underline text-foreground-creamLite hover-text-static-scarletLite d-sm-block py-sm-2 pl-sm-3 pr-sm-5'
            href='/hub'
            trackProps={{
              clickType: 'button',
              buttonName: 'library',
              buttonText: t('my-library'),
              unitName: 'main_menu',
              unitLocation: 'header',
              componentId: sys.id
            }}
          >
            <SVGbullet className='vicon' width='9' height='9' />
            <span className='pl-sm-1 text-foreground-darkblue'>
              {t('my-library')}
            </span>
          </TrackClick>
        </li>
        <li>
          <TrackClick
            as='button'
            type='button'
            onClick={logOut}
            className='Btn-link no-underline text-foreground-creamLite hover-text-static-scarletLite d-sm-block py-sm-2 pl-sm-3 pr-sm-6'
            trackProps={{
              clickType: 'button',
              buttonName: 'log_out',
              buttonText: t('logout'),
              unitName: 'main_menu',
              unitLocation: 'header',
              componentId: sys.id
            }}
          >
            <SVGbullet className='vicon' width='9' height='9' />
            <span className='pl-sm-1 text-bold text-foreground-darkblue'>
              {t('logout')}
            </span>
          </TrackClick>
        </li>
      </ul>
    </div>
  )
}

export default Dropdown
