import Experiments from 'components/shared/constants/experiments'
import Features from 'components/shared/constants/features'
import React from 'react'
import { DynamicConfig } from 'statsig-js'

type Gate = typeof Features[keyof typeof Features]
type Experiment = typeof Experiments[keyof typeof Experiments]

export interface ICustomStatsigProviderParams {
  children?: React.ReactNode
  statsigConfig?: {
    experimentsConfig?: { [key: string]: any }
    gatesConfig?: { [key: string]: boolean }
  }
}

export type RegisterFuncReturn = {
  gate?: Gate
  experiment?: Experiment
  layer?: string
  isEnabledInArticle?: boolean | (() => boolean)
}

export interface ICustomStatsigContext {
  isInitialized: boolean
  isUpdating: boolean
  getExperiment(experimentName: Experiment): DynamicConfig | undefined
  getStatsigExperiment(experimentName: Experiment, isSSR?: boolean): DynamicConfig | undefined
  checkGate(configName: Gate): boolean | undefined
  checkStatsigGate(configName: Gate, isSSR?: boolean): boolean | undefined
  logEvent(eventName: string, value?: any, metadata?: any): void
  registerCustomData(key: string, fn: () => Promise<RegisterFuncReturn>): void
  experiments?: { [key in Experiment]?: DynamicConfig }
  gates?: { [key in Gate]?: boolean }
}

export const GATE_LAYERS = {
  NO_LAYER: 'no_layer',
  ARTICLE_BODY: 'article_body_layer'
}
