import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGarrowRight = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.9998 40L33.5078 43.5L48.5438 28.521C50.4888 26.574 50.4848 23.412 48.5348 21.473L33.5078 6.5L29.9998 10.001L42.5029 22.5H0V27.5H42.5448L29.9998 40ZM45 25.0537L45.0288 25.025L45 24.9963V25.0537Z'
      />
    </svg>
  )
}

export default SVGarrowRight
