import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGdownload = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='download-svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.5 32.503V0.00012207H22.5V32.545L10 19.9999L6.5 23.5079L21.479 38.5439C23.426 40.4889 26.588 40.4849 28.527 38.5349L43.5 23.5079L39.999 19.9999L27.5 32.503ZM0 50H50V45H0V50Z'
      />
    </svg>
  )
}

export default SVGdownload
