// @ts-nocheck
import React from 'react'
import { IHorizontalRule } from './types'

/** HorizontalRule component would map to contentful HR */
function HorizontalRule ({}: IHorizontalRule) {
  return (
    <hr
      data-testid='horizontalRule'
      className='mt-sm-3 mb-sm-5 border-static-grey3 border-sm-top-0'
    />
  )
}

export default HorizontalRule
