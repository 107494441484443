import { AnalyticsContext } from '@context/AnalyticsContext'
import ReactSidebar from '@fractal/features/Header/ReactSidebar'
import SVGunderstood from '@fractal/primitives/SVGs/logos/understood'
import useTranslation from 'app/i18n/client'
import DownloadChatButton from 'components/assistant/DownloadChatButton'
import StartOverButton from 'components/assistant/StartOverButton'
import TrackClick from 'components/dynamic-page/shared/components/TrackClick/TrackClick'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { ContentfulContext } from 'components/shared/providers/ContentfulProvider'
import React, { useContext } from 'react'
import useIsFeatureEnabled from 'hooks/useIsFeatureEnabled'
import Features from 'components/shared/constants/features'
import styles from './TopNav.module.scss'
import { IComponentTopNav } from './TopNav.type'
import AriaLocale from './components/AriaLocale/AriaLocale'
import HeaderProvider from './components/HeaderProvider/HeaderProvider'
import MenuButton from './components/MenuButton/MenuButton'
import SearchBar from './components/SearchBar/SearchBar'
import SearchButton from './components/SearchButton/SearchButton'
import UserLogin from './components/UserLogin/UserLogin'
import AssistantIcon from './components/AssistantIcon/AssistantIcon'

function TopNav({
  sys,
  disableSearch = false,
  isTakeNote = false,
  showAccount = true,
  searchAlwaysOpened = false,
  showDonateButton = true,
  navLinksCollection,
  transparentBackground,
  showAiNav = false
}: IComponentTopNav) {
  const { trackImpression } = useContext(AnalyticsContext)
  const { getPropsByType } = useContext(ContentfulContext)
  const { t } = useTranslation()

  const onFocusSkip = () => {
    trackImpression({
      unitName: 'main_menu',
      buttonName: 'skip_to_content',
      unitLocation: 'header',
      componentId: sys.id
    })
  }

  let background = ''
  if (isTakeNote) background = 'bg-background-high-darkblue'
  if (!transparentBackground) background = 'bg-static-darkblue'
  const sidebarProps = getPropsByType('Header')
  const skipToContent = t('skip-to-content')
  const donateText = t('donate')
  const isAssistantEnabled = useIsFeatureEnabled(Features.ChatBotNavBar)

  return (
    <HeaderProvider
      searchAlwaysOpened={searchAlwaysOpened}
    >
      <>
        <TrackClick
          as='a'
          href='#target-skip-to-content'
          className='Btn Btn--small Btn--outline no-underline p-sm-absolute sr-only-focusable m-sm-2'
          onFocus={onFocusSkip}
          trackProps={{
            clickType: 'button',
            unitName: 'main_menu',
            buttonName: 'skip_to_content',
            buttonText: skipToContent,
            unitLocation: 'header',
            componentId: sys.id
          }}
        >
          {skipToContent}
        </TrackClick>

        <AriaLocale />

        <header
          data-testid='page-topnav'
          aria-label='Header'
          className={`${background} px-sm-4 px-lg-5 py-sm-4`}
        >
          <TrackImpression
            componentId={sys.id}
            unitName={sys.id}
            unitLocation='inline'
          />
          <nav className='d-sm-flex flex-sm-align-center flex-sm-justify-between' aria-label='Main'>
            <div className='d-sm-flex flex-sm-align-center'>
              <TrackClick
                data-testid='iconHeader-understood-home-link'
                className={`${transparentBackground ? 'text-foreground-darkblue' : 'text-static-white'}`}
                href='/'
                aria-label='Understood Home'
                trackProps={{
                  clickType: 'button',
                  buttonName: 'understood_home',
                  unitName: 'main_menu',
                  unitLocation: 'header',
                  componentId: sys.id
                }}
              >
                <SVGunderstood className='d-sm-block target vicon' />
              </TrackClick>
            </div>

            <ul className={`d-sm-none d-lg-flex list-reset my-sm-0 ${styles.navLinks}`}>
              {navLinksCollection?.items?.map((navLink, index) => {
                let url
                let id

                if ('url' in navLink) {
                  url = navLink.url
                } else {
                  url = getContentUrl(navLink.linkTo)
                  id = navLink.linkTo?.sys?.id
                }

                return (
                  <li key={navLink.sys.id}>
                    <TrackClick
                      href={url}
                      className={`primary-link no-underline f-sm-4 text-regular
                        ${transparentBackground ? 'text-foreground-darkblue' : 'text-static-white'}`}
                      trackProps={{
                        clickType: 'text',
                        isContent: true,
                        linkText: navLink.label,
                        linkedContentId: id,
                        linkedContentUrl: url,
                        unitName: sys.id,
                        unitLocation: 'footer',
                        positionInUnit: index + 1,
                        componentId: sys.id
                      }}
                    >
                      {navLink.label}
                    </TrackClick>
                  </li>
                )
              })}
            </ul>

            <div className='d-sm-flex'>
              {showDonateButton && (
                <div className='mr-sm-5 d-sm-none d-md-block no-print'>
                  <TrackClick
                    as='a'
                    className='Btn Btn--primary Btn--small no-underline d-sm-inline-block bg-static-scarletTint'
                    data-testid='iconHeader-donate-button'
                    href='/donate'
                    target='_blank'
                    trackProps={{
                      clickType: 'button',
                      unitName: 'main_menu',
                      unitLocation: 'header',
                      isExternal: true,
                      linkedContentUrl: '/donate',
                      buttonName: 'donate',
                      buttonText: donateText,
                      componentId: sys.id
                    }}
                  >
                    {donateText}
                    <span className='sr-only'>Opens new window</span>
                  </TrackClick>
                </div>
              )}

              {!disableSearch && !isAssistantEnabled && (
                <SearchButton
                  sys={sys}
                  disableSearch={disableSearch}
                  searchAlwaysOpened={searchAlwaysOpened}
                  transparentBackground={transparentBackground}
                />
              )}
              {isAssistantEnabled && (
                <AssistantIcon transparentBackground={transparentBackground} sysId={sys.id} />
              )}

              {showAccount && (
                <UserLogin sys={sys} transparentBackground={transparentBackground} />
              )}

              {showAiNav && (
                <>
                  <DownloadChatButton />
                  <StartOverButton />
                </>
              )}

              <MenuButton
                sys={sys}
                transparentBackground={transparentBackground}
              />
            </div>
          </nav>
        </header>

        <SearchBar
          searchAlwaysOpened={searchAlwaysOpened}
          sys={sys}
          transparentBackground={transparentBackground}
        />

        <ReactSidebar
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sidebarProps}
          links={sidebarProps?.sidebarLinks || []}
          searchAlwaysOpened={searchAlwaysOpened}
        />
      </>
    </HeaderProvider>
  )
}

export default TopNav
