import React from 'react'
import { ILanguageContext, Languages } from './types'

const DefaultLanguageContext: ILanguageContext = {
  language: Languages.EN,
  changeLanguage: (language: Languages): void => { },
  enableLocaleSwitcher: false,
  slug: '/',
  slugEn: '/',
  slugEs: '/',
  langAbbrev: 'en',
}

const LanguagePathMap = new Map()

LanguagePathMap.set(Languages.EN, 'en')
LanguagePathMap.set(Languages.ES, 'es-mx')

const LanguageContext = React.createContext(DefaultLanguageContext)

const { Provider, Consumer } = LanguageContext

export {
  DefaultLanguageContext, Consumer as LanguageConsumer, LanguagePathMap, Provider as LanguageProvider
}

export default LanguageContext
